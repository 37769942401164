import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import NeurologicalSidebar from '../neurological_setup_mainsidebar/NeurologicalSidebar';


function EditSpeechNurologies(props) {

    const [SpeechNurologies, setSpeechNurologies] = useState([]);
    const [error, setError] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {

        var url = window.location.href;
        var urlsplit = url.split("/");
        var lastpart = urlsplit[urlsplit.length - 1];
        if (lastpart === '') {
            lastpart = urlsplit[urlsplit.length - 2];
        }

        // const carotid_id = props.match.params.id;
        axios.get(`/edit-speech-nurologies/${lastpart}`).then(res => {

            if (res.data.status === 200) {
                // console.log(res.data.SpeechNurologies);
                setSpeechNurologies(res.data.SpeechNurologies);
                // setPicture(res.data.SpeechNurologies.image);
            } else if (res.data.status === 404) {
                setError(res.data.errors);

            }

        });

    }, []);


    const handleInput = (e) => {
        e.persist();
        setSpeechNurologies({ ...SpeechNurologies, [e.target.name]: e.target.value });
    }

    const updateSpeechNurologiesInput = (e) => {
        e.preventDefault();

        var url = window.location.href;
        var urlsplit = url.split("/");
        var lastpart = urlsplit[urlsplit.length - 1];
        if (lastpart === '') {
            lastpart = urlsplit[urlsplit.length - 2];
        }

        const formData = new FormData();
        formData.append('SpeechNurologies_name', SpeechNurologies.SpeechNurologies_name);


        axios.post(`/update-speech-nurologies/${lastpart}`, formData).then(res => {
            if (res.data.status === 200) {
                swal("Success", res.data.message, "success");

                setSpeechNurologies({
                    ...SpeechNurologies,

                });
                navigate(`/speech-nurologies/`);

            } else {
                setError(res.data.errors)
            }
        });

    }


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <NeurologicalSidebar />
                    </div>
                    <div className="col-md-9 mt-2">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="card-title"> Speech
                                    <Link to={'/speech-nurologies'} className="btn btn-primary btn-sm float-end"> Back </Link>
                                </h6>
                            </div>
                            <div className="card-body">
                                <form id="SpeechNurologiesForm" onSubmit={updateSpeechNurologiesInput}>
                                    <div className="card-body">

                                        <div className="col-md-12">

                                            <div className="form-group">
                                                <label htmlFor="history_name" className="col-form-label-sm"> Name </label>
                                                <input type="text" value={SpeechNurologies.SpeechNurologies_name} className="form-control" name="SpeechNurologies_name" onChange={handleInput} />
                                                <span className="text-danger">{error.SpeechNurologies_name}</span>
                                            </div>

                                            <div className="float-right">
                                                <button type="submit" className="btn btn-sm btn-primary text-uppercase float-end mt-2"><i
                                                    className="fas fa-save"></i> Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

export default EditSpeechNurologies;
