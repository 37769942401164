import React, { useEffect, useState } from 'react';
import clinic4 from "../../../../Images/clinical10.png";
import { Popover } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
const Masculo = (props) => {
    const [historyShowAll, setHistoryShowAll] = useState(false);
    const [specialTestShowAll, setSpecialTestShowAll] = useState(false);
    const [history, setHistory] = useState([]);
    const [historySort, setHistorySort] = useState([]);
    const [historyValue, setHistoryValue] = useState([]);
    const [posture, setPosture] = useState("");
    const [tender, setTender] = useState([]);
    const [tenerness, setTenerness] = useState([]);
    const [tenernessSide, setTenernessSide] = useState("");
    const [inspection, setInspection] = useState("");
    const [lumberLordosis, setLumberLordosis] = useState("");
    const [painIntoKnee, setPainIntoKnee] = useState("");
    const [suddenAnaesthesia, setSuddenAnaesthesia] = useState("");
    const [slumTest, setSlumTest] = useState("");
    const [thoracicKyphosis, setThoracicKyphosis] = useState("");
    const [spine, setSpine] = useState("");
    const [painAlong, setPainAlong] = useState("");
    const [painOver, setPainOver] = useState("");
    const [walkOn, setWalkOn] = useState("");
    const [forward, setForward] = useState(false);
    const [lateralFlexion, setLateralFlexion] = useState("");
    const [extension, setExtension] = useState(false);
    const [tenderOver, setTenderOver] = useState([]);
    const [tenderOverL1ToL6, setTenderOverL1ToL6] = useState([]);
    const [tendernessOver, setTendernessOver] = useState([]);
    const [tendernessOverL1ToL6, setTendernessOverL1ToL6] = useState({});
    const [tendernessOverSide, setTendernessOverSide] = useState("");
    const [slJoint, setSlJoint] = useState("");
    const [hipJoint, setHipJoint] = useState("");
    const [valsalva, setValsalva] = useState("");
    const [straight, setStraight] = useState("");
    const [femoral, setFemoral] = useState("");
    const [hooverTest, setHooverTest] = useState("");
    const [gaenslen, setGaenslen] = useState("");
    const [bucket, setBucket] = useState("");
    const [shober, setShober] = useState("");
    const [quadrant, setQuadrant] = useState("");
    const [trendelenburg, setTrendelenburg] = useState("");
    const [spondylosis, setSpondylosis] = useState("");
    const [hipFlexion, setHipFlexion] = useState("");
    const [resisted, setResisted] = useState("");
    const [dorsiflexion, setDorsiflexion] = useState("");
    const [extend, setExtend] = useState("");
    const [l1, setL1] = useState("");
    const [l3Area, setL3Area] = useState("");
    const [l4Area, setL4Area] = useState("");
    const [l5Area, setL5Area] = useState("");
    const [planterFlexion, setPlanterFlexion] = useState("");
    const [s1Area, setS1Area] = useState("");
    const [s4Supply, setS4Supply] = useState("");
    const [hip, setHip] = useState("");
    const [sacroIliac, setSacroIliac] = useState("");
    const [ortolani, setOrtolani] = useState("");
    const [inspectionLook, setInspectionLook] = useState("");
    const [thomasTest, setThomasTest] = useState("");
    const [hipJointCompression, setHipJointCompression] = useState("");
    const [oberTest, setOberTest] = useState("");
    const [gait, setGait] = useState("");
    const [patrick, setPatrick] = useState("");
    const [piriformis, setPiriformis] = useState("");
    const [elyTest, setElyTest] = useState("");
    const [hipJointtenderness, setHipJointtenderness] = useState("");
    const [greaterTrochanter, setGreaterTrochanter] = useState("");
    const [gluteusMedius, setGluteusMedius] = useState("");
    const [trochantericBursi, setTrochantericBursi] = useState("");
    const [legLength, setlegLength] = useState({ left: "", right: "" });
    const [apparentLegLength, setApparentLegLength] = useState({ left: "", right: "" });
    const [shoulder, setShoulder] = useState("");
    const [shapCounter, setShapeCounter] = useState([]);
    const [anteriorView, setAnteriorView] = useState([]);
    const [levelOfSpine, setLevelOfSpine] = useState("");
    const [wingedScapula, setWingedScapula] = useState("");
    const [sternum, setSternum] = useState("");
    const [coracoidProcess, setCoracoidProcess] = useState("");
    const [scapula, setScapula] = useState("");
    const [axillaryLymphadenopathy, setAxillaryLymphadenopathy] = useState("");
    const [ACJoint, setACJoint] = useState("");
    const [jointMargin, setJointMargin] = useState("");
    const [infraspinatusTendon, setInfraspinatusTendon] = useState("");
    const [subacromialSpace, setSubacromialSpace] = useState("");
    const [spineofScapula, setSpineofScapula] = useState("");
    const [supraSpinatusTendon, setSupraSpinatusTendon] = useState("");
    const [painfulArc, setPainfulArc] = useState("");
    const [resistedMovement, setResistedMovement] = useState([]);
    const [resistedMovementside, setResistedMovementside] = useState("");
    const [acromioclavicular, setAcromioclavicular] = useState("");

    const [scapularAssistanceTest, setScapularAssistanceTest] = useState("");
    const [lateralSlideTest, setLateralSlideTest] = useState("");
    const [impigementNeerTest, setImpigementNeerTest] = useState("");
    const [impigementHawkinskennedyTest, setImpigementHawkinskennedyTest] = useState("");
    const [instabilityLoadAndShiftTest, setInstabilityLoadAndShiftTest] = useState("");
    const [instabilityApprehension, setInstabilityApprehension] = useState("");
    const [inferiorSulcusSign, setInferiorSulcusSign] = useState("");
    const [superiorLabralAnterior, setSuperiorLabralAnterior] = useState("");
    const [SLAPLeisonOBriensTest, setSLAPLeisonOBriensTest] = useState("");
    const [specificPalpation, setSpecificPalpation] = useState("");
    const [upperLimbNeuroDynamicTest, setUpperLimbNeuroDynamicTest] = useState("");


    const [handWrist, setHandWrist] = useState("");
    const [wrist, setWrist] = useState([]);
    const [distalInterphalangealJointSide, setDistalInterphalangealJointSide] = useState('');
    const [distalInterphalangealJointNumber, setDistalInterphalangealJointNumber] = useState('');
    const [distalInterphalangealJoint, setDistalInterphalangealJoint] = useState([]);

    const [metacarpophalangealJointSide, setMetacarpophalangealJointSide] = useState('');
    const [metacarpophalangealJointNumber, setMetacarpophalangealJointNumber] = useState('');
    const [metacarpophalangealJoint, setMetacarpophalangealJoint] = useState([]);
    const [nailsSide, setNailsSide] = useState('');
    const [nailsNumber, setNailsNumber] = useState('');
    const [nails, setNails] = useState([]);
    const [proximalInterphalangealJointSide, setProximalInterphalangealJointSide] = useState('');
    const [proximalInterphalangealJointNumber, setProximalInterphalangealJointNumber] = useState('');
    const [proximalInterphalangealJoint, setProximalInterphalangealJoint] = useState([]);
    const [dorsumofHand, setDorsumofHand] = useState("");
    const [fingersSide, setFingersSide] = useState('');
    const [fingersNumber, setFingersNumber] = useState('');
    const [fingersHand, setFingersHand] = useState([]);
    const [palpationProximalInterphalangealJointSide, setPalpationProximalInterphalangealJointSide] = useState('');
    const [palpationProximalInterphalangealJointNumber, setPalpationProximalInterphalangealJointNumber] = useState('');
    const [palpationProximalInterphalangealJoint, setPalpationProximalInterphalangealJoint] = useState([]);
    const [palpationTenders, setPalpationTenders] = useState([]);
    const [palpationWrist, setPalpationWrist] = useState([]);
    const [flexonTendonsSide, setFlexonTendonsSide] = useState('');
    const [flexonTendonsNumber, setFlexonTendonsNumber] = useState('');
    const [flexonTendons, setFlexonTendons] = useState([]);
    const [palpationMetacarpophalangealJointSide, setPalpationMetacarpophalangealJointSide] = useState('');
    const [palpationMetacarpophalangealJointNumber, setPalpationMetacarpophalangealJointNumber] = useState('');
    const [palpationMetacarpophalangealJoint, setPalpationMetacarpophalangealJoint] = useState([]);
    const [handMovementWrist, setHandMovementWrist] = useState("");
    const [handMovementFinger, setHandMovementFinger] = useState("");
    const [handMovementFlexion, setHandMovementFlexion] = useState("");
    const [handMovementExtension, setHandMovementExtension] = useState("");
    const [handMovementSupination, setHandMovementSupination] = useState("");
    const [handMovementPronation, setHandMovementPronation] = useState("");
    const [handMovementPassiveFlexion, setHandMovementPassiveFlexion] = useState("");
    const [handMovementPassiveExtension, setHandMovementPassiveExtension] = useState("");


    const [finkelsteinTest, setFinkelsteinTest] = useState(false);
    const [wrishFlexionTest, setWrishFlexionTest] = useState('');
    const [flexorDigitorumProfundusTest, setFlexorDigitorumProfundusTest] = useState('');
    const [handMurphySign, setHandMurphySign] = useState('');
    const [handTinelSign, setHandTinelSign] = useState('');
    const [watsonTest, setWatsonTest] = useState('');
    const [sweaterFingerSign, setSweaterFingerSign] = useState('');
    const [flexorDigitorumSuperficialisTest, setFlexorDigitorumSuperficialisTest] = useState('');
    const [quervainTenosynovitis, setQuervainTenosynovitis] = useState('');

    const [elbowJoint, setElbowJoint] = useState("");
    const [elbowHistoryValue, setElbowHistoryValue] = useState([]);
    const [elbowFemale, setElbowFemale] = useState("");
    const [elbowMale, setElbowMale] = useState("");
    const [contractualDeformity, setContractualDeformity] = useState(false);
    const [softTissueControlNormal, setSoftTissueControlNormal] = useState(false);
    const [elbowScars, setElbowScars] = useState(false);
    const [swellings, setSwellings] = useState(false);
    const [elbowTenderness, setElbowTenderness] = useState(false);
    const [elbowCrepitus, setElbowCrepitus] = useState(false);
    const [elbowDeformity, setElbowDeformity] = useState(false);
    const [elbowMuscleWasting, setElbowMuscleWasting] = useState(false);
    const [elbowEffussion, setElbowEffussion] = useState(false);
    const [atFlexion, setAtFlextion] = useState([]);
    const [atFullFlexion, setAtFullFlextion] = useState([]);
    const [elbowExamFlextion, setElbowExamFlextion] = useState("");
    const [elbowExamFlextionNumber, setElbowExamFlextionNumber] = useState("");
    const [elbowExamExtention, setElbowExamExtention] = useState("");
    const [elbowExamExtentionNumber, setElbowExamExtentionNumber] = useState("");
    const [elbowExamSupination, setElbowExamSupination] = useState("");
    const [elbowExamSupinationNumber, setElbowExamSupinationNumber] = useState("");
    const [elbowExamPronation, setElbowExamPronation] = useState("");
    const [elbowExamPronationNumber, setElbowExamPronationNumber] = useState("");

    const [kneeJoint, setKneeJoint] = useState("");
    const [kneeAnteriorView, setKneeAnteriorView] = useState([]);
    const [lateralView, setLateralView] = useState([]);
    const [kneePosteriorView, setKneePosteriorView] = useState([]);
    const [stance, setStance] = useState([]);
    const [brushOrWipeTest, setBrushOrWipeTest] = useState("");
    const [patellarTapTest, setPatellarTapTest] = useState("");
    const [zohlerTest, setZohlerTest] = useState("");
    const [kneeCondyle, setKneeCondyle] = useState("");
    const [tibialTubercle, setTibialTubercle] = useState("");
    const [aboveKneeMeasurementSide, setAboveKneeMeasurementSide] = useState("");
    const [aboveKneeMeasurement, setAboveKneeMeasurement] = useState("");
    const [aboveKneeMeasurement1, setAboveKneeMeasurement1] = useState("");
    const [bellowKneeMeasurementSide, setBellowKneeMeasurementSide] = useState("");
    const [bellowKneeMeasurement, setBellowKneeMeasurement] = useState("");
    const [medialMeniscus, setMedialMeniscus] = useState("");
    const [lateralMeniscus, setLateralMeniscus] = useState("");
    const [kneeFlexion, setKneeFlexion] = useState("");
    const [kneeExtention, setKneeExtention] = useState("");
    const [kneeLigamentInjury, setKneeLigamentInjury] = useState("");
    const [kneeMeniscusInjusy, setKneeMeniscusInjusy] = useState("");
    const [kneeMedialLigament, setKneeMedialLigament] = useState("");
    const [kneeLateralLigament, setKneeLateralLigament] = useState("");
    const [kneeAnteriorCruciate, setKneeAnteriorCruciate] = useState("");
    const [kneeSagSign, setKneeSagSign] = useState("");
    const [kneePostCruciateLigament, setKneePostCruciateLigament] = useState("");
    const [kneeWilsonTest, setKneeWilsonTest] = useState("");
    const [kneePatellaApprehension, setKneePatellaApprehension] = useState("");
    const [kneeNobleCompression, setKneeNobleCompression] = useState("");
    const [footInspectio, setFootInspectio] = useState("");
    const [MTPJointNumber, setMTPJointNumber] = useState([]);
    const [MTPJointSymptom, setMTPJointSymptom] = useState([]);
    const [PIPJointNumber, setPIPJointNumber] = useState([]);
    const [PIPJointSymptom, setPIPJointSymptom] = useState([]);
    const [DIPJointNumber, setDIPJointNumber] = useState([]);
    const [DIPJointSymptom, setDIPJointSymptom] = useState([]);
    const [toesSymptomNumber, setToesSymptomNumber] = useState([]);
    const [toesSymptomRight, setToesSymptomRight] = useState([]);
    const [dorsumSymptom, setDorsumSymptom] = useState([]);
    const [planterSymptom, setPlanterSymptom] = useState([]);
    const [nailNumber, setNailNumber] = useState([]);
    const [nailSymptom, setNailSymptom] = useState([]);
    const [feelPalpation, setFeelPalpation] = useState("");
    const [feelPalpationTender, setFeelPalpationTender] = useState(false);
    const [feelPalpationWarm, setFeelPalpationWarm] = useState(false);
    const [feelPalpationPittingOedema, setFeelPalpationPittingOedema] = useState(false);
    const [feelPalpationToesLeft, setFeelPalpationToesLeft] = useState([]);
    const [feelPalpationMTPJoint, setFeelPalpationMTPJoint] = useState([]);
    const [feelPalpationPIPJoint, setFeelPalpationPIPJoint] = useState([]);
    const [feelPalpationDIPJoint, setFeelPalpationDIPJoint] = useState([]);
    const [feelPalpationMedialMalleolus, setFeelPalpationMedialMalleolus] = useState(false);
    const [feelPalpationLateralMalleolus, setFeelPalpationLateralMalleolus] = useState(false);
    const [feelPalpationTenderOver, setFeelPalpationTenderOver] = useState([]);
    const [feelPalpationSwollenOver, setFeelPalpationSwollenOver] = useState([]);
    const [arteriaDorsalisPedisPluse, setArteriaDorsalisPedisPluse] = useState("");
    const [tibialisPosteriorPluse, setTibialisPosteriorPluse] = useState("");
    const [planterAspectOfFoot, setPlanterAspectOfFoot] = useState([]);
    const [feelPalpationNailSide, setFeelPalpationNailSide] = useState([]);
    const [feelPalpationNailNumber, setFeelPalpationNailNumber] = useState([]);
    const [feelPalpationNailSymptom, setFeelPalpationNailSymptom] = useState([]);
    const [dorsiFlexionMove, setDorsiFlexionMove] = useState([]);
    const [planterFlexionMove, setPlanterFlexionMove] = useState([]);
    const [achilleTendonType, setAchilleTendonType] = useState([]);
    const [achilleTendon, setAchilleTendon] = useState([]);
    const [planterReflex, setPlanterReflex] = useState("");
    const [ankleJerk, setAnkleJerk] = useState("");
    const [ankleS1, setAnkleS1] = useState("");
    const [ankleL4, setAnkleL4] = useState("");
    const [ankleL5, setAnkleL5] = useState("");


    const [suddenAnaesthesiaArray, setSuddenAnaesthesiaArray] = useState([]);

    const [inspectionArray, setInspectionArray] = useState([]);
    const [thoracicKyphosisArray, setThoracicKyphosisArray] = useState([]);
    const [spineArray, setSpineArray] = useState([]);
    const [gaitArray, setGaitArray] = useState([]);

    const [lumberLordosisArray, setLumberLordosisArray] = useState([]);
    const [painIntoKneeArray, setPainIntoKneeArray] = useState([]);
    const [posturArray, setPosturArray] = useState([]);
    const [movementArray, setMovementArray] = useState([]);
    // Tender over spinous  process at
    const [tenderOverSpinusArray, setTenderOverSpinusArray] = useState([]);
    const [tendernessOverLRBArr, setTendernessOverLRBArr] = useState([]);
    const [tenernessOverFacetJoinArray, setTenernessOverFacetJoinArray] = useState([]);

    //TenderOverSpinusThoraco
    const [tenderOverSpinusThoracoArray, setTenderOverSpinusThoracoArray] = useState([]);
    const [tenderTendernessL1ToL6ThoracoArray, setTenderTendernessL1ToL6ThoracoArray] = useState([]);
    const [tenderTendernessL1ToL6ThoracoArray2, setTenderTendernessL1ToL6ThoracoArray2] = useState([]);

    const [tendernessOverFacetThoracoArray, setTendernessOverFacetThoracoArray] = useState([]);

    const [painAlongArray, setPainAlongArray] = useState([]);
    const [painOverArray, setPainOverArray] = useState([]);
    const [walkOnArray, setWalkOnArray] = useState([]);
    const [straightLegRaising, setStraightLegRaising] = useState([]);
    const [shapeCounterArray, setShapeCounterArray] = useState([]);
    const [anteriorViewArray, setAnteriorViewArray] = useState([]);
    const [levelOfSpineArray, setLevelOfSpineArray] = useState([]);
    const [wingedScapulaArray, setWingedScapulaArray] = useState([]);


    const [l1L2HipFlexionArray, setL1L2HipFlexionArray] = useState([]);
    const [l3resistedPowerArray, setL3resistedPowerArray] = useState([]);
    const [l4DorsiflexionPowerArray, setL4DorsiflexionPowerArray] = useState([]);
    const [l5extendPowerArray, setL5extendPowerArray] = useState([]);
    const [l1AndL2SensationArray, setL1AndL2SensationArray] = useState([]);
    const [l3AreaSensationArray, setL3AreaSensationArray] = useState([]);
    const [l4AreaSensationArray, setL4AreaSensationArray] = useState([]);
    const [l5AreaSensationArray, setL5AreaSensationArray] = useState([]);
    const [s1planterFlexionArray, setS1planterFlexionArray] = useState([]);
    const [s1AreaArray, setS1AreaArray] = useState([]);
    const [s4SupplyArray, setS4SupplyArray] = useState([]);
    const [wristArray, setWristArray] = useState([]);
    const [distalInterphalangealJointArray, setDistalInterphalangealJointArray] = useState([]);
    const [metacarpophalangealJointArray, setMetacarpophalangealJointArray] = useState([]);
    const [proximalInterphalangealJointArray, setProximalInterphalangealJointArray] = useState([]);
    const [flexorDigitorumProfundusTestArray, setFlexorDigitorumProfundusTestArray] = useState([]);
    const [flexorDigitorumSuperficialisTestArray, setFlexorDigitorumSuperficialisTestArray] = useState([]);
    const [inspectionFemaleArray, setInspectionFemaleArray] = useState([]);
    const [inspectionMaleArray, setInspectionMaleArray] = useState([]);
    const [at90FlextionArray, setAt90FlextionArray] = useState([]);
    const [atFullFlextionPalpationArray, setAtFullFlextionPalpationArray] = useState([]);
    const [flextion140to150ExaminationArray, setFlextion140to150ExaminationArray] = useState([]);
    const [extention0to10ExaminationArray, setExtention0to10ExaminationArray] = useState([]);
    const [supination90ExaminationArray, setSupination90ExaminationArray] = useState([]);
    const [pronation80to90ExaminationArray, setPronation80to90ExaminationArray] = useState([]);
    const [kneeAnteriorViewArray, setKneeAnteriorViewArray] = useState([]);
    const [lateralViewKneeArray, setLateralViewKneeArray] = useState([]);
    const [posteriorViewKneeArray, setPosteriorViewKneeArray] = useState([]);
    const [stanceArray, setStanceArray] = useState([]);
    const [jointMarginArray, setJointMarginArray] = useState([]);
    const [condyleArray, setCondyleArray] = useState([]);
    const [tibialTubercleArray, setTibialTubercleArray] = useState([]);


    const [nailsArray, setNailsArray] = useState([
        { name: "Pitting", id: 1 },
        { name: "Ridless", id: 2 },
        { name: "Onycholysis", id: 3 },
        { name: "Hyperkeratosis", id: 4 },
        { name: "Discoloration", id: 5 },
    ]);
    const [dorsumofHandArray, setDorsumofHandArray] = useState([
        { name: "Snuffbox Tender", id: 1 },
        { name: "Radial Tubercle Tender", id: 2 },
        { name: "Radial Styloid Process Tender", id: 3 },
        { name: "EPL Tender", id: 4 },
        { name: "Extensor Tendons Swollen Tender", id: 5 },
        { name: "ULNER Styloid Tender", id: 6 },
    ]);
    const [fingersArray, setFingersArray] = useState([
        { name: "Ulcer", id: 1 },
        { name: "Sclerosis", id: 2 },
        { name: "Tender", id: 3 },
        { name: "Dupuytren’s Contracture", id: 4 },
        { name: "Scars", id: 5 },
        { name: "Heberden Nodes", id: 6 },
        { name: "Arthritis Mutilans", id: 7 },
    ])

    const [inspectionLookHipPelvisArray, setInspectionLookHipPelvisArray] = useState([]);
    const [testHipPelvisArray, setTestHipPelvisArray] = useState([]);
    const [palpationTendersArray, setPalpationTendersArray] = useState([
        { name: "Scaphoid", id: 1 },
        { name: "Lunate", id: 2 },
        { name: "Triquetrum", id: 3 },
        { name: "Pisiform", id: 4 },
        { name: "Trapezium", id: 5 },
        { name: "Capitate", id: 6 },
        { name: "Hamate", id: 7 },
    ]);
    const [flexonTendonsArray, setFlexonTendonsArray] = useState([
        { name: "Scaphoid", id: 1 },
        { name: "Lunate", id: 2 },
        { name: "Triquetrum", id: 3 },
        { name: "Pisiform", id: 4 },
        { name: "Trapezium", id: 5 },
        { name: "Capitate", id: 6 },
        { name: "Hamate", id: 7 },
    ]);
    const [elbowHistoryArray, setElbowHistoryArray] = useState([
        { name: "Pain over medial epicondyle" },
        { name: "Pain over lateral epicondyle" },
        { name: "Swelling over back" },
        { name: "Stiffness" },
        { name: "Difficult to hold and carry" },
        { name: "Numbness and paraesthesia" },
        { name: "Instability of the joint" },
        { name: "Gouty tophi" },
        { name: "Subcutaneous nodules" },
    ]);
    const [MTPJointArray, setMTPJointArray] = useState([
        { name: "Swelling" },
        { name: "Hallux Valgue" },
        { name: "Hyperextension" },
        { name: "Deformity" },
        { name: "Redness" },
    ]);
    const [PIPJointArray, setPIPJointArray] = useState([
        { name: "Swelling" },
        { name: "Redness" },
        { name: "Fixed Flexion" },
    ]);
    const [DIPJointArray, setDIPJointArray] = useState([
        { name: "Redness" },
        { name: "Scar" },
    ]);
    const [toesSymptomArray, setToesSymptomArray] = useState([
        { name: "Swelling" },
        { name: "Scar" },
        { name: "Redness" },
        { name: "Purple" },
        { name: "Tender" },
        { name: "Itchiness" },
    ]);
    const [dorsumSymptomArray, setDorsumSymptomArray] = useState([
        { name: "Swelling" },
        { name: "Redness" },
        { name: "Ulcer" },
        { name: "Eczematous change" },
        { name: "Keloid" },
        { name: "Muscle wasting" },
    ]);
    const [planterSymptomArray, setPlanterSymptomArray] = useState([
        { name: "Corn" },
        { name: "Callus" },
        { name: "Wart" },
        { name: "Ulcer" },
        { name: "Transverse arch flattened" },
        { name: "Transverse arch normal" },
        { name: "Longitudinal arch flattened" },
        { name: "Longitudinal arch normal" },
    ]);
    const [nailSymptomArray, setNailSymptomArray] = useState([
        { name: "Normal" },
        { name: "Hyperkeratosis" },
        { name: "Onycholysis" },
        { name: "Ridges" },
        { name: "Discolouration" },
        { name: "Subungual Hematoma" },
    ]);
    const [planterAspectOfFootArray, setPlanterAspectOfFootArray] = useState([
        { name: "Plantar aspect of foot" },
        { name: "Medial side heel tender" },
        { name: "Lateral side heel tender" },
        { name: "Plantar fascia - tender" },
        { name: "Sensation - Intact" },
        { name: "Unable to feel on pin prick" },
        { name: "Unable to feel on light touch" },
    ]);




    ////// Shoulder
    const [painfulArcArray, setPainfulArcArray] = useState([]);
    const [resistedMovementArray, setResistedMovementArray] = useState([]);

    //history Search

    const historySearch = (e) => {
        const { value } = e.target;
        if (value?.length > 0) {
            const existing = [...historySort];
            setHistory(existing.filter(item => item?.history_name?.toLowerCase().includes(value?.toLowerCase())))
        } else {
            setHistory(historySort)
        }
    }
    //

    useEffect(() => {
        let controller = new AbortController();
        axios.get(`/commonHistory-all/Musculo`, { signal: controller.singnal }).then(res => {

            setHistory(res.data.commonHistory)
            setHistorySort(res.data.commonHistory)
        });
        axios.get(`/posture`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setPosturArray(res.data.Posture);
            }
        });
        axios.get(`/movement`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setMovementArray(res.data.Movement);
            }
        });
        axios.get(`/tender-over-spinus-process-at`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTenderOverSpinusArray(res.data.TenderOverSpinusProcessAt);
            }
        });
        axios.get(`/tenerness-over-facet-join`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTenernessOverFacetJoinArray(res.data.TenernessOverFacetJoin);
            }
        });
        axios.get(`/tenderness-over-facet-lrb-thoraco`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTendernessOverLRBArr(res.data.tendernessOverFacetLRBThoraco);
            }
        });
        axios.get(`/inspection`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setInspectionArray(res.data.Inspection);
            }
        });
        axios.get(`/sudden-anaesthesia`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setSuddenAnaesthesiaArray(res.data.SuddenAnaesthesia);
            }
        });
        axios.get(`/thoracic-kyphosis`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setThoracicKyphosisArray(res.data.ThoracicKyphosis);
            }

        });
        axios.get(`/lumber-lordosis`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setLumberLordosisArray(res.data.LumberLordosis);
            }

        });
        axios.get(`/pain-into-knee`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setPainIntoKneeArray(res.data.PainIntoKnee);
            }

        });
        axios.get(`/spine`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setSpineArray(res.data.Spine);
            }

        });
        axios.get(`/pain-along-anterior`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setPainAlongArray(res.data.painAlongAnterior);
            }
        });
        axios.get(`/pain-over-posterior`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setPainOverArray(res.data.painOverPosterior);
            }
        });
        axios.get(`/walk-on-heels`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setWalkOnArray(res.data.walkOnHeels);
            }
        });
        axios.get(`/tender-over-spinus-thoraco`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTenderOverSpinusThoracoArray(res.data.tenderOverSpinusThoraco);
            }
        });
        axios.get(`/tender-tenerness-over-l1-to-l6-thoraco`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTenderTendernessL1ToL6ThoracoArray(res.data.tenderTenernessOverL1ToL6Thoraco);


                setTenderTendernessL1ToL6ThoracoArray2(res.data.tenderTenernessOverL1ToL6Thoraco);
            }
        });
        axios.get(`/lying-on-couch`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setStraightLegRaising(res.data.lyingOnCouch);
            }
        });
        axios.get(`/tenderness-over-facet-thoraco`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTendernessOverFacetThoracoArray(res.data.tendernessOverFacetThoraco);
            }
        });
        axios.get(`/gait-limping`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setGaitArray(res.data.gait);
            }

        });
        axios.get(`/shape-and-contour`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setShapeCounterArray(res.data.ShapeAndContour);
            }

        });
        axios.get(`/anterior-view`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setAnteriorViewArray(res.data.AnteriorView);
            }

        });
        axios.get(`/level-of-spine-of-scapula`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setLevelOfSpineArray(res.data.LevelOfSpineOfScapula);
            }

        });
        axios.get(`/winged-scapula`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setWingedScapulaArray(res.data.WingedScapula);
            }
        });
        axios.get(`/l1-and-l2-hip-flexion`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setL1L2HipFlexionArray(res.data.l1L2HipFlexion);
            }
        });
        axios.get(`/l3-to-l5-power`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setL3resistedPowerArray(res.data.l3ToL5Power);

                setL4DorsiflexionPowerArray(res.data.l3ToL5Power);

                setL5extendPowerArray(res.data.l3ToL5Power);
            }
        });
        axios.get(`/l1-and-l2-sensation`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setL1AndL2SensationArray(res.data.l1L2Sensation);
            }
        });
        axios.get(`/l3-to-l5-sensation`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {
                setL3AreaSensationArray(res.data.l3ToL5Sensation);

                setL4AreaSensationArray(res.data.l3ToL5Sensation);

                setL5AreaSensationArray(res.data.l3ToL5Sensation);
            }
        });
        axios.get(`/s1-to-s4-test`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setS1planterFlexionArray(res.data.s1ToS4Test);


                setS1AreaArray(res.data.s1ToS4Test);


                setS4SupplyArray(res.data.s1ToS4Test);
            }
        });
        // Hip & Pelvis
        axios.get(`/inspection-look-hip-pelvis`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setInspectionLookHipPelvisArray(res.data.inspectionLookHipPelvis);
            }
        });
        axios.get(`/test-hip-pelvis`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setTestHipPelvisArray(res.data.testHipPelvis);
            }
        });
        ////// Shoulder
        axios.get(`/painful-arc`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setPainfulArcArray(res.data.painfulArc);
            }
        });
        axios.get(`/resisted-movement`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setResistedMovementArray(res.data.resistedMovement);
            }
        });
        // Hand & Wrist part-1
        axios.get(`/wrist`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setWristArray(res.data.Wrist);
            }

        });
        axios.get(`/distal-interphalangeal-joint`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setDistalInterphalangealJointArray(res.data.DistalInterphalangealJoint);
            }

        });
        axios.get(`/metacarpophalangeal-joint`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setMetacarpophalangealJointArray(res.data.MetacarpophalangealJoint);
            }

        });
        axios.get(`/proximal-interphalangeal-joint`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setProximalInterphalangealJointArray(res.data.ProximalInterphalangealJoint);
            }

        });

        ////// Shoulder
        axios.get(`/painful-arc`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setPainfulArcArray(res.data.painfulArc);
            }
        });
        axios.get(`/resisted-movement`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setResistedMovementArray(res.data.resistedMovement);
            }
        })
        // Hand & Wrist part-2
        axios.get(`/flexor-digitorum-profundus-test`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setFlexorDigitorumProfundusTestArray(res.data.flexorDigitorumProfundusTest);
            }
        });
        axios.get(`/flexor-digitorum-superficialis-test`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setFlexorDigitorumSuperficialisTestArray(res.data.flexorDigitorumSuperficialisTest);
            }
        });

        // Elbow
        axios.get(`/inspection-male-female`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setInspectionFemaleArray(res.data.inspectionMaleFemale);


                setInspectionMaleArray(res.data.inspectionMaleFemale);
            }
        });
        axios.get(`/at-90-flextion`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setAt90FlextionArray(res.data.at90Flextion);
            }
        });
        axios.get(`/at-full-flextion`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setAtFullFlextionPalpationArray(res.data.atFullFlextionPalpation);
            }
        });
        axios.get(`/flextion-0-to-150-examination`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setFlextion140to150ExaminationArray(res.data.flextion0to150Examination);


                setExtention0to10ExaminationArray(res.data.flextion0to150Examination);


                setSupination90ExaminationArray(res.data.flextion0to150Examination);


                setPronation80to90ExaminationArray(res.data.flextion0to150Examination);
            }
        });

        // Knee 
        axios.get(`/anterior-view-knee`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setKneeAnteriorViewArray(res.data.anteriorViewKnee);
            }
        });
        axios.get(`/lateral-view-knee`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setLateralViewKneeArray(res.data.lateralViewKnee);
            }
        });
        axios.get(`/posterior-view-knee`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setPosteriorViewKneeArray(res.data.posteriorViewKnee);
            }
        });
        axios.get(`/stance-swat-knee`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {

                setStanceArray(res.data.stanceSwatKnee);
            }
        });
        axios.get(`/margin-condyle-tibial`, { signal: controller.singnal }).then(res => {
            if (res.data.status === 200) {


                setJointMarginArray(res.data.marginCondyleTibial);


                setCondyleArray(res.data.marginCondyleTibial);


                setTibialTubercleArray(res.data.marginCondyleTibial);
            }
        });

        return () => { controller.abort() }

    }, []);

    const [saveLoding, setsaveLoding] = useState(false)
    const [spiner, setspiner] = useState(true)

    const saveMusculoSkeletalData = () => {

        setsaveLoding(true)

        const msCarvical = {
            patient_id: props.patient_id,
            compareTo,
            posture,
            historyValue,
            tender,
            tenerness,
            forwardFlexion45,
            backwardExtension45,
            lateralBending45,
            lateralBendingLeft45,
            rotationOver75,
            rotationOverLeft75,

            carvicalHistoryValue
        }
        const msCarve = []

        axios.post('/save-ms-cervical', msCarvical).then(res => {
            console.log("MS", res.data)
            msCarve.push(res.data.mse)

        })

        console.log("msCarve", msCarve)

        const thoracalSpine = {
            patient_id: props.patient_id,

            tenderOver: tenderOver,
            tenderOverL1ToL6: tenderOverL1ToL6,
            tendernessOver: tendernessOver,

            inspection_look_value: inspection,
            sudden_anaesthesia_value: suddenAnaesthesia,
            thoracic_kyphosis_value: thoracicKyphosis,
            lumber_lordosis_value: lumberLordosis,
            spine_value: spine,
            pain_along_value: painAlong,
            pain_over_value: painOver,
            slum_test_ternary: slumTest,
            pain_into_knee_value: painIntoKnee,
            walk_on_value: walkOn,
            tenderness_over_thoraco_side: tendernessOverSide,
            lateral_flexion_side: `${lateralFlexion} : ${lateralFlexionValue}`,
            forward_ternsry: forwordFlexion80,
            extension_ternary: extentionValue2030,
            slJoint_side: slJoint,
            hip_joint_line_side: hipJoint,
            valsalva_PN_ternary: valsalva,
            straight_leg_raising_ternary: straight,
            femoral_stretch_PN_ternary: femoral,
            hoover_test_PN_ternary: hooverTest,
            gaenslens_sign_PN_ternary: gaenslen,
            patrick_bucket_PN_ternary: bucket,
            quadrant_PN_ternary: quadrant,
            trendelenburg_PN_ternary: trendelenburg,
            spondylosis_PN_ternary: spondylosis,
            hip_flexion_value: hipFlexion,
            resisted_value: resisted,
            dorsiflexion_value: dorsiflexion,
            extend_value: extend,
            l1_value: l1,
            l3Area_value: l3Area,
            l4Area_value: l4Area,
            l5Area_value: l5Area,
            planter_flexion_value: planterFlexion,
            s1_area_value: s1Area,
            s4_supply_value: s4Supply,
            Shober_test: `${shoberTest} ${shober}`,

            thoracoHistoryValue
        }


        const msThora = []

        axios.post('/save-ms-thoracal-lumber-spine', thoracalSpine).then(res => {
            console.log("horaco-lumber Spine", res.data)
            msThora.push(res.data.mse)
        })



        //test_value: test,
        // move_ternary: move,


        const hipPalvis = {
            patient_id: props.patient_id,
            hip_ternary: hip,
            sacroIliac_PN_ternary: sacroIliac,
            ortolani_PN_ternary: ortolani,
            inspection_look_value: inspectionLook,
            thomas_test_PN_ternary: thomasTest,
            hipJoint_compression_PN_ternary: hipJointCompression,
            ober_test_PN_ternary: oberTest,
            gait_value: gait,
            patrick_PN_ternary: patrick,
            piriformis_PN_ternary: piriformis,
            ely_test_PN_ternary: elyTest,
            hip_jointtenderness_PA_ternary: hipJointtenderness,
            greater_trochanter_LR_ternary: greaterTrochanter,
            gluteus_medius_PA_ternary: gluteusMedius,
            trochanteric_bursi_PA_ternary: trochantericBursi,
            flexion135: flexion135,
            abduction50: abduction50,
            InternalRotation45: InternalRotation45,
            Extension20: Extension20,
            Adduction45: Adduction45,
            alRotation45: alRotation45,
            trueLegLengthLeft: legLength.left,
            trueLegLengthRight: legLength.right,
            ApparentLegLengthLeft: apparentLegLength.left,
            ApparentLegLengthRight: apparentLegLength.right,

            hipHistoryValue
        }

        const msHip = []
        axios.post('/save-ms-hip-pelvis', hipPalvis).then(res => {
            console.log("hipPalvis", res.data)
            msHip.push(res.data.mse)
        })


        const knee = {
            patient_id: props.patient_id,
            kneeJoint_ternary: kneeJoint,

            knee_anterior_view_object_value: kneeAnteriorView,
            lateral_view_object_value: lateralView,
            knee_posterior_view_object_value: kneePosteriorView,
            stance_object_value: stance,

            brush_or_wipe_test_PN_ternary: brushOrWipeTest,
            patellar_tap_test_PN_ternary: patellarTapTest,
            zohler_test_PN_ternary: zohlerTest,
            knee_condyle_value: kneeCondyle,
            tibial_tubercle_value: tibialTubercle,
            above_knee_measurement_side_ternary: aboveKneeMeasurementSide,
            above_knee_measurement: aboveKneeMeasurement,
            above_knee_measurement_1: aboveKneeMeasurement1,

            bellow_knee_measurement_side_ternary: bellowKneeMeasurementSide,
            bellow_knee_measurement: bellowKneeMeasurement,

            medial_meniscus_PN_ternary: medialMeniscus,
            lateral_meniscus_PN_ternary: lateralMeniscus,
            knee_flexion_PN_ternary: kneeFlexion,
            knee_kxtention_PN_ternary: kneeExtention,

            //new
            kneeLigamentInjury,
            kneeMeniscusInjusy,
            //new
            kneeMedialLigament,
            kneeAnteriorCruciate,
            kneeSagSign,
            kneeLateralLigament,
            kneePostCruciateLigament,
            kneeWilsonTest,
            kneeNobleCompression,


            medialMeniscusSide,
            lateralMeniscusSide,
            kneeLigamentInjurySide,
            kneeMeniscusInjusySide,
            kneeMedialRotationLigament,
            kneeMedialRotationMeniscus,
            kneeMedialLigamentSide,
            kneeAnteriorCruciateSide,
            kneeLateralLigamentSide,
            kneeSagSignSide,
            kneePostCruciateLigamentSide,
            kneeWilsonTestSide,
            kneePatellaApprehensionSide,
            kneeNobleCompressionSide,

            kneeHistoryValue

        }
        const msKnee = []
        axios.post("/save-ms-knee", knee).then((res) => {
            console.log("Knee res", res.data)

            msKnee.push(res.data.mse)
        })

        const Ankel = {
            patient_id: props.patient_id,
            footInspectio,
            MTPJointNumber,
            MTPJointSymptom,
            PIPJointNumber,
            PIPJointSymptom,

            DIPJointNumber,
            DIPJointSymptom,

            toesSymptomNumber,
            toesSymptomRight,

            dorsumSymptom,
            planterSymptom,

            nailNumber,
            nailSymptom,

            feelPalpation,
            feelPalpationToesLeft,

            feelPalpationMTPJointSide,
            feelPalpationMTPJoint,
            feelPalpationPIPJoint,
            feelPalpationDIPJoint,

            //true false
            feelPalpationMedialMalleolus,
            feelPalpationLateralMalleolus,
            //
            feelPalpationTenderOver,
            feelPalpationSwollenOver,
            arteriaDorsalisPedisPluse,

            //new
            // tibialisPosteriorPluse,

            planterAspectOfFoot,

            feelPalpationNailSide,
            feelPalpationNailNumber,
            feelPalpationNailSymptom,

            //true
            feelPalpationTender,
            feelPalpationWarm,
            feelPalpationPittingOedema,

            dorsiFlexionMove,
            planterFlexionMove,
            achilleTendonType,
            achilleTendon,

            planterReflex,
            ankleJerk,
            ankleS1,
            ankleL4,
            ankleL5,

            ankleHistoryValue
        }

        const msAnkel = []
        axios.post("/save-ms-ankel-foot", Ankel).then((res) => {
            console.log("Ankel and Foot", res.data)

            msAnkel.push(res.data.mse)
        })


        //anterior_view_object: anteriorView,
        //shap_counter_object: shapCounter,
        //resistedMovement

        const Shoulder = {
            patient_id: props.patient_id,

            shapCounter,
            anteriorView,
            resistedMovement,

            shoulder_LR_ternary: shoulder,
            level_of_spine_value: levelOfSpine,
            winged_scapula_value: wingedScapula,
            sternum_ternary_value: sternum,
            coracoid_process_ternary_value: coracoidProcess,
            scapula_ternary_value: scapula,
            axillary_ternary_value: axillaryLymphadenopathy,
            ac_joint_ternary_value: ACJoint,
            joint_margin_ternary_value: jointMargin,
            infraspinatus_ternary_value: infraspinatusTendon,
            subacromial_ternary_value: subacromialSpace,
            spineof_scapula_ternary_value: spineofScapula,
            supra_spinatus_ternary_value: supraSpinatusTendon,
            painful_arc_value: painfulArc,
            resistedMovementside: resistedMovementside,
            acromioclavicular_PN_ternary: acromioclavicular,
            scapular_assistance_PN_ternary: scapularAssistanceTest,
            lateral_slide_PN_ternary: lateralSlideTest,
            impigement_neer_PN_ternary: impigementNeerTest,
            impigement_hawkinskennedy_PN_ternary: impigementHawkinskennedyTest,
            instability_load_shift_PN_ternary: instabilityLoadAndShiftTest,
            instability_apprehension_PN_ternary: instabilityApprehension,
            inferior_sulcus_sign_PN_ternary: inferiorSulcusSign,
            superiorLabralAnterior: superiorLabralAnterior,
            SLAPLeison_obriens_PN_ternary: SLAPLeisonOBriensTest,
            specific_palpation_PN_ternary: specificPalpation,
            upper_limb_euro_dynamic_PN_ternary: upperLimbNeuroDynamicTest,
            abduction50: abduction50shoulder,
            abduction170: abduction170,
            Flexion160: Flexion160shoulder,
            Extension50: Extension50,
            LateralRotation80: LateralRotation80,
            MedialRotation: MedialRotation,

            shoulderHistoryValue
        }

        const msShoulder = []
        axios.post('/save-ms-shoulder', Shoulder).then(res => {
            console.log("Shoulder", res.data)
            msShoulder.push(res.data.mse)
        })






        const hand = {
            patient_id: props.patient_id,
            hand_wrist_ternary: handWrist,
            wrist_Arr: wrist,
            distal_side_value: distalInterphalangealJointSide,
            distal_number_value: distalInterphalangealJointNumber,
            distal_Arr: distalInterphalangealJoint,
            metacarpophalangeal_side_value: metacarpophalangealJointSide,
            metacarpophalangeal_number_value: metacarpophalangealJointNumber,
            metacarpophalangeal_Arr: metacarpophalangealJoint,
            nails_side_value: nailsSide,
            nails_number_value: nailsNumber,
            nails_Arr: nails,
            proximal_side_value: proximalInterphalangealJointSide,
            proximal_number_value: proximalInterphalangealJointNumber,
            proximal_Arr: proximalInterphalangealJoint,
            dorsum_of_hand_value: dorsumofHand,
            fingers_side_value: fingersSide,
            fingers_number_value: fingersNumber,
            fingers_hand_Arr: fingersHand,
            palpation_interphalangeal_side_value: palpationProximalInterphalangealJointSide,
            palpation_interphalangeal_number_value: palpationProximalInterphalangealJointNumber,
            palpation_interphalangeal_Arr: palpationProximalInterphalangealJoint,
            palpation_tenders_Arr: palpationTenders,
            palpation_wrist_Arr: palpationWrist,
            flexon_tendons_side_value: flexonTendonsSide,
            flexon_tendons_number_value: flexonTendonsNumber,
            flexon_tendons_Arr: flexonTendons,
            palpation_metacarpophalangeal_side_value: palpationMetacarpophalangealJointSide,
            palpation_metacarpophalangeal_number_value: palpationMetacarpophalangealJointNumber,
            palpation_metacarpophalangeal_Arr: palpationMetacarpophalangealJoint,

            hand_movement_wrist_NR_ternary: handMovementWrist,
            hand_movement_finger: handMovementFinger,
            hand_movement_flexion_NR_ternary: handMovementFlexion,
            hand_movement_extension_NR_ternary: handMovementExtension,
            hand_movement_supination_NR_ternary: handMovementSupination,
            hand_movement_pronation_NR_ternary: handMovementPronation,

            hand_movement_passive_flexion: handMovementPassiveFlexion,
            hand_movement_Passive_Extension: handMovementPassiveExtension,

            finkelstein_test_ternary: finkelsteinTest,
            wrish_flexion_test_PN_ternary: wrishFlexionTest,
            hand_murphy_sign_PN_ternary: handMurphySign,
            handTinel_sign_PN_ternary: handTinelSign,
            watson_test_PN_ternary: watsonTest,
            sweater_finger_sign_PN_ternary: sweaterFingerSign,
            flexor_digitorum_profundus_test_value: flexorDigitorumProfundusTest,
            flexor_digitorum_superficialis_test_value: flexorDigitorumSuperficialisTest,
            quervain_tenosynovitis_ternary: quervainTenosynovitis,

            handHistoryValue

        }
        const msHand = []
        axios.post("/save-ms-hand-wrist", hand).then((res) => {
            console.log("hand", res.data)
            msHand.push(res.data.mse)
        })


        const elbow = {
            patient_id: props.patient_id,
            elbow_joint_ternary: elbowJoint,
            elbow_history_Arr: elbowHistoryValue,
            elbow_female_value: elbowFemale,
            elbow_male_value: elbowMale,
            contractual_deformity_ternary: contractualDeformity,
            soft_tissue_control_normal_ternary: softTissueControlNormal,
            elbow_scars_ternary: elbowScars,
            swellings_ternary: swellings,
            elbow_tenderness_ternary: elbowTenderness,
            elbow_crepitus_ternary: elbowCrepitus,
            elbow_deformity_ternary: elbowDeformity,
            elbow_muscle_wasting_ternary: elbowMuscleWasting,
            elbow_effussion_ternary: elbowEffussion,
            at_flexion_Arr: atFlexion,
            at_full_flexion_Arr: atFullFlexion,
            elbow_exam_flextion_value: elbowExamFlextion,
            elbow_exam_flextion_number_value: elbowExamFlextionNumber,
            elbow_exam_extention_value: elbowExamExtention,
            elbow_exam_extention_number_value: elbowExamExtentionNumber,
            elbow_exam_supination_value: elbowExamSupination,
            elbow_exam_supination_number_value: elbowExamSupinationNumber,
            elbow_exam_pronation_value: elbowExamPronation,
            elbow_exam_pronation_number_value: elbowExamPronationNumber,

            elbowHistoryVal
        }

        const msElbow = []
        axios.post("/save-ms-elbow", elbow).then((res) => {
            console.log("elbow", res.data)
            msElbow.push(res.data.mse)
        })


        setTimeout(function () {
            const note = `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Musculo Skeletal :</strong></span></p>

${msCarve[0]?.historyValue === "" ? `` :
                    `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msCarve[0].historyValue.replaceAll(",", " . ")}
</span></span></p> `}



${msCarve[0].posture === null && msCarve[0].tender === "" && msCarve[0].tenerness === "" && msCarve[0].forwardFlexion45 === null && msCarve[0].forwardFlexion45 === null && msCarve[0].lateralBending45 === null &&
                    msCarve[0].lateralBendingLeft45 === null &&
                    msCarve[0].rotationOver75 === null &&
                    msCarve[0].rotationOverLeft75 === null &&
                    msCarve[0].carvicalHistoryValue === ""
                    ? "" : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">Cervical Spine:</span></strong></p>

${msCarve[0].carvicalHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
<strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

<p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
<span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
${msCarve[0].carvicalHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}

${msCarve[0].posture === null ? "" : `
<p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
          Posture: ${msCarve[0].posture}
</span></span></p>`} 

${msCarve[0].tender === "" && msCarve[0].tenerness === "" ? "" : `
<p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
         <strong>Palpation : </strong> 
         ${msCarve[0].tender === "" ? `` : `Tender over spinous process at: ${msCarve[0].tender} . `}
         ${msCarve[0].tenerness === "" ? `` : `Tenderness over facet joint at: ${msCarve[0].tenerness}`}
</span></span></p>`} 

${msCarve[0].forwardFlexion45 === null &&
                        msCarve[0].backwardExtension45 === null &&
                        msCarve[0].lateralBending45 === null &&
                        msCarve[0].lateralBendingLeft45 === null &&
                        msCarve[0].rotationOver75 === null &&
                        msCarve[0].rotationOverLeft75 === null
                        ? "" : `
<p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
         <strong>Movement: </strong> 
         ${msCarve[0].forwardFlexion45 === null ? `` : `Forward Flexion to : ${msCarve[0].forwardFlexion45} °`}
         ${msCarve[0].backwardExtension45 === null ? `` : `Backward Extension to: ${msCarve[0].backwardExtension45} °`}
         ${msCarve[0].lateralBending45 === null ? `` : `Lateral bending- right to: ${msCarve[0].lateralBending45} °`}
         ${msCarve[0].lateralBendingLeft45 === null ? `` : `Lateral bending- left to: ${msCarve[0].lateralBendingLeft45} °`}
         ${msCarve[0].rotationOver75 === null ? `` : `Rotation over shoulder to  right: ${msCarve[0].rotationOver75} ° `}
         ${msCarve[0].rotationOverLeft75 === null ? `` : `Rotation over shoulder to  left: ${msCarve[0].rotationOverLeft75} °`}
</span></span></p>`} `}


${msThora[0].inspection_look_value === null &&
                    msThora[0].pain_into_knee_value === null &&
                    msThora[0].sudden_anaesthesia_value === null && msThora[0].spine_value === null && msThora[0].slum_test_ternary === null && msThora[0].thoracic_kyphosis_value === null &&
                    msThora[0].pain_along_value === null && msThora[0].pain_over_value === null &&
                    msThora[0].walk_on_value === null && msThora[0].forward_ternsry === null && msThora[0].lateral_flexion_side == ":" && msThora[0].extension_ternary === null &&
                    msThora[0].tenderOver === "" && msThora[0].tendernessOver === "" && msThora[0].slJoint_side === null && msThora[0].hip_joint_line_side === null
                    && msThora[0].valsalva_PN_ternary === null &&
                    msThora[0].valsalva_PN_ternary === null &&
                    msThora[0].femoral_stretch_PN_ternary === null &&
                    msThora[0].hoover_test_PN_ternary === null &&
                    msThora[0].gaenslens_sign_PN_ternary === null &&
                    msThora[0].patrick_bucket_PN_ternary === null
                    && msThora[0].Shober_test === null &&
                    msThora[0].quadrant_PN_ternary === null &&
                    msThora[0].trendelenburg_PN_ternary === null &&
                    msThora[0].spondylosis_PN_ternary === null &&
                    msThora[0].planter_flexion_value === null
                    && msThora[0].hip_flexion_value === null &&
                    msThora[0].resisted_value === null &&
                    msThora[0].dorsiflexion_value === null &&
                    msThora[0].extend_value === null &&
                    msThora[0].s4_supply_value === null

                    && msThora[0].l1_value === null &&
                    msThora[0].l3Area_value === null &&
                    msThora[0].l4Area_value === null &&
                    msThora[0].l5Area_value === null &&
                    msThora[0].s1_area_value === null &&
                    msThora[0].thoracoHistoryValue === ""

                    ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Thoraco-lumber Spine: </strong></span></p>

${msThora[0].thoracoHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msThora[0].thoracoHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}

${msThora[0].inspection_look_value === null &&
                        msThora[0].pain_into_knee_value === null &&
                        msThora[0].sudden_anaesthesia_value === null && msThora[0].spine_value === null && msThora[0].slum_test_ternary === null && msThora[0].thoracic_kyphosis_value === null &&
                        msThora[0].pain_along_value === null ? `` :
                        `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
${msThora[0].inspection_look_value === null ? `` : `Inspection (Look for deformity): ${msThora[0].inspection_look_value} . `}
    
    ${msThora[0].lumber_lordosis_value === null ? `` : `Lumbar Lordosis: ${msThora[0].lumber_lordosis_value} . `}
    ${msThora[0].pain_into_knee_value === null ? `` : `Pain into knee: ${msThora[0].pain_into_knee_value} . `}
    ${msThora[0].sudden_anaesthesia_value === null ? `` : `Saddle Anaesthesia: ${msThora[0].sudden_anaesthesia_value} . `}
    ${msThora[0].spine_value === null ? `` : `Spine : ${msThora[0].spine_value} . `}
    ${msThora[0].slum_test_ternary === null ? `` : `Slump Test: ${msThora[0].slum_test_ternary} . `}
    ${msThora[0].thoracic_kyphosis_value === null ? `` : `Thoracic Kyphosis: ${msThora[0].thoracic_kyphosis_value} . `}
    ${msThora[0].pain_along_value === null ? `` : `Pain along anterior and lateral aspect of thigh: ${msThora[0].pain_along_value} . `}
    ${msThora[0].pain_over_value === null ? `` : `Pain over posterior or lateral aspect of greater trochanter: ${msThora[0].pain_over_value} `}
 </span></p>`}



${msThora[0].walk_on_value === null && msThora[0].forward_ternsry === null && msThora[0].lateral_flexion_side == ":" && msThora[0].extension_ternary === null ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Movements: </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].walk_on_value === null ? `` : ` Walk on heels (L5) and Toes (S1): ${msThora[0].walk_on_value} . `}
    ${msThora[0].forward_ternsry === null ? `` : `Forword Flexion to 80° : ${msThora[0].forward_ternsry} . `}
    ${msThora[0].lateral_flexion_side == ":" ? `` : `Lateral Flexion to 30° : ${msThora[0].lateral_flexion_side} . `}
    ${msThora[0].extension_ternary === null ? `` : `Extension -20° - 30° : ${msThora[0].extension_ternary} `}
 </span></p>`}

${msThora[0].tenderOver === "" && msThora[0].tendernessOver === "" && msThora[0].slJoint_side === null && msThora[0].hip_joint_line_side === null ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Palpation : </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].tenderOver === "" ? `` : `Tender over spinous  process at: ${msThora[0].tenderOver} . `}
    ${msThora[0].tendernessOver === "" ? `` : `Tenderness over facet joint: ${msThora[0].tendernessOver} . `}
    ${msThora[0].slJoint_side === null ? `` : `Sl joint line tenderness: ${msThora[0].slJoint_side} . `}
    ${msThora[0].hip_joint_line_side === null ? `` : `Hip joint line tenderness: ${msThora[0].hip_joint_line_side} `}
 </span></p>`}

${msThora[0].valsalva_PN_ternary === null ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Sitting : </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].valsalva_PN_ternary === null ? `` : `Valsalva’s Maneouver (Increased intra-thecal Pressure causing leg pain): ${msThora[0].valsalva_PN_ternary} `}
 </span></p>`}


${msThora[0].valsalva_PN_ternary === null &&
                        msThora[0].femoral_stretch_PN_ternary === null &&
                        msThora[0].hoover_test_PN_ternary === null &&
                        msThora[0].gaenslens_sign_PN_ternary === null &&
                        msThora[0].patrick_bucket_PN_ternary === null
                        ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Lying on couch : </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].straight_leg_raising_ternary === null ? `` : `Straight Leg Raising test Normal 80° - 90° : ${msThora[0].straight_leg_raising_ternary} . `}
    ${msThora[0].femoral_stretch_PN_ternary === null ? `` : `Femoral Stretch test Herniated disc L2 to L4 : ${msThora[0].femoral_stretch_PN_ternary} . `}
    ${msThora[0].hoover_test_PN_ternary === null ? `` : `Hoover test : ${msThora[0].hoover_test_PN_ternary} . `}
    ${msThora[0].gaenslens_sign_PN_ternary === null ? `` : `Gaenslen’s sign : ${msThora[0].gaenslens_sign_PN_ternary} . `}
    ${msThora[0].patrick_bucket_PN_ternary === null ? `` : `Patrick’s (Fabere) test or Bucket Handle test : ${msThora[0].patrick_bucket_PN_ternary} `}
 </span></p>`}

${msThora[0].Shober_test === null &&
                        msThora[0].quadrant_PN_ternary === null &&
                        msThora[0].trendelenburg_PN_ternary === null &&
                        msThora[0].spondylosis_PN_ternary === null &&
                        msThora[0].planter_flexion_value === null
                        ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Test : </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].Shober_test === null ? `` : `Shober’s test : ${msThora[0].Shober_test} cm <br>`}
    ${msThora[0].quadrant_PN_ternary === null ? `` : `Quadrant test (facet joint arthopathy): ${msThora[0].quadrant_PN_ternary} .  <br>`}
    ${msThora[0].trendelenburg_PN_ternary === null ? `` : `Trendelenburg Test. Abductor weakness on stance side (Sag side safe) : ${msThora[0].trendelenburg_PN_ternary} <br>`}
    ${msThora[0].spondylosis_PN_ternary === null ? `` : `Spondylosis Test. Extension in one leg standing (stress fracture of pars interartucularis, Facet joint pathology): ${msThora[0].spondylosis_PN_ternary}  <br>`}
    ${msThora[0].planter_flexion_value === null ? `` : `S1- Plantar flexion of foot Gastrocnemius and soleus musclet : ${msThora[0].planter_flexion_value} `}
 </span></p>`}

${msThora[0].hip_flexion_value === null &&
                        msThora[0].resisted_value === null &&
                        msThora[0].dorsiflexion_value === null &&
                        msThora[0].extend_value === null &&
                        msThora[0].s4_supply_value === null
                        ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Power : </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].hip_flexion_value === null ? `` : `L1 and L2. Hip Flexion : ${msThora[0].hip_flexion_value} <br>`}
    ${msThora[0].resisted_value === null ? `` : `L3- Resisted knee extension Power on lower leg Quadriceps muscle: ${msThora[0].resisted_value}<br>`}
    ${msThora[0].dorsiflexion_value === null ? `` : `L4- Dorsiflexion of fool and ankle Tibialia anterior muscle action: ${msThora[0].dorsiflexion_value} <br>`}
    ${msThora[0].extend_value === null ? `` : `L5- Extend great toe distal Phalanx Extensor hallucis longus extension: ${msThora[0].extend_value} <br>`}
    ${msThora[0].s4_supply_value === null ? `` : `S2-S4 Supply of urinary bladder and intrinsic muscle of foot: ${msThora[0].s4_supply_value} `}
 </span></p>`}


${msThora[0].l1_value === null &&
                        msThora[0].l3Area_value === null &&
                        msThora[0].l4Area_value === null &&
                        msThora[0].l5Area_value === null &&
                        msThora[0].s1_area_value === null
                        ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Power : </strong></span></p>

<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
 <span style="font-family: Roboto; color: rgb(54, 54, 54);">
    ${msThora[0].l1_value === null ? `` : `L1 and L2 : ${msThora[0].l1_value} . `}
    ${msThora[0].l3Area_value === null ? `` : `L3 area : ${msThora[0].l3Area_value}. `}
    ${msThora[0].l4Area_value === null ? `` : `L4 area : ${msThora[0].l4Area_value} . `}
    ${msThora[0].l5Area_value === null ? `` : `L5 area : ${msThora[0].l5Area_value} . `}
    ${msThora[0].s1_area_value === null ? `` : `S1 Area : ${msThora[0].s1_area_value} `}
 </span></p>`}


    
`}

${msHip[0].hip_ternary === null && msHip[0].inspection_look_value === null && msHip[0].gait_value === null &&
                    msHip[0].hip_jointtenderness_PA_ternary === null &&
                    msHip[0].gluteus_medius_PA_ternary === null &&
                    msHip[0].trochanteric_bursi_PA_ternary === null &&
                    msHip[0].greater_trochanter_LR_ternary === null &&

                    msHip[0].flexion135 === null &&
                    msHip[0].abduction50 === null &&
                    msHip[0].InternalRotation45 === null &&
                    msHip[0].Extension20 === null &&
                    msHip[0].Adduction45 === null &&
                    msHip[0].alRotation45 === null &&
                    msHip[0].trueLegLengthLeft === null && msHip[0].trueLegLengthRight === null &&
                    msHip[0].ApparentLegLengthLeft === null && msHip[0].ApparentLegLengthRight === null &&
                    msHip[0].thomas_test_PN_ternary === null &&
                    msHip[0].patrick_PN_ternary === null &&
                    msHip[0].sacroIliac_PN_ternary === null &&
                    msHip[0].hipJoint_compression_PN_ternary === null &&
                    msHip[0].piriformis_PN_ternary === null &&
                    msHip[0].ortolani_PN_ternary === null &&
                    msHip[0].ober_test_PN_ternary === null &&
                    msHip[0].ely_test_PN_ternary === null &&
                    msHip[0].hipHistoryValue === ""

                    ? `` :
                    `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);">

    <strong>Hip & Pelvis : </strong></span><br>


    ${msHip[0].hipHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msHip[0].hipHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}


     ${msHip[0].hip_ternary === null ? `` : `Hip & Pelvis : ${msHip[0].hip_ternary} <br>`}
     ${msHip[0].inspection_look_value === null ? `` : `Inspection (Look) : ${msHip[0].inspection_look_value} <br>`}
     ${msHip[0].gait_value === null ? `` : `Gait - Limping : ${msHip[0].gait_value} <br>`}

        ${msHip[0].hip_jointtenderness_PA_ternary === null &&
                        msHip[0].gluteus_medius_PA_ternary === null &&
                        msHip[0].trochanteric_bursi_PA_ternary === null &&
                        msHip[0].greater_trochanter_LR_ternary === null ? `` :
                        ` <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Palpation (Feel): </strong></span><br>`
                    }
      
       ${msHip[0].hip_jointtenderness_PA_ternary === null ? `` : `Hip Joint tenderness: ${msHip[0].hip_jointtenderness_PA_ternary} <br>`}
       ${msHip[0].gluteus_medius_PA_ternary === null ? `` : `Gluteus Medius tendinitis: ${msHip[0].gluteus_medius_PA_ternary} <br>`}
       ${msHip[0].trochanteric_bursi_PA_ternary === null ? `` : `Trochanteric Bursi: ${msHip[0].trochanteric_bursi_PA_ternary} <br>`}
       ${msHip[0].greater_trochanter_LR_ternary === null ? `` : `Greater trochanter - Higher on abnormaln: ${msHip[0].greater_trochanter_LR_ternary} <br>`}
      
      
       ${msHip[0].flexion135 === null &&
                        msHip[0].abduction50 === null &&
                        msHip[0].InternalRotation45 === null &&
                        msHip[0].Extension20 === null &&
                        msHip[0].Adduction45 === null &&
                        msHip[0].alRotation45 === null

                        ? `` :
                        ` <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Move : </strong></span><br>`
                    }
      
       ${msHip[0].flexion135 === null ? `` : `Flexion 135° : ${msHip[0].flexion135} .  `}
       ${msHip[0].abduction50 === null ? `` : `Abduction 50° : ${msHip[0].abduction50} .  `}
       ${msHip[0].InternalRotation45 === null ? `` : `Internal Rotation 45° : ${msHip[0].InternalRotation45} .  `}
       ${msHip[0].Extension20 === null ? `` : `Extension 20° : ${msHip[0].Extension20} .  `}
       ${msHip[0].Adduction45 === null ? `` : `Adduction 45° : ${msHip[0].Adduction45} .  `}
       ${msHip[0].alRotation45 === null ? `` : `Al Rotation 45° : ${msHip[0].alRotation45} <br>`}

         ${msHip[0].flexion135 === null &&
                        msHip[0].abduction50 === null &&
                        msHip[0].InternalRotation45 === null &&
                        msHip[0].Extension20 === null &&
                        msHip[0].Adduction45 === null &&
                        msHip[0].alRotation45 === null

                        ? `` :
                        `<br> <span style="font-family: Roboto; ">Internal rotation abduction and extension restricted in Osteoarthritis hip. In children Measure rotation, abduction and adduction with hip and knee flex for perthes and Slipped upper femoral epiphysis </span><br>`
                    }


       ${msHip[0].trueLegLengthLeft === null && msHip[0].trueLegLengthRight === null ? `` : `True leg length : Left - ${msHip[0].trueLegLengthLeft === null ? `....` : msHip[0].trueLegLengthLeft} mm and Right - ${msHip[0].trueLegLengthRight === null ? `....` : msHip[0].trueLegLengthRight} mm <br>`}
       ${msHip[0].ApparentLegLengthLeft === null && msHip[0].ApparentLegLengthRight === null ? `` : `Apparent leg length Right : Left - ${msHip[0].ApparentLegLengthLeft === null ? `....` : msHip[0].ApparentLegLengthLeft} mm and Right - ${msHip[0].ApparentLegLengthRight === null ? `....` : msHip[0].ApparentLegLengthRight} mm <br>`}


         ${msHip[0].thomas_test_PN_ternary === null &&
                        msHip[0].patrick_PN_ternary === null &&
                        msHip[0].sacroIliac_PN_ternary === null &&
                        msHip[0].hipJoint_compression_PN_ternary === null &&
                        msHip[0].piriformis_PN_ternary === null &&
                        msHip[0].ortolani_PN_ternary === null &&
                        msHip[0].ober_test_PN_ternary === null &&
                        msHip[0].ely_test_PN_ternary === null

                        ? `` :
                        ` <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Test: </strong></span><br>`
                    }
      
       ${msHip[0].thomas_test_PN_ternary === null ? `` : `Thomas test for fixed flexion deformity: ${msHip[0].thomas_test_PN_ternary} <br>`}
       ${msHip[0].patrick_PN_ternary === null ? `` : `Patrick’s (Fabere) test or Bucket Handle test: ${msHip[0].patrick_PN_ternary} <br>`}
       ${msHip[0].sacroIliac_PN_ternary === null ? `` : `Sacro-iliac joint compression test: ${msHip[0].sacroIliac_PN_ternary} <br>`}
       ${msHip[0].hipJoint_compression_PN_ternary === null ? `` : `Hip joint compression: ${msHip[0].hipJoint_compression_PN_ternary} <br>`}
       ${msHip[0].piriformis_PN_ternary === null ? `` : `Piriformis test: ${msHip[0].piriformis_PN_ternary} <br>`}
       ${msHip[0].ortolani_PN_ternary === null ? `` : `Ortolani & Barlow test: ${msHip[0].ortolani_PN_ternary} <br>`}
       ${msHip[0].ober_test_PN_ternary === null ? `` : `Ober’s test: ${msHip[0].ober_test_PN_ternary} <br>`}
       ${msHip[0].ely_test_PN_ternary === null ? `` : `Ely’s test: ${msHip[0].ely_test_PN_ternary} <br>`}

    </p>
`}

${msKnee[0].kneeJoint_ternary === null &&
                    msKnee[0].knee_anterior_view_object_value === "" &&
                    msKnee[0].lateral_view_object_value === "" &&
                    msKnee[0].knee_posterior_view_object_value === "" &&
                    msKnee[0].brush_or_wipe_test_PN_ternary === null &&
                    msKnee[0].kneeJoint_ternary === null &&
                    msKnee[0].above_knee_measurement_side_ternary === null &&
                    msKnee[0].patellar_tap_test_PN_ternary === null &&
                    msKnee[0].knee_condyle_value === null &&
                    msKnee[0].bellow_knee_measurement_side_ternary === null &&
                    msKnee[0].zohler_test_PN_ternary === null &&
                    msKnee[0].tibial_tubercle_value === null &&
                    msKnee[0].knee_flexion_PN_ternary === null &&
                    msKnee[0].knee_kxtention_PN_ternary === null &&

                    msKnee[0].medial_meniscus_PN_ternary === null &&
                    msKnee[0].lateral_meniscus_PN_ternary === null &&

                    msKnee[0].kneeLigamentInjury === null &&
                    msKnee[0].kneeMeniscusInjusy === null &&

                    msKnee[0].kneeMedialLigament === null &&
                    msKnee[0].kneeAnteriorCruciate === null &&
                    msKnee[0].kneeSagSign === null &&
                    msKnee[0].kneeLateralLigament === null &&
                    msKnee[0].kneePostCruciateLigament === null &&
                    msKnee[0].kneeWilsonTest === null &&
                    msKnee[0].kneeNobleCompression === null &&

                    msKnee[0].kneeHistoryValue === ""

                    ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Knee Joint : </strong></span> <br>

 ${msKnee[0].kneeHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msKnee[0].kneeHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}

     ${msKnee[0].kneeJoint_ternary === null ? `` : `Knee Joint : ${msKnee[0].kneeJoint_ternary} <br>`}

     ${msKnee[0].knee_anterior_view_object_value === "" &&
                        msKnee[0].lateral_view_object_value === "" &&
                        msKnee[0].knee_posterior_view_object_value === "" ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Inspection (Look) : </strong></span> <br>`
                    }
     ${msKnee[0].knee_anterior_view_object_value === "" ? `` : `Anterior View : ${msKnee[0].knee_anterior_view_object_value.replaceAll(",", " . ")} <br>`}
     ${msKnee[0].lateral_view_object_value === "" ? `` : `Lateral View : ${msKnee[0].lateral_view_object_value.replaceAll(",", " . ")} <br>`} 
     ${msKnee[0].knee_posterior_view_object_value === "" ? `` : `Posterior View : ${msKnee[0].knee_posterior_view_object_value.replaceAll(",", " . ")} <br>`} 
    

    ${msKnee[0].stance_object_value === "" ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Stance : </strong></span> <br>`
                    }
     ${msKnee[0].stance_object_value === "" ? `` : `Swat: (ask the patient to sit with knee flexed 90 Deg or dangling over couch) : ${msKnee[0].stance_object_value.replaceAll(",", " . ")} <br>`}


 ${msKnee[0].brush_or_wipe_test_PN_ternary === null &&
                        msKnee[0].kneeJoint_ternary === null &&
                        msKnee[0].above_knee_measurement_side_ternary === null &&
                        msKnee[0].patellar_tap_test_PN_ternary === null &&
                        msKnee[0].knee_condyle_value === null &&
                        msKnee[0].bellow_knee_measurement_side_ternary === null &&
                        msKnee[0].zohler_test_PN_ternary === null &&
                        msKnee[0].tibial_tubercle_value === null
                        ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Palpation (lying down position): </strong></span> <br>`
                    }

     ${msKnee[0].brush_or_wipe_test_PN_ternary === null ? `` : `Brush or wipe test: ${msKnee[0].brush_or_wipe_test_PN_ternary} <br>`} 
     ${msKnee[0].kneeJoint_ternary === null ? `` : `Joint Margin: ${msKnee[0].kneeJoint_ternary} <br>`} 
     ${msKnee[0].above_knee_measurement_side_ternary === null ? `` : `Above knee measurement: ${msKnee[0].above_knee_measurement_side_ternary} ${msKnee[0].above_knee_measurement} ${msKnee[0].above_knee_measurement_1}<br>`} 
     ${msKnee[0].patellar_tap_test_PN_ternary === null ? `` : `Patellar tap test : ${msKnee[0].patellar_tap_test_PN_ternary} <br>`} 
     ${msKnee[0].knee_condyle_value === null ? `` : `Condyle : ${msKnee[0].knee_condyle_value} <br>`} 
     ${msKnee[0].bellow_knee_measurement_side_ternary === null ? `` : `Bellow knee measurement: ${msKnee[0].bellow_knee_measurement_side_ternary} ${msKnee[0].bellow_knee_measurement}<br>`} 
     ${msKnee[0].zohler_test_PN_ternary === null ? `` : `Zohler's Test: ${msKnee[0].zohler_test_PN_ternary} <br>`} 
     ${msKnee[0].tibial_tubercle_value === null ? `` : `Tibial Tubercle: ${msKnee[0].tibial_tubercle_value} <br>`} 
 
 
     ${msKnee[0].knee_flexion_PN_ternary === null &&
                        msKnee[0].knee_kxtention_PN_ternary === null
                        ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Movement: </strong></span> <br>`
                    }

     ${msKnee[0].knee_flexion_PN_ternary === null ? `` : `Flexion 0° - 130° : ${msKnee[0].knee_flexion_PN_ternary} <br>`} 
     ${msKnee[0].knee_kxtention_PN_ternary === null ? `` : `Extension 0° - 15° : ${msKnee[0].knee_kxtention_PN_ternary} <br>`} 
    
     ${msKnee[0].medial_meniscus_PN_ternary === null &&
                        msKnee[0].lateral_meniscus_PN_ternary === null
                        ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Mc Murray’s Test: </strong></span> <br>`
                    }

     ${msKnee[0].medial_meniscus_PN_ternary === null ? `` : `Medial Meniscus : ${msKnee[0].medial_meniscus_PN_ternary}  ${msKnee[0].medialMeniscusSide}<br>`} 
     ${msKnee[0].lateral_meniscus_PN_ternary === null ? `` : `Lateral Meniscus : ${msKnee[0].lateral_meniscus_PN_ternary} ${msKnee[0].lateralMeniscusSide}<br>`} 
    
    
     ${msKnee[0].kneeLigamentInjury === null &&
                        msKnee[0].kneeMeniscusInjusy === null
                        ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Apley’s Test: </strong></span> <br>`
                    }

     ${msKnee[0].kneeLigamentInjury === null ? `` : `Rotation with Distraction (ligament injury): ${msKnee[0].kneeLigamentInjury}  ${msKnee[0].kneeLigamentInjurySide} ${msKnee[0].kneeMedialRotationLigament}<br>`} 
     ${msKnee[0].kneeMeniscusInjusy === null ? `` : `Rotation with compression (meniscus injury): ${msKnee[0].kneeMeniscusInjusy} ${msKnee[0].kneeMeniscusInjusySide} ${msKnee[0].kneeMedialRotationMeniscus}<br>`} 
    

     ${msKnee[0].kneeMedialLigament === null &&
                        msKnee[0].kneeAnteriorCruciate === null &&
                        msKnee[0].kneeSagSign === null &&
                        msKnee[0].kneeLateralLigament === null &&
                        msKnee[0].kneePostCruciateLigament === null &&
                        msKnee[0].kneeWilsonTest === null &&
                        msKnee[0].kneeNobleCompression === null
                        ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Ligament Test: </strong></span> <br>`
                    }

     ${msKnee[0].kneeMedialLigament === null ? `` : `Medial Ligament: ${msKnee[0].kneeMedialLigament}  ${msKnee[0].kneeMedialLigamentSide} <br>`} 
     ${msKnee[0].kneeAnteriorCruciate === null ? `` : `Anterior Cruciate: ${msKnee[0].kneeAnteriorCruciate} ${msKnee[0].kneeAnteriorCruciateSide} <br>`} 
     ${msKnee[0].kneeSagSign === null ? `` : `Sag Sign: ${msKnee[0].kneeSagSign} ${msKnee[0].kneeSagSignSide} <br>`} 
     ${msKnee[0].kneeLateralLigament === null ? `` : `Lateral Ligament: ${msKnee[0].kneeLateralLigament} ${msKnee[0].kneeLateralLigamentSide} <br>`} 
     ${msKnee[0].kneePostCruciateLigament === null ? `` : `Post Cruciate Ligament: ${msKnee[0].kneePostCruciateLigament} ${msKnee[0].kneePostCruciateLigamentSide} <br>`} 
     ${msKnee[0].kneeWilsonTest === null ? `` : `Wilson Test: ${msKnee[0].kneeWilsonTest} ${msKnee[0].kneeWilsonTestSide} <br>`} 
     ${msKnee[0].kneeNobleCompression === null ? `` : `Noble Compression: ${msKnee[0].kneeNobleCompression} ${msKnee[0].kneeNobleCompressionSide} <br>`} 
    
    </p>
    `}

 ${msAnkel[0].footInspectio === null &&
                    msAnkel[0].MTPJointSymptom === "" &&
                    msAnkel[0].footInspectio === null &&
                    msAnkel[0].MTPJointSymptom === "" &&
                    msAnkel[0].PIPJointSymptom === "" &&
                    msAnkel[0].DIPJointSymptom === "" &&
                    msAnkel[0].toesSymptomRight === "" &&
                    msAnkel[0].dorsumSymptom === "" &&
                    msAnkel[0].planterSymptom === "" &&
                    msAnkel[0].nailSymptom === "" &&

                    msAnkel[0].feelPalpation === null &&
                    msAnkel[0].feelPalpationMTPJointSide === null &&
                    msAnkel[0].feelPalpationDIPJoint === "" &&
                    msAnkel[0].feelPalpationPIPJoint === "" &&
                    msAnkel[0].feelPalpationMedialMalleolus === false &&
                    msAnkel[0].feelPalpationLateralMalleolus === false &&
                    msAnkel[0].feelPalpationTenderOver === "" &&
                    msAnkel[0].planterAspectOfFoot === "" &&
                    msAnkel[0].feelPalpationNailSymptom === "" &&
                    msAnkel[0].feelPalpationTender === false &&
                    msAnkel[0].feelPalpationWarm === false &&
                    msAnkel[0].feelPalpationPittingOedema === false &&

                    msAnkel[0].dorsiFlexionMove === "" &&
                    msAnkel[0].planterFlexionMove === "" &&
                    msAnkel[0].achilleTendonType === "" &&

                    msAnkel[0].planterReflex === null &&
                    msAnkel[0].ankleJerk === null &&

                    msAnkel[0].ankleS1 === null &&
                    msAnkel[0].ankleL4 === null &&
                    msAnkel[0].ankleL5 === null &&
                    msAnkel[0].ankleHistoryValue === ""
                    ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Ankle and foot : </strong></span><br>

         ${msAnkel[0].ankleHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msAnkel[0].ankleHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}
        ${msAnkel[0].footInspectio === null &&
                        msAnkel[0].MTPJointSymptom === "" &&
                        msAnkel[0].PIPJointSymptom === "" &&
                        msAnkel[0].DIPJointSymptom === "" &&
                        msAnkel[0].toesSymptomRight === "" &&
                        msAnkel[0].dorsumSymptom === "" &&
                        msAnkel[0].planterSymptom === "" &&
                        msAnkel[0].nailSymptom === ""
                        ? `` : `
                <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Inspection (Look) : </strong></span><br>
        `}

         ${msAnkel[0].footInspectio === null ? `` : `Inspection (Look) : ${msAnkel[0].footInspectio} <br>`}
         ${msAnkel[0].MTPJointSymptom === "" ? `` : `MTP Joint : ${msAnkel[0].MTPJointNumber === "" ? `` : `${msAnkel[0].MTPJointNumber.replaceAll(",", " . ")} |`} ${msAnkel[0].MTPJointSymptom.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].PIPJointSymptom === "" ? `` : `PIP Joint : ${msAnkel[0].PIPJointNumber === "" ? `` : `${msAnkel[0].PIPJointNumber.replaceAll(",", " . ")} |`} ${msAnkel[0].PIPJointSymptom.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].DIPJointSymptom === "" ? `` : `DIP Joint : ${msAnkel[0].DIPJointNumber === "" ? `` : `${msAnkel[0].DIPJointNumber.replaceAll(",", " . ")} |`} ${msAnkel[0].DIPJointSymptom.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].toesSymptomRight === "" ? `` : `Toes : ${msAnkel[0].toesSymptomNumber === "" ? `` : `${msAnkel[0].toesSymptomNumber.replaceAll(",", " . ")} |`} ${msAnkel[0].toesSymptomRight.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].dorsumSymptom === "" ? `` : `Dorsum of foot :  ${msAnkel[0].dorsumSymptom.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].planterSymptom === "" ? `` : `Plantar aspect of foot :  ${msAnkel[0].planterSymptom.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].nailSymptom === "" ? `` : `Nail : ${msAnkel[0].nailNumber === "" ? `` : `${msAnkel[0].nailNumber.replaceAll(",", " . ")} |`} ${msAnkel[0].nailSymptom.replaceAll(",", " . ")} <br>`}
       
       
         ${msAnkel[0].feelPalpation === null &&
                        msAnkel[0].feelPalpationMTPJointSide === null &&
                        msAnkel[0].feelPalpationDIPJoint === "" &&
                        msAnkel[0].feelPalpationPIPJoint === "" &&
                        msAnkel[0].feelPalpationMedialMalleolus === false &&
                        msAnkel[0].feelPalpationLateralMalleolus === false &&
                        msAnkel[0].feelPalpationTenderOver === "" &&
                        msAnkel[0].planterAspectOfFoot === "" &&
                        msAnkel[0].feelPalpationNailSymptom === "" &&
                        msAnkel[0].feelPalpationTender === false &&
                        msAnkel[0].feelPalpationWarm === false &&
                        msAnkel[0].feelPalpationPittingOedema === false

                        ? `` : `
                <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Feel (Palpation): </strong></span><br>
        `}

        
         ${msAnkel[0].feelPalpation === null ? `` : `Feel (Palpation) : ${msAnkel[0].feelPalpationToesLeft === "" ? `` : `${msAnkel[0].feelPalpationToesLeft.replaceAll(",", " . ")} |`} ${msAnkel[0].feelPalpation} <br>`}
         ${msAnkel[0].feelPalpationMTPJointSide === null ? `` : `MTP Joint : ${msAnkel[0].feelPalpationMTPJoint === "" ? `` : `${msAnkel[0].feelPalpationMTPJoint.replaceAll(",", " . ")} |`} ${msAnkel[0].feelPalpationMTPJointSide} <br>`}

         ${msAnkel[0].feelPalpationDIPJoint === "" ? `` : `DIP Joint : ${msAnkel[0].feelPalpationDIPJoint.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].feelPalpationPIPJoint === "" ? `` : `PIP Joint : ${msAnkel[0].feelPalpationPIPJoint.replaceAll(",", " . ")} <br>`}

         ${msAnkel[0].feelPalpationMedialMalleolus === false ? `` : `Medial Malleolus Tender <br>`}
         ${msAnkel[0].feelPalpationLateralMalleolus === false ? `` : `Lateral Malleolus Tender <br>`}

         ${msAnkel[0].feelPalpationTenderOver === "" ? `` : `Dorsum of foot :  ${msAnkel[0].feelPalpationTenderOver === "" ? `` : `${msAnkel[0].feelPalpationTenderOver.replaceAll(",", " . ")} |`} ${msAnkel[0].feelPalpationSwollenOver === "" ? `` : `${msAnkel[0].feelPalpationSwollenOver.replaceAll(",", " . ")} |`} ${msAnkel[0].arteriaDorsalisPedisPluse === null ? `` : `${msAnkel[0].arteriaDorsalisPedisPluse} |`} ${msAnkel[0].tibialisPosteriorPluse === null ? `` : `${msAnkel[0].tibialisPosteriorPluse} |`}<br>`}
         ${msAnkel[0].planterAspectOfFoot === "" ? `` : `Plantar aspect of foot : ${msAnkel[0].planterAspectOfFoot.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].feelPalpationNailSymptom === "" ? `` : `Nail : ${msAnkel[0].feelPalpationNailSide === null ? `` : `${msAnkel[0].feelPalpationNailSide} |`} ${msAnkel[0].feelPalpationNailNumber === "" ? `` : `${msAnkel[0].feelPalpationNailNumber.replaceAll(",", " . ")} |`} ${msAnkel[0].feelPalpationNailSymptom.replaceAll(",", " . ")} <br>`}

         ${msAnkel[0].feelPalpationTender === false ? `` : `Tender <br>`}
         ${msAnkel[0].feelPalpationWarm === false ? `` : `Warm <br>`}
         ${msAnkel[0].feelPalpationPittingOedema === false ? `` : `Pitting oedema <br>`}

   ${msAnkel[0].dorsiFlexionMove === "" &&
                        msAnkel[0].planterFlexionMove === "" &&
                        msAnkel[0].achilleTendonType === ""
                        ? `` : `
                <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Move : </strong></span><br>
   
   `}
         ${msAnkel[0].dorsiFlexionMove === "" ? `` : `Dorsi Flexion  20° : ${msAnkel[0].dorsiFlexionMove.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].planterFlexionMove === "" ? `` : `Plantar Flexion  50° : ${msAnkel[0].planterFlexionMove.replaceAll(",", " . ")} <br>`}
         ${msAnkel[0].achilleTendonType === "" ? `` : `Achilles Tendon: ${msAnkel[0].achilleTendonType.replaceAll(",", " . ")} <br>`}
 
         ${msAnkel[0].planterReflex === null &&
                        msAnkel[0].ankleJerk === null
                        ? `` : `
                <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Test: </strong></span><br>
   
   `}
         ${msAnkel[0].planterReflex === null ? `` : `Plantar Reflex : ${msAnkel[0].planterReflex} <br>`}
         ${msAnkel[0].ankleJerk === null ? `` : `Ankle Jerk : ${msAnkel[0].ankleJerk} <br>`}
        
         ${msAnkel[0].ankleS1 === null &&
                        msAnkel[0].ankleL4 === null &&
                        msAnkel[0].ankleL5 === null
                        ? `` : `
                <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Sensation: Over : </strong></span><br>
   
   `}
         ${msAnkel[0].ankleS1 === null ? `` : `S 1 : ${msAnkel[0].ankleS1} <br>`}
         ${msAnkel[0].ankleL4 === null ? `` : `L 4 : ${msAnkel[0].ankleL4} <br>`}
         ${msAnkel[0].ankleL5 === null ? `` : `L 5 : ${msAnkel[0].ankleL5} <br>`}



 </p>`}


${msShoulder[0].shoulder_LR_ternary === null &&
                    msShoulder[0].shapCounter === "" &&
                    msShoulder[0].anteriorView === "" &&
                    msShoulder[0].level_of_spine_value === null &&
                    msShoulder[0].winged_scapula_value === null &&

                    msShoulder[0].sternum_ternary_value === null &&
                    msShoulder[0].ac_joint_ternary_value === null &&
                    msShoulder[0].subacromial_ternary_value === null &&
                    msShoulder[0].coracoid_process_ternary_value === null &&
                    msShoulder[0].joint_margin_ternary_value === null &&
                    msShoulder[0].spineof_scapula_ternary_value === null &&
                    msShoulder[0].scapula_ternary_value === null &&
                    msShoulder[0].infraspinatus_ternary_value === null &&
                    msShoulder[0].supra_spinatus_ternary_value === null &&
                    msShoulder[0].axillary_ternary_value === null &&

                    msShoulder[0].abduction170 === null &&
                    msShoulder[0].abduction50 === null &&
                    msShoulder[0].Flexion160 === null &&
                    msShoulder[0].Extension50 === null &&
                    msShoulder[0].LateralRotation80 === null &&
                    msShoulder[0].MedialRotation === null &&

                    msShoulder[0].painful_arc_value === null &&
                    msShoulder[0].resistedMovementside === null &&

                    msShoulder[0].acromioclavicular_PN_ternary === null &&
                    msShoulder[0].scapular_assistance_PN_ternary === null &&
                    msShoulder[0].lateral_slide_PN_ternary === null &&
                    msShoulder[0].impigement_neer_PN_ternary === null &&
                    msShoulder[0].impigement_hawkinskennedy_PN_ternary === null &&
                    msShoulder[0].instability_load_shift_PN_ternary === null &&
                    msShoulder[0].instability_apprehension_PN_ternary === null &&
                    msShoulder[0].inferior_sulcus_sign_PN_ternary === null &&
                    msShoulder[0].SLAPLeison_obriens_PN_ternary === null &&
                    msShoulder[0].specific_palpation_PN_ternary === null &&
                    msShoulder[0].painful_arc_value === null &&

                    msShoulder[0].shoulderHistoryValue === ""

                    ? `` : `
 <p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Shoulder :</strong></span><br>
    
    ${msShoulder[0].shoulderHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msShoulder[0].shoulderHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}

         ${msShoulder[0].shoulder_LR_ternary === null ? `` : `Shoulder : ${msShoulder[0].shoulder_LR_ternary} <br>`}
    
         ${msShoulder[0].shapCounter === "" &&
                        msShoulder[0].anteriorView === "" &&
                        msShoulder[0].level_of_spine_value === null &&
                        msShoulder[0].winged_scapula_value === null ? `` : `
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Inspection :</strong></span><br>
          `}

         ${msShoulder[0].shapCounter === "" ? `` : `Shape & Contour : ${msShoulder[0].shapCounter} <br>`}
         ${msShoulder[0].anteriorView === "" ? `` : `Anterior View : ${msShoulder[0].anteriorView} <br>`}
         ${msShoulder[0].level_of_spine_value === null && msShoulder[0].winged_scapula_value === null ? `` : `Posterior View : Level of spine of scapula : ${msShoulder[0].level_of_spine_value === null ? "" : `${msShoulder[0].level_of_spine_value}`}  .  ${msShoulder[0].winged_scapula_value === null ? `` : `Winged scapula : ${msShoulder[0].winged_scapula_value}`}<br>`}

         ${msShoulder[0].sternum_ternary_value === null &&
                        msShoulder[0].ac_joint_ternary_value === null &&
                        msShoulder[0].subacromial_ternary_value === null &&
                        msShoulder[0].coracoid_process_ternary_value === null &&
                        msShoulder[0].joint_margin_ternary_value === null &&
                        msShoulder[0].spineof_scapula_ternary_value === null &&
                        msShoulder[0].scapula_ternary_value === null &&
                        msShoulder[0].infraspinatus_ternary_value === null &&
                        msShoulder[0].supra_spinatus_ternary_value === null &&
                        msShoulder[0].axillary_ternary_value === null ? `` :
                        `
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Palpation: </strong></span><br>

`}
        
         ${msShoulder[0].sternum_ternary_value === null ? `` : `Sternum: ${msShoulder[0].sternum_ternary_value} <br>`}
         ${msShoulder[0].ac_joint_ternary_value === null ? `` : `AC - Joint: ${msShoulder[0].ac_joint_ternary_value} <br>`}
         ${msShoulder[0].subacromial_ternary_value === null ? `` : `Subacromial Space: ${msShoulder[0].subacromial_ternary_value} <br>`}
         ${msShoulder[0].coracoid_process_ternary_value === null ? `` : `Coracoid Process: ${msShoulder[0].coracoid_process_ternary_value} <br>`}
         ${msShoulder[0].joint_margin_ternary_value === null ? `` : `Joint Margin: ${msShoulder[0].joint_margin_ternary_value} <br>`}
         ${msShoulder[0].spineof_scapula_ternary_value === null ? `` : `Spine of Scapula: ${msShoulder[0].spineof_scapula_ternary_value} <br>`}
         ${msShoulder[0].scapula_ternary_value === null ? `` : `Scapula: ${msShoulder[0].scapula_ternary_value} <br>`}
         ${msShoulder[0].infraspinatus_ternary_value === null ? `` : `Infraspinatus Tendon: ${msShoulder[0].infraspinatus_ternary_value} <br>`}
         ${msShoulder[0].supra_spinatus_ternary_value === null ? `` : `Supraspinatus Tendon: ${msShoulder[0].supra_spinatus_ternary_value} <br>`}
         ${msShoulder[0].axillary_ternary_value === null ? `` : `Axillary Lymphadenopathy: ${msShoulder[0].axillary_ternary_value} <br>`}

${msShoulder[0].abduction170 === null &&
                        msShoulder[0].abduction50 === null &&
                        msShoulder[0].Flexion160 === null &&
                        msShoulder[0].Extension50 === null &&
                        msShoulder[0].LateralRotation80 === null &&
                        msShoulder[0].MedialRotation === null ? `` : `
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Movements: </strong></span><br>

`}

         ${msShoulder[0].abduction170 === null ? `` : `Abduction (170° - 180°) : ${msShoulder[0].abduction170}° <br>`}
         ${msShoulder[0].abduction50 === null ? `` : `Adduction (50° - 75°) : ${msShoulder[0].abduction50}° <br>`}
         ${msShoulder[0].Flexion160 === null ? `` : `Flexion (160° - 180°) : ${msShoulder[0].Flexion160}° <br>`}
         ${msShoulder[0].Extension50 === null ? `` : `Extension (50° - 60°) : ${msShoulder[0].Extension50}° <br>`}
         ${msShoulder[0].LateralRotation80 === null ? `` : `Lateral Rotation (80° - 90° ) : ${msShoulder[0].LateralRotation80}° <br>`}
         ${msShoulder[0].MedialRotation === null ? `` : `Medial Rotation: ${msShoulder[0].MedialRotation}° <br>`}

${msShoulder[0].painful_arc_value === null &&
                        msShoulder[0].resistedMovementside === null ? `` : `
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Test: </strong></span><br>

`}
        
         ${msShoulder[0].painful_arc_value === null ? `` : `Painful Arc: ${msShoulder[0].painful_arc_value} <br>`}
         ${msShoulder[0].resistedMovementside === null ? `` : `Resisted Movement: ${msShoulder[0].resistedMovementside} <br>`}

${msShoulder[0].acromioclavicular_PN_ternary === null &&
                        msShoulder[0].scapular_assistance_PN_ternary === null &&
                        msShoulder[0].lateral_slide_PN_ternary === null &&
                        msShoulder[0].impigement_neer_PN_ternary === null &&
                        msShoulder[0].impigement_hawkinskennedy_PN_ternary === null &&
                        msShoulder[0].instability_load_shift_PN_ternary === null &&
                        msShoulder[0].instability_apprehension_PN_ternary === null &&
                        msShoulder[0].inferior_sulcus_sign_PN_ternary === null &&
                        msShoulder[0].SLAPLeison_obriens_PN_ternary === null &&
                        msShoulder[0].specific_palpation_PN_ternary === null &&
                        msShoulder[0].painful_arc_value === null ? `` : `
         <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Special Test: </strong></span><br>

`}

         ${msShoulder[0].acromioclavicular_PN_ternary === null ? `` : `Acromioclavicular Joint O’Briens Test: ${msShoulder[0].acromioclavicular_PN_ternary} <br>`}

        ${msShoulder[0].scapular_assistance_PN_ternary === null ? `` : `Scapular Assistance Test (SAT): ${msShoulder[0].scapular_assistance_PN_ternary} <br>`}

        ${msShoulder[0].lateral_slide_PN_ternary === null ? `` : `Lateral Slide Test (LST): ${msShoulder[0].lateral_slide_PN_ternary} <br>`}

        ${msShoulder[0].impigement_neer_PN_ternary === null ? `` : `Impingement  - Neer test: ${msShoulder[0].impigement_neer_PN_ternary} <br>`}

        ${msShoulder[0].impigement_hawkinskennedy_PN_ternary === null ? `` : `Impingement  - Hawkins/kennedy test: ${msShoulder[0].impigement_hawkinskennedy_PN_ternary} <br>`}

        ${msShoulder[0].instability_load_shift_PN_ternary === null ? `` : `Instability - load and shift test: ${msShoulder[0].instability_load_shift_PN_ternary} <br>`}

        ${msShoulder[0].instability_apprehension_PN_ternary === null ? `` : `Instability - apprehension augmentation, relocation test: ${msShoulder[0].instability_apprehension_PN_ternary} <br>`}

        ${msShoulder[0].inferior_sulcus_sign_PN_ternary === null ? `` : `Inferior - sulcus sign: ${msShoulder[0].inferior_sulcus_sign_PN_ternary} <br>`}

        ${msShoulder[0].SLAPLeison_obriens_PN_ternary === null ? `` : `SLAP leison O’Briens test: ${msShoulder[0].SLAPLeison_obriens_PN_ternary} <br>`}

        ${msShoulder[0].specific_palpation_PN_ternary === null ? `` : `Specific palpation for trigger point: ${msShoulder[0].specific_palpation_PN_ternary} <br>`}

        ${msShoulder[0].painful_arc_value === null ? `` : `Upper limb neuro - dynamic test: ${msShoulder[0].painful_arc_value} <br>`}

    </p>`}

${msHand[0].hand_wrist_ternary === null &&
                    msHand[0].wrist_Arr === "" &&
                    msHand[0].distal_side_value === null &&
                    msHand[0].metacarpophalangeal_side_value === null &&
                    msHand[0].nails_side_value === null &&
                    msHand[0].proximal_side_value === null &&

                    msHand[0].dorsum_of_hand_value === null &&
                    msHand[0].fingers_side_value === null &&
                    msHand[0].palpation_interphalangeal_side_value === null &&
                    msHand[0].palpation_tenders_Arr === "" &&
                    msHand[0].palpation_wrist_Arr === "" &&
                    msHand[0].flexon_tendons_side_value === null &&
                    msHand[0].palpation_metacarpophalangeal_side_value === null &&

                    msHand[0].hand_movement_wrist_NR_ternary === null &&
                    msHand[0].hand_movement_finger === null &&

                    msHand[0].hand_movement_flexion_NR_ternary === null &&
                    msHand[0].hand_movement_extension_NR_ternary === null &&
                    msHand[0].hand_movement_supination_NR_ternary === null &&
                    msHand[0].hand_movement_pronation_NR_ternary === null &&

                    msHand[0].finkelstein_test_ternary === false &&
                    msHand[0].hand_murphy_sign_PN_ternary === null &&
                    msHand[0].sweater_finger_sign_PN_ternary === null &&
                    msHand[0].wrish_flexion_test_PN_ternary === null &&
                    msHand[0].handTinel_sign_PN_ternary === null &&
                    msHand[0].flexor_digitorum_superficialis_test_value === null &&
                    msHand[0].flexor_digitorum_profundus_test_value === null &&
                    msHand[0].watson_test_PN_ternary === null &&
                    msHand[0].quervain_tenosynovitis_ternary === null &&

                    msHand[0].handHistoryValue === ""

                    ? `` :
                    `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">

    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Hand & Wrist : </strong></span> <br>


    ${msHand[0].handHistoryValue === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msHand[0].handHistoryValue.replaceAll(",", " . ")}
</span></span></p> `}


         ${msHand[0].hand_wrist_ternary === null ? `` : `Hand & Wrist : ${msHand[0].hand_wrist_ternary} <br>`}

${msHand[0].wrist_Arr === "" &&
                        msHand[0].distal_side_value === null &&
                        msHand[0].metacarpophalangeal_side_value === null &&
                        msHand[0].nails_side_value === null &&
                        msHand[0].proximal_side_value === null ? `` : `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Inspection (Look) : </strong></span> <br>`}
        
         ${msHand[0].wrist_Arr === "" ? `` : `Wrist : ${msHand[0].wrist_Arr} <br>`}
         ${msHand[0].distal_side_value === null ? `` : `Distal Interphalangeal Joint : ${msHand[0].distal_side_value} | ${msHand[0].distal_number_value} | ${msHand[0].distal_Arr}<br>`}
         ${msHand[0].metacarpophalangeal_side_value === null ? `` : `Metacarpophalangeal Joint : ${msHand[0].metacarpophalangeal_side_value} | ${msHand[0].metacarpophalangeal_number_value} | ${msHand[0].metacarpophalangeal_Arr}<br>`}
         ${msHand[0].nails_side_value === null ? `` : `Nails : ${msHand[0].nails_side_value} | ${msHand[0].nails_number_value} | ${msHand[0].nails_Arr}<br>`}
         ${msHand[0].proximal_side_value === null ? `` : `Proximal Interphalangeal Joint : ${msHand[0].proximal_side_value} | ${msHand[0].proximal_number_value} | ${msHand[0].proximal_Arr}<br>`}

${msHand[0].dorsum_of_hand_value === null &&
                        msHand[0].fingers_side_value === null &&
                        msHand[0].palpation_interphalangeal_side_value === null &&
                        msHand[0].palpation_tenders_Arr === "" &&
                        msHand[0].palpation_wrist_Arr === "" &&
                        msHand[0].flexon_tendons_side_value === null &&
                        msHand[0].palpation_metacarpophalangeal_side_value === null ? `` : `
        <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Palpation : </strong></span> <br>
`}


         ${msHand[0].dorsum_of_hand_value === null ? `` : `Dorsum of Hand : ${msHand[0].dorsum_of_hand_value} <br>`}
         ${msHand[0].fingers_side_value === null ? `` : `Fingers : ${msHand[0].fingers_side_value} | ${msHand[0].fingers_number_value} | ${msHand[0].fingers_hand_Arr}<br>`}
         ${msHand[0].palpation_interphalangeal_side_value === null ? `` : `Proximal Interphalangeal Joint : ${msHand[0].palpation_interphalangeal_side_value} | ${msHand[0].palpation_interphalangeal_number_value} | ${msHand[0].palpation_interphalangeal_Arr}<br>`}
         ${msHand[0].palpation_tenders_Arr === "" ? `` : `Tenders : ${msHand[0].palpation_tenders_Arr} <br>`}
         ${msHand[0].palpation_wrist_Arr === "" ? `` : `Wrist : ${msHand[0].palpation_wrist_Arr} <br>`}
         ${msHand[0].flexon_tendons_side_value === null ? `` : `Flexon Tendons : ${msHand[0].flexon_tendons_side_value} | ${msHand[0].flexon_tendons_number_value} | ${msHand[0].flexon_tendons_Arr}<br>`}
         ${msHand[0].palpation_metacarpophalangeal_side_value === null ? `` : `Metacarpophalangeal Joint : ${msHand[0].palpation_metacarpophalangeal_side_value} | ${msHand[0].palpation_metacarpophalangeal_number_value} | ${msHand[0].palpation_metacarpophalangeal_Arr}<br>`}


${msHand[0].hand_movement_wrist_NR_ternary === null &&
                        msHand[0].hand_movement_finger === null ? `` : `
        <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Movement : </strong></span> <br>
`}

         ${msHand[0].hand_movement_wrist_NR_ternary === null ? `` : `Wrist : ${msHand[0].hand_movement_wrist_NR_ternary} <br>`}
         ${msHand[0].hand_movement_finger === null ? `` : `Finger : ${msHand[0].hand_movement_finger} <br>`}

         ${msHand[0].hand_movement_flexion_NR_ternary === null &&
                        msHand[0].hand_movement_extension_NR_ternary === null &&
                        msHand[0].hand_movement_supination_NR_ternary === null &&
                        msHand[0].hand_movement_pronation_NR_ternary === null ? `` : `
          <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Active : </strong></span> <br>

`}

         ${msHand[0].hand_movement_flexion_NR_ternary === null ? `` : `Flexion : ${msHand[0].hand_movement_flexion_NR_ternary} <br>`}
         ${msHand[0].hand_movement_extension_NR_ternary === null ? `` : `Extension : ${msHand[0].hand_movement_extension_NR_ternary} <br>`}
         ${msHand[0].hand_movement_supination_NR_ternary === null ? `` : `Supination : ${msHand[0].hand_movement_supination_NR_ternary} <br>`}
         ${msHand[0].hand_movement_pronation_NR_ternary === null ? `` : `Pronation : ${msHand[0].hand_movement_pronation_NR_ternary} <br>`}
        
         ${msHand[0].finkelstein_test_ternary === false &&
                        msHand[0].hand_murphy_sign_PN_ternary === null &&
                        msHand[0].sweater_finger_sign_PN_ternary === null &&
                        msHand[0].wrish_flexion_test_PN_ternary === null &&
                        msHand[0].handTinel_sign_PN_ternary === null &&
                        msHand[0].flexor_digitorum_superficialis_test_value === null &&
                        msHand[0].flexor_digitorum_profundus_test_value === null &&
                        msHand[0].watson_test_PN_ternary === null &&
                        msHand[0].quervain_tenosynovitis_ternary === null ? `` : `
          <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Test : </strong></span> <br>

` }

         ${msHand[0].finkelstein_test_ternary === false ? `` : `Finkelstein’s Test <br>`}
         ${msHand[0].hand_murphy_sign_PN_ternary === null ? `` : `Murphy’s Sign : ${msHand[0].hand_murphy_sign_PN_ternary} <br>`}
         ${msHand[0].sweater_finger_sign_PN_ternary === null ? `` : `Sweater Finger Sign : ${msHand[0].sweater_finger_sign_PN_ternary} <br>`}
         ${msHand[0].wrish_flexion_test_PN_ternary === null ? `` : `Phalen’s Wrist Flexion Test : ${msHand[0].wrish_flexion_test_PN_ternary} <br>`}
         ${msHand[0].handTinel_sign_PN_ternary === null ? `` : `Tinel’s Sign : ${msHand[0].handTinel_sign_PN_ternary} <br>`}
         ${msHand[0].flexor_digitorum_superficialis_test_value === null ? `` : `Flexor Digitorum Superficialis Test : ${msHand[0].flexor_digitorum_superficialis_test_value} <br>`}
         ${msHand[0].flexor_digitorum_profundus_test_value === null ? `` : `Flexor Digitorum Profundus Test : ${msHand[0].flexor_digitorum_profundus_test_value} <br>`}
         ${msHand[0].watson_test_PN_ternary === null ? `` : `Watson Test : ${msHand[0].watson_test_PN_ternary} <br>`}
         ${msHand[0].quervain_tenosynovitis_ternary === null ? `` : `de Quervain’s Tenosynovitis : ${msHand[0].quervain_tenosynovitis_ternary} <br>`}

</p>`}


${msElbow[0].elbow_joint_ternary === null
                    && msElbow[0].elbow_history_Arr === "" &&

                    msElbow[0].elbow_female_value === null &&
                    msElbow[0].elbow_male_value === null &&
                    msElbow[0].contractual_deformity_ternary === false &&
                    msElbow[0].elbow_scars_ternary === false &&
                    msElbow[0].swellings_ternary === false &&
                    msElbow[0].soft_tissue_control_normal_ternary === false &&

                    msElbow[0].elbow_tenderness_ternary === false &&
                    msElbow[0].elbow_deformity_ternary === false &&
                    msElbow[0].elbow_effussion_ternary === false &&
                    msElbow[0].elbow_crepitus_ternary === false &&
                    msElbow[0].elbow_muscle_wasting_ternary === false &&
                    msElbow[0].at_flexion_Arr === "" &&
                    msElbow[0].at_full_flexion_Arr === "" &&
                    msElbow[0].elbow_exam_flextion_value === null && msElbow[0].elbow_exam_flextion_number_value === null &&
                    msElbow[0].elbow_exam_extention_value === null && msElbow[0].elbow_exam_extention_number_value === null &&
                    msElbow[0].elbow_exam_supination_value === null && msElbow[0].elbow_exam_supination_number_value === null &&
                    msElbow[0].elbow_exam_pronation_value === null && msElbow[0].elbow_exam_pronation_number_value === null &&

                    msElbow[0].elbowHistoryVal === ""
                    ? `` : `
<p class="MsoNormal" style="margin: 0in 0in 0px ; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">  
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Elbow Joint :</strong></span><br>


    ${msElbow[0].elbowHistoryVal === "" ? `` :
                        `<p class="MsoNormal" style="margin: 0in 0in 0px ; font-size: 15px; font-family: Arial, sans-serif; line-height: 180%;">
    <strong><span style="font-family: Roboto; color: rgb(54, 54, 54);">History:</span></strong></p>

 <p class="MsoNormal" style="margin: 0in 0in 0px; font-family: Arial, sans-serif; line-height: 180%; font-size: 15px;">
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><span style="font-size: 15px;">
   ${msElbow[0].elbowHistoryVal.replaceAll(",", " . ")}
</span></span></p> `}

    ${msElbow[0].elbow_joint_ternary === null ? `` : `Elbow Joint : ${msElbow[0].elbow_joint_ternary} <br>`}

    ${msElbow[0].elbow_history_Arr === "" ? `` : `
     <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>History :</strong></span><br>
    ${msElbow[0].elbow_history_Arr.replaceAll(",", " . ")} <br>`}

    ${msElbow[0].elbow_female_value === null &&
                        msElbow[0].elbow_male_value === null &&
                        msElbow[0].contractual_deformity_ternary === false &&
                        msElbow[0].elbow_scars_ternary === false &&
                        msElbow[0].swellings_ternary === false &&
                        msElbow[0].soft_tissue_control_normal_ternary === false ? `` : `
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Inspection :</strong></span><br>
`}

    ${msElbow[0].elbow_female_value === null ? `` : `Female (10° - 15° ) : ${msElbow[0].elbow_female_value} <br>`}
    ${msElbow[0].elbow_male_value === null ? `` : `Male (5° - 10° ) : ${msElbow[0].elbow_male_value} <br>`}
    ${msElbow[0].contractual_deformity_ternary === false ? `` : `Contractual deformity <br>`}
    ${msElbow[0].elbow_scars_ternary === false ? `` : `Scars <br>`}
    ${msElbow[0].swellings_ternary === false ? `` : `Swellings <br>`}
    ${msElbow[0].soft_tissue_control_normal_ternary === false ? `` : `Soft tissue control normal <br>`}

    ${msElbow[0].elbow_tenderness_ternary === false &&
                        msElbow[0].elbow_deformity_ternary === false &&
                        msElbow[0].elbow_effussion_ternary === false &&
                        msElbow[0].elbow_crepitus_ternary === false &&
                        msElbow[0].elbow_muscle_wasting_ternary === false &&
                        msElbow[0].at_flexion_Arr === "" &&
                        msElbow[0].at_full_flexion_Arr === "" ? `` : `
    <span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Palpation :</strong></span><br>
`}
   
    ${msElbow[0].elbow_tenderness_ternary === false ? `` : `Tenderness <br>`}
    ${msElbow[0].elbow_deformity_ternary === false ? `` : `Deformity <br>`}
    ${msElbow[0].elbow_effussion_ternary === false ? `` : `Effusion <br>`}
    ${msElbow[0].elbow_crepitus_ternary === false ? `` : `Crepitus <br>`}
    ${msElbow[0].elbow_muscle_wasting_ternary === false ? `` : `Muscle Wasting <br>`}
    ${msElbow[0].at_flexion_Arr === "" ? `` : `At 90° Flexion : ${msElbow[0].at_flexion_Arr.replaceAll(",", " . ")}<br>`}
    ${msElbow[0].at_full_flexion_Arr === "" ? `` : `At Full Flexion : ${msElbow[0].at_full_flexion_Arr.replaceAll(",", " . ")}<br>`}
 
${msElbow[0].elbow_exam_flextion_value === null && msElbow[0].elbow_exam_flextion_number_value === null &&
                        msElbow[0].elbow_exam_extention_value === null && msElbow[0].elbow_exam_extention_number_value === null &&
                        msElbow[0].elbow_exam_supination_value === null && msElbow[0].elbow_exam_supination_number_value === null &&
                        msElbow[0].elbow_exam_pronation_value === null && msElbow[0].elbow_exam_pronation_number_value === null ? `` :
                        `<span style="font-family: Roboto; color: rgb(54, 54, 54);"><strong>Examination :</strong></span><br>
`}

    ${msElbow[0].elbow_exam_flextion_value === null && msElbow[0].elbow_exam_flextion_number_value === null ? `` : `Flexion (140° - 150° ) : ${msElbow[0].elbow_exam_flextion_number_value === null ? `` : msElbow[0].elbow_exam_flextion_number_value} | ${msElbow[0].elbow_exam_flextion_value === null ? `` : msElbow[0].elbow_exam_flextion_value} <br>`}
    ${msElbow[0].elbow_exam_extention_value === null && msElbow[0].elbow_exam_extention_number_value === null ? `` : `Extension (0° - 10° ) : ${msElbow[0].elbow_exam_extention_number_value === null ? `` : msElbow[0].elbow_exam_extention_number_value} | ${msElbow[0].elbow_exam_extention_value === null ? `` : msElbow[0].elbow_exam_extention_value} <br>`}
    ${msElbow[0].elbow_exam_supination_value === null && msElbow[0].elbow_exam_supination_number_value === null ? `` : `Supination 90° : ${msElbow[0].elbow_exam_supination_number_value === null ? `` : msElbow[0].elbow_exam_supination_number_value} | ${msElbow[0].elbow_exam_supination_value === null ? `` : msElbow[0].elbow_exam_supination_value} <br>`}
    ${msElbow[0].elbow_exam_pronation_value === null && msElbow[0].elbow_exam_pronation_number_value === null ? `` : `Pronation (80° - 90° ) : ${msElbow[0].elbow_exam_pronation_number_value === null ? `` : msElbow[0].elbow_exam_pronation_number_value} | ${msElbow[0].elbow_exam_pronation_value === null ? `` : msElbow[0].elbow_exam_pronation_value} <br>`}
   
</p>`}

`;
            const data = {
                appointment_id: props?.appId,
                patient_id: props?.patient_id,
                note: note
            }
            axios.post('doctors-note', data)
                .then(res => console.log(res))
            props?.setUpdateForHistory(Math.random())

            toast.success("Data inserted sucessfully")
            setspiner(false)
        }, 5000)



    }



    //
    const [change, setChange] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    //

    const [change2, setChange2] = useState(false);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;
    //
    const [change3, setChange3] = useState(false);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'simple-popover' : undefined;
    //
    const [change4, setChange4] = useState(false);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const open4 = Boolean(anchorEl4);
    const id4 = open4 ? 'simple-popover' : undefined;
    //
    const [change5, setChange5] = useState(false);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const open5 = Boolean(anchorEl5);
    const id5 = open5 ? 'simple-popover' : undefined;
    //
    const [change6, setChange6] = useState(false);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const open6 = Boolean(anchorEl6);
    const id6 = open6 ? 'simple-popover' : undefined;
    //
    const [change7, setChange7] = useState(false);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const open7 = Boolean(anchorEl7);
    const id7 = open7 ? 'simple-popover' : undefined;
    //
    const [change8, setChange8] = useState(false);
    const [anchorEl8, setAnchorEl8] = useState(null);
    const open8 = Boolean(anchorEl8);
    const id8 = open8 ? 'simple-popover' : undefined;
    //
    const [change9, setChange9] = useState(false);
    const [anchorEl9, setAnchorEl9] = useState(null);
    const open9 = Boolean(anchorEl9);
    const id9 = open9 ? 'simple-popover' : undefined;
    //
    const [change10, setChange10] = useState(false);
    const [anchorEl10, setAnchorEl10] = useState(null);
    const open10 = Boolean(anchorEl10);
    const id10 = open10 ? 'simple-popover' : undefined;
    //
    const [change11, setChange11] = useState(false);
    const [anchorEl11, setAnchorEl11] = useState(null);
    const open11 = Boolean(anchorEl11);
    const id11 = open11 ? 'simple-popover' : undefined;
    //
    const [change12, setChange12] = useState(false);
    const [anchorEl12, setAnchorEl12] = useState(null);
    const open12 = Boolean(anchorEl12);
    const id12 = open12 ? 'simple-popover' : undefined;
    //
    const [change13, setChange13] = useState(false);
    const [anchorEl13, setAnchorEl13] = useState(null);
    const open13 = Boolean(anchorEl13);
    const id13 = open13 ? 'simple-popover' : undefined;
    //
    const [change14, setChange14] = useState(false);
    const [anchorEl14, setAnchorEl14] = useState(null);
    const open14 = Boolean(anchorEl14);
    const id14 = open14 ? 'simple-popover' : undefined;
    //
    const [change15, setChange15] = useState(false);
    const [anchorEl15, setAnchorEl15] = useState(null);
    const open15 = Boolean(anchorEl15);
    const id15 = open15 ? 'simple-popover' : undefined;
    //
    const [change16, setChange16] = useState(false);
    const [anchorEl16, setAnchorEl16] = useState(null);
    const open16 = Boolean(anchorEl16);
    const id16 = open16 ? 'simple-popover' : undefined;
    //
    const [change17, setChange17] = useState(false);
    const [anchorEl17, setAnchorEl17] = useState(null);
    const open17 = Boolean(anchorEl17);
    const id17 = open17 ? 'simple-popover' : undefined;
    //
    const [change18, setChange18] = useState(false);
    const [anchorEl18, setAnchorEl18] = useState(null);
    const open18 = Boolean(anchorEl18);
    const id18 = open18 ? 'simple-popover' : undefined;
    //
    const [change19, setChange19] = useState(false);
    const [anchorEl19, setAnchorEl19] = useState(null);
    const open19 = Boolean(anchorEl19);
    const id19 = open19 ? 'simple-popover' : undefined;
    //
    const [change20, setChange20] = useState(false);
    const [anchorEl20, setAnchorEl20] = useState(null);
    const open20 = Boolean(anchorEl20);
    const id20 = open20 ? 'simple-popover' : undefined;

    //
    const [change21, setChange21] = useState(false);
    const [anchorEl21, setAnchorEl21] = useState(null);
    const open21 = Boolean(anchorEl21);
    const id21 = open21 ? 'simple-popover' : undefined;
    //
    const [change22, setChange22] = useState(false);
    const [anchorEl22, setAnchorEl22] = useState(null);
    const open22 = Boolean(anchorEl22);
    const id22 = open22 ? 'simple-popover' : undefined;
    //
    const [change23, setChange23] = useState(false);
    const [anchorEl23, setAnchorEl23] = useState(null);
    const open23 = Boolean(anchorEl23);
    const id23 = open23 ? 'simple-popover' : undefined;
    //
    const [change24, setChange24] = useState(false);
    const [anchorEl24, setAnchorEl24] = useState(null);
    const open24 = Boolean(anchorEl24);
    const id24 = open24 ? 'simple-popover' : undefined;
    //
    const [change25, setChange25] = useState(false);
    const [anchorEl25, setAnchorEl25] = useState(null);
    const open25 = Boolean(anchorEl25);
    const id25 = open25 ? 'simple-popover' : undefined;
    //
    const [change26, setChange26] = useState(false);
    const [anchorEl26, setAnchorEl26] = useState(null);
    const open26 = Boolean(anchorEl26);
    const id26 = open26 ? 'simple-popover' : undefined;
    //
    const [change27, setChange27] = useState(false);
    const [anchorEl27, setAnchorEl27] = useState(null);
    const open27 = Boolean(anchorEl27);
    const id27 = open27 ? 'simple-popover' : undefined;
    //
    const [change28, setChange28] = useState(false);
    const [anchorEl28, setAnchorEl28] = useState(null);
    const open28 = Boolean(anchorEl28);
    const id28 = open28 ? 'simple-popover' : undefined;
    //
    const [change29, setChange29] = useState(false);
    const [anchorEl29, setAnchorEl29] = useState(null);
    const open29 = Boolean(anchorEl29);
    const id29 = open29 ? 'simple-popover' : undefined;
    //
    const [change30, setChange30] = useState(false);
    const [anchorEl30, setAnchorEl30] = useState(null);
    const open30 = Boolean(anchorEl30);
    const id30 = open30 ? 'simple-popover' : undefined;
    //
    const [change31, setChange31] = useState(false);
    const [anchorEl31, setAnchorEl31] = useState(null);
    const open31 = Boolean(anchorEl31);
    const id31 = open31 ? 'simple-popover' : undefined;
    //
    const [change32, setChange32] = useState(false);
    const [anchorEl32, setAnchorEl32] = useState(null);
    const open32 = Boolean(anchorEl32);
    const id32 = open32 ? 'simple-popover' : undefined;
    //
    const [change33, setChange33] = useState(false);
    const [anchorEl33, setAnchorEl33] = useState(null);
    const open33 = Boolean(anchorEl33);
    const id33 = open33 ? 'simple-popover' : undefined;
    //
    const [change34, setChange34] = useState(false);
    const [anchorEl34, setAnchorEl34] = useState(null);
    const open34 = Boolean(anchorEl34);
    const id34 = open34 ? 'simple-popover' : undefined;
    //
    const [change35, setChange35] = useState(false);
    const [anchorEl35, setAnchorEl35] = useState(null);
    const open35 = Boolean(anchorEl35);
    const id35 = open35 ? 'simple-popover' : undefined;
    //
    const [change36, setChange36] = useState(false);
    const [anchorEl36, setAnchorEl36] = useState(null);
    const open36 = Boolean(anchorEl36);
    const id36 = open36 ? 'simple-popover' : undefined;
    //
    const [change37, setChange37] = useState(false);
    const [anchorEl37, setAnchorEl37] = useState(null);
    const open37 = Boolean(anchorEl37);
    const id37 = open37 ? 'simple-popover' : undefined;
    //
    const [change38, setChange38] = useState(false);
    const [anchorEl38, setAnchorEl38] = useState(null);
    const open38 = Boolean(anchorEl38);
    const id38 = open38 ? 'simple-popover' : undefined;
    //
    const [change39, setChange39] = useState(false);
    const [anchorEl39, setAnchorEl39] = useState(null);
    const open39 = Boolean(anchorEl39);
    const id39 = open39 ? 'simple-popover' : undefined;
    //
    const [change40, setChange40] = useState(false);
    const [anchorEl40, setAnchorEl40] = useState(null);
    const open40 = Boolean(anchorEl40);
    const id40 = open40 ? 'simple-popover' : undefined;
    //
    const [change42, setChange42] = useState(false);
    const [anchorEl42, setAnchorEl42] = useState(null);
    const open42 = Boolean(anchorEl42);
    const id42 = open42 ? 'simple-popover' : undefined;
    //
    const [change43, setChange43] = useState(false);
    const [anchorEl43, setAnchorEl43] = useState(null);
    const open43 = Boolean(anchorEl43);
    const id43 = open43 ? 'simple-popover' : undefined;
    //
    const [change44, setChange44] = useState(false);
    const [anchorEl44, setAnchorEl44] = useState(null);
    const open44 = Boolean(anchorEl44);
    const id44 = open44 ? 'simple-popover' : undefined;
    //
    const [change45, setChange45] = useState(false);
    const [anchorEl45, setAnchorEl45] = useState(null);
    const open45 = Boolean(anchorEl45);
    const id45 = open45 ? 'simple-popover' : undefined;
    //
    const [change466, setChange466] = useState(false);
    const [anchorEl466, setAnchorEl466] = useState(null);
    const open466 = Boolean(anchorEl466);
    const id466 = open466 ? 'simple-popover' : undefined;
    //
    const [change46, setChange46] = useState(false);
    const [anchorEl46, setAnchorEl46] = useState(null);
    const open46 = Boolean(anchorEl46);
    const id46 = open46 ? 'simple-popover' : undefined;
    //
    const [change47, setChange47] = useState(false);
    const [anchorEl47, setAnchorEl47] = useState(null);
    const open47 = Boolean(anchorEl47);
    const id47 = open47 ? 'simple-popover' : undefined;
    //
    const [change48, setChange48] = useState(false);
    const [anchorEl48, setAnchorEl48] = useState(null);
    const open48 = Boolean(anchorEl48);
    const id48 = open48 ? 'simple-popover' : undefined;
    //
    const [change49, setChange49] = useState(false);
    const [anchorEl49, setAnchorEl49] = useState(null);
    const open49 = Boolean(anchorEl49);
    const id49 = open49 ? 'simple-popover' : undefined;
    //
    const [change50, setChange50] = useState(false);
    const [anchorEl50, setAnchorEl50] = useState(null);
    const open50 = Boolean(anchorEl50);
    const id50 = open50 ? 'simple-popover' : undefined;
    //
    const [change51, setChange51] = useState(false);
    const [anchorEl51, setAnchorEl51] = useState(null);
    const open51 = Boolean(anchorEl51);
    const id51 = open51 ? 'simple-popover' : undefined;
    //
    const [change52, setChange52] = useState(false);
    const [anchorEl52, setAnchorEl52] = useState(null);
    const open52 = Boolean(anchorEl52);
    const id52 = open52 ? 'simple-popover' : undefined;
    //
    const [change53, setChange53] = useState(false);
    const [anchorEl53, setAnchorEl53] = useState(null);
    const open53 = Boolean(anchorEl53);
    const id53 = open53 ? 'simple-popover' : undefined;
    //
    const [change54, setChange54] = useState(false);
    const [anchorEl54, setAnchorEl54] = useState(null);
    const open54 = Boolean(anchorEl54);
    const id54 = open54 ? 'simple-popover' : undefined;
    //
    const [change55, setChange55] = useState(false);
    const [anchorEl55, setAnchorEl55] = useState(null);
    const open55 = Boolean(anchorEl55);
    const id55 = open55 ? 'simple-popover' : undefined;
    //
    const [change255, setChange255] = useState(false);
    const [anchorEl255, setAnchorEl255] = useState(null);
    const open255 = Boolean(anchorEl255);
    const id255 = open255 ? 'simple-popover' : undefined;
    //
    const [change56, setChange56] = useState(false);
    const [anchorEl56, setAnchorEl56] = useState(null);
    const open56 = Boolean(anchorEl56);
    const id56 = open56 ? 'simple-popover' : undefined;
    //
    const [change57, setChange57] = useState(false);
    const [anchorEl57, setAnchorEl57] = useState(null);
    const open57 = Boolean(anchorEl57);
    const id57 = open57 ? 'simple-popover' : undefined;
    //
    const [change58, setChange58] = useState(false);
    const [anchorEl58, setAnchorEl58] = useState(null);
    const open58 = Boolean(anchorEl58);
    const id58 = open58 ? 'simple-popover' : undefined;
    //

    const [change59, setChange59] = useState(false);
    const [anchorEl59, setAnchorEl59] = useState(null);
    const open59 = Boolean(anchorEl59);
    const id59 = open59 ? 'simple-popover' : undefined;
    //
    const [change60, setChange60] = useState(false);
    const [anchorEl60, setAnchorEl60] = useState(null);
    const open60 = Boolean(anchorEl60);
    const id60 = open60 ? 'simple-popover' : undefined;
    //
    const [change61, setChange61] = useState(false);
    const [anchorEl61, setAnchorEl61] = useState(null);
    const open61 = Boolean(anchorEl61);
    const id61 = open61 ? 'simple-popover' : undefined;
    //
    const [change62, setChange62] = useState(false);
    const [anchorEl62, setAnchorEl62] = useState(null);
    const open62 = Boolean(anchorEl62);
    const id62 = open62 ? 'simple-popover' : undefined;
    //
    const [change63, setChange63] = useState(false);
    const [anchorEl63, setAnchorEl63] = useState(null);
    const open63 = Boolean(anchorEl63);
    const id63 = open63 ? 'simple-popover' : undefined;
    //
    const [change64, setChange64] = useState(false);
    const [anchorEl64, setAnchorEl64] = useState(null);
    const open64 = Boolean(anchorEl64);
    const id64 = open64 ? 'simple-popover' : undefined;
    //
    const [change65, setChange65] = useState(false);
    const [anchorEl65, setAnchorEl65] = useState(null);
    const open65 = Boolean(anchorEl65);
    const id65 = open65 ? 'simple-popover' : undefined;
    //
    const [change66, setChange66] = useState(false);
    const [anchorEl66, setAnchorEl66] = useState(null);
    const open66 = Boolean(anchorEl66);
    const id66 = open66 ? 'simple-popover' : undefined;
    //
    const [change67, setChange67] = useState(false);
    const [anchorEl67, setAnchorEl67] = useState(null);
    const open67 = Boolean(anchorEl67);
    const id67 = open67 ? 'simple-popover' : undefined;
    //
    const [change68, setChange68] = useState(false);
    const [anchorEl68, setAnchorEl68] = useState(null);
    const open68 = Boolean(anchorEl68);
    const id68 = open68 ? 'simple-popover' : undefined;
    //
    const [change699, setChange699] = useState(false);
    const [anchorEl699, setAnchorEl699] = useState(null);
    const open699 = Boolean(anchorEl699);
    const id699 = open699 ? 'simple-popover' : undefined;
    //
    const [change69, setChange69] = useState(false);
    const [anchorEl69, setAnchorEl69] = useState(null);
    const open69 = Boolean(anchorEl69);
    const id69 = open69 ? 'simple-popover' : undefined;
    //
    const [change70, setChange70] = useState(false);
    const [anchorEl70, setAnchorEl70] = useState(null);
    const open70 = Boolean(anchorEl70);
    const id70 = open70 ? 'simple-popover' : undefined;
    //
    const [change71, setChange71] = useState(false);
    const [anchorEl71, setAnchorEl71] = useState(null);
    const open71 = Boolean(anchorEl71);
    const id71 = open71 ? 'simple-popover' : undefined;
    //
    const [change72, setChange72] = useState(false);
    const [anchorEl72, setAnchorEl72] = useState(null);
    const open72 = Boolean(anchorEl72);
    const id72 = open72 ? 'simple-popover' : undefined;
    //
    const [change7333, setChange7333] = useState(false);
    const [anchorEl7333, setAnchorEl7333] = useState(null);
    const open7333 = Boolean(anchorEl7333);
    const id7333 = open7333 ? 'simple-popover' : undefined;
    //
    const [change73, setChange73] = useState(false);
    const [anchorEl73, setAnchorEl73] = useState(null);
    const open73 = Boolean(anchorEl73);
    const id73 = open73 ? 'simple-popover' : undefined;
    //
    const [change7444, setChange7444] = useState(false);
    const [anchorEl7444, setAnchorEl7444] = useState(null);
    const open7444 = Boolean(anchorEl7444);
    const id7444 = open7444 ? 'simple-popover' : undefined;
    //
    const [change74, setChange74] = useState(false);
    const [anchorEl74, setAnchorEl74] = useState(null);
    const open74 = Boolean(anchorEl74);
    const id74 = open74 ? 'simple-popover' : undefined;
    //
    const [change75, setChange75] = useState(false);
    const [anchorEl75, setAnchorEl75] = useState(null);
    const open75 = Boolean(anchorEl75);
    const id75 = open75 ? 'simple-popover' : undefined;
    //
    const [change76, setChange76] = useState(false);
    const [anchorEl76, setAnchorEl76] = useState(null);
    const open76 = Boolean(anchorEl76);
    const id76 = open76 ? 'simple-popover' : undefined;
    //
    const [change7777, setChange7777] = useState(false);
    const [anchorEl7777, setAnchorEl7777] = useState(null);
    const open7777 = Boolean(anchorEl7777);
    const id7777 = open7777 ? 'simple-popover' : undefined;
    //
    const [change77, setChange77] = useState(false);
    const [anchorEl77, setAnchorEl77] = useState(null);
    const open77 = Boolean(anchorEl77);
    const id77 = open77 ? 'simple-popover' : undefined;
    //
    const [change78, setChange78] = useState(false);
    const [anchorEl78, setAnchorEl78] = useState(null);
    const open78 = Boolean(anchorEl78);
    const id78 = open78 ? 'simple-popover' : undefined;
    //
    const [change79, setChange79] = useState(false);
    const [anchorEl79, setAnchorEl79] = useState(null);
    const open79 = Boolean(anchorEl79);
    const id79 = open79 ? 'simple-popover' : undefined;
    //
    const [change80, setChange80] = useState(false);
    const [anchorEl80, setAnchorEl80] = useState(null);
    const open80 = Boolean(anchorEl80);
    const id80 = open80 ? 'simple-popover' : undefined;
    //
    const [change81, setChange81] = useState(false);
    const [anchorEl81, setAnchorEl81] = useState(null);
    const open81 = Boolean(anchorEl81);
    const id81 = open81 ? 'simple-popover' : undefined;
    //
    const [change82, setChange82] = useState(false);
    const [anchorEl82, setAnchorEl82] = useState(null);
    const open82 = Boolean(anchorEl82);
    const id82 = open82 ? 'simple-popover' : undefined;
    //
    const [change83, setChange83] = useState(false);
    const [anchorEl83, setAnchorEl83] = useState(null);
    const open83 = Boolean(anchorEl83);
    const id83 = open83 ? 'simple-popover' : undefined;
    //
    const [change84, setChange84] = useState(false);
    const [anchorEl84, setAnchorEl84] = useState(null);
    const open84 = Boolean(anchorEl84);
    const id84 = open84 ? 'simple-popover' : undefined;
    //
    const [change8555, setChange8555] = useState(false);
    const [anchorEl8555, setAnchorEl8555] = useState(null);
    const open8555 = Boolean(anchorEl8555);
    const id8555 = open8555 ? 'simple-popover' : undefined;
    //
    const [change85, setChange85] = useState(false);
    const [anchorEl85, setAnchorEl85] = useState(null);
    const open85 = Boolean(anchorEl85);
    const id85 = open85 ? 'simple-popover' : undefined;
    //
    const [change86, setChange86] = useState(false);
    const [anchorEl86, setAnchorEl86] = useState(null);
    const open86 = Boolean(anchorEl86);
    const id86 = open86 ? 'simple-popover' : undefined;
    //
    const [change87, setChange87] = useState(false);
    const [anchorEl87, setAnchorEl87] = useState(null);
    const open87 = Boolean(anchorEl87);
    const id87 = open87 ? 'simple-popover' : undefined;
    //
    const [change88, setChange88] = useState(false);
    const [anchorEl88, setAnchorEl88] = useState(null);
    const open88 = Boolean(anchorEl88);
    const id88 = open88 ? 'simple-popover' : undefined;
    //
    const [change89, setChange89] = useState(false);
    const [anchorEl89, setAnchorEl89] = useState(null);
    const open89 = Boolean(anchorEl89);
    const id89 = open89 ? 'simple-popover' : undefined;
    //
    const [change90, setChange90] = useState(false);
    const [anchorEl90, setAnchorEl90] = useState(null);
    const open90 = Boolean(anchorEl90);
    const id90 = open90 ? 'simple-popover' : undefined;
    //

    const [change92, setChange92] = useState(false);
    const [anchorEl92, setAnchorEl92] = useState(null);
    const open92 = Boolean(anchorEl92);
    const id92 = open92 ? 'simple-popover' : undefined;
    //
    const [change93, setChange93] = useState(false);
    const [anchorEl93, setAnchorEl93] = useState(null);
    const open93 = Boolean(anchorEl93);
    const id93 = open93 ? 'simple-popover' : undefined;
    //
    const [change94, setChange94] = useState(false);
    const [anchorEl94, setAnchorEl94] = useState(null);
    const open94 = Boolean(anchorEl94);
    const id94 = open94 ? 'simple-popover' : undefined;
    //
    const [change95, setChange95] = useState(false);
    const [anchorEl95, setAnchorEl95] = useState(null);
    const open95 = Boolean(anchorEl95);
    const id95 = open95 ? 'simple-popover' : undefined;
    //
    const [change96, setChange96] = useState(false);
    const [anchorEl96, setAnchorEl96] = useState(null);
    const open96 = Boolean(anchorEl96);
    const id96 = open96 ? 'simple-popover' : undefined;
    //
    const [change97, setChange97] = useState(false);
    const [anchorEl97, setAnchorEl97] = useState(null);
    const open97 = Boolean(anchorEl97);
    const id97 = open97 ? 'simple-popover' : undefined;
    //
    const [change98, setChange98] = useState(false);
    const [anchorEl98, setAnchorEl98] = useState(null);
    const open98 = Boolean(anchorEl98);
    const id98 = open98 ? 'simple-popover' : undefined;
    //
    const [change99, setChange99] = useState(false);
    const [anchorEl99, setAnchorEl99] = useState(null);
    const open99 = Boolean(anchorEl99);
    const id99 = open99 ? 'simple-popover' : undefined;
    //
    const [change100, setChange100] = useState(false);
    const [anchorEl100, setAnchorEl100] = useState(null);
    const open100 = Boolean(anchorEl100);
    const id100 = open100 ? 'simple-popover' : undefined;
    //
    const [change101, setChange101] = useState(false);
    const [anchorEl101, setAnchorEl101] = useState(null);
    const open101 = Boolean(anchorEl101);
    const id101 = open101 ? 'simple-popover' : undefined;
    //
    const [change102, setChange102] = useState(false);
    const [anchorEl102, setAnchorEl102] = useState(null);
    const open102 = Boolean(anchorEl102);
    const id102 = open102 ? 'simple-popover' : undefined;
    //
    const [change103, setChange103] = useState(false);
    const [anchorEl103, setAnchorEl103] = useState(null);
    const open103 = Boolean(anchorEl103);
    const id103 = open103 ? 'simple-popover' : undefined;
    //
    const [change104, setChange104] = useState(false);
    const [anchorEl104, setAnchorEl104] = useState(null);
    const open104 = Boolean(anchorEl104);
    const id104 = open104 ? 'simple-popover' : undefined;
    //
    const [change105, setChange105] = useState(false);
    const [anchorEl105, setAnchorEl105] = useState(null);
    const open105 = Boolean(anchorEl105);
    const id105 = open105 ? 'simple-popover' : undefined;
    //
    const [change106, setChange106] = useState(false);
    const [anchorEl106, setAnchorEl106] = useState(null);
    const open106 = Boolean(anchorEl106);
    const id106 = open106 ? 'simple-popover' : undefined;
    //
    const [change107, setChange107] = useState(false);
    const [anchorEl107, setAnchorEl107] = useState(null);
    const open107 = Boolean(anchorEl107);
    const id107 = open107 ? 'simple-popover' : undefined;
    //
    const [change108, setChange108] = useState(false);
    const [anchorEl108, setAnchorEl108] = useState(null);
    const open108 = Boolean(anchorEl108);
    const id108 = open108 ? 'simple-popover' : undefined;
    //
    const [change109, setChange109] = useState(false);
    const [anchorEl109, setAnchorEl109] = useState(null);
    const open109 = Boolean(anchorEl109);
    const id109 = open109 ? 'simple-popover' : undefined;
    //
    const [change110, setChange110] = useState(false);
    const [anchorEl110, setAnchorEl110] = useState(null);
    const open110 = Boolean(anchorEl110);
    const id110 = open110 ? 'simple-popover' : undefined;
    //
    const [change111, setChange111] = useState(false);
    const [anchorEl111, setAnchorEl111] = useState(null);
    const open111 = Boolean(anchorEl111);
    const id111 = open111 ? 'simple-popover' : undefined;
    //
    const [change112, setChange112] = useState(false);
    const [anchorEl112, setAnchorEl112] = useState(null);
    const open112 = Boolean(anchorEl112);
    const id112 = open112 ? 'simple-popover' : undefined;
    //
    const [change113, setChange113] = useState(false);
    const [anchorEl113, setAnchorEl113] = useState(null);
    const open113 = Boolean(anchorEl113);
    const id113 = open113 ? 'simple-popover' : undefined;
    //

    const [change114, setChange114] = useState(false);
    const [anchorEl114, setAnchorEl114] = useState(null);
    const open114 = Boolean(anchorEl114);
    const id114 = open114 ? 'simple-popover' : undefined;
    //
    const [change115, setChange115] = useState(false);
    const [anchorEl115, setAnchorEl115] = useState(null);
    const open115 = Boolean(anchorEl115);
    const id115 = open115 ? 'simple-popover' : undefined;
    //
    const [change116, setChange116] = useState(false);
    const [anchorEl116, setAnchorEl116] = useState(null);
    const open116 = Boolean(anchorEl116);
    const id116 = open116 ? 'simple-popover' : undefined;
    //
    const [change117, setChange117] = useState(false);
    const [anchorEl117, setAnchorEl117] = useState(null);
    const open117 = Boolean(anchorEl117);
    const id117 = open117 ? 'simple-popover' : undefined;
    //
    const [change118, setChange118] = useState(false);
    const [anchorEl118, setAnchorEl118] = useState(null);
    const open118 = Boolean(anchorEl118);
    const id118 = open118 ? 'simple-popover' : undefined;
    //
    const [change119, setChange119] = useState(false);
    const [anchorEl119, setAnchorEl119] = useState(null);
    const open119 = Boolean(anchorEl119);
    const id119 = open119 ? 'simple-popover' : undefined;
    //
    const [change120, setChange120] = useState(false);
    const [anchorEl120, setAnchorEl120] = useState(null);
    const open120 = Boolean(anchorEl120);
    const id120 = open120 ? 'simple-popover' : undefined;
    //
    const [change121, setChange121] = useState(false);
    const [anchorEl121, setAnchorEl121] = useState(null);
    const open121 = Boolean(anchorEl121);
    const id121 = open121 ? 'simple-popover' : undefined;
    //
    const [change122, setChange122] = useState(false);
    const [anchorEl122, setAnchorEl122] = useState(null);
    const open122 = Boolean(anchorEl122);
    const id122 = open122 ? 'simple-popover' : undefined;
    //
    const [change123, setChange123] = useState(false);
    const [anchorEl123, setAnchorEl123] = useState(null);
    const open123 = Boolean(anchorEl123);
    const id123 = open123 ? 'simple-popover' : undefined;
    //
    const [change124, setChange124] = useState(false);
    const [anchorEl124, setAnchorEl124] = useState(null);
    const open124 = Boolean(anchorEl124);
    const id124 = open124 ? 'simple-popover' : undefined;
    //
    const [change125, setChange125] = useState(false);
    const [anchorEl125, setAnchorEl125] = useState(null);
    const open125 = Boolean(anchorEl125);
    const id125 = open125 ? 'simple-popover' : undefined;
    //
    const [change126, setChange126] = useState(false);
    const [anchorEl126, setAnchorEl126] = useState(null);
    const open126 = Boolean(anchorEl126);
    const id126 = open126 ? 'simple-popover' : undefined;
    //
    const [change127, setChange127] = useState(false);
    const [anchorEl127, setAnchorEl127] = useState(null);
    const open127 = Boolean(anchorEl127);
    const id127 = open127 ? 'simple-popover' : undefined;
    //
    const [change128, setChange128] = useState(false);
    const [anchorEl128, setAnchorEl128] = useState(null);
    const open128 = Boolean(anchorEl128);
    const id128 = open128 ? 'simple-popover' : undefined;
    //
    const [change129, setChange129] = useState(false);
    const [anchorEl129, setAnchorEl129] = useState(null);
    const open129 = Boolean(anchorEl129);
    const id129 = open129 ? 'simple-popover' : undefined;
    //
    const [change130, setChange130] = useState(false);
    const [anchorEl130, setAnchorEl130] = useState(null);
    const open130 = Boolean(anchorEl130);
    const id130 = open130 ? 'simple-popover' : undefined;
    //
    const [change131, setChange131] = useState(false);
    const [anchorEl131, setAnchorEl131] = useState(null);
    const open131 = Boolean(anchorEl131);
    const id131 = open131 ? 'simple-popover' : undefined;
    //
    const [change132, setChange132] = useState(false);
    const [anchorEl132, setAnchorEl132] = useState(null);
    const open132 = Boolean(anchorEl132);
    const id132 = open132 ? 'simple-popover' : undefined;
    //
    const [change133, setChange133] = useState(false);
    const [anchorEl133, setAnchorEl133] = useState(null);
    const open133 = Boolean(anchorEl133);
    const id133 = open133 ? 'simple-popover' : undefined;
    //
    const [change134, setChange134] = useState(false);
    const [anchorEl134, setAnchorEl134] = useState(null);
    const open134 = Boolean(anchorEl134);
    const id134 = open134 ? 'simple-popover' : undefined;
    //
    const [change135, setChange135] = useState(false);
    const [anchorEl135, setAnchorEl135] = useState(null);
    const open135 = Boolean(anchorEl135);
    const id135 = open135 ? 'simple-popover' : undefined;
    //
    const [change136, setChange136] = useState(false);
    const [anchorEl136, setAnchorEl136] = useState(null);
    const open136 = Boolean(anchorEl136);
    const id136 = open136 ? 'simple-popover' : undefined;
    //
    const [change137, setChange137] = useState(false);
    const [anchorEl137, setAnchorEl137] = useState(null);
    const open137 = Boolean(anchorEl137);
    const id137 = open137 ? 'simple-popover' : undefined;
    //
    const [change138, setChange138] = useState(false);
    const [anchorEl138, setAnchorEl138] = useState(null);
    const open138 = Boolean(anchorEl138);
    const id138 = open138 ? 'simple-popover' : undefined;
    //
    const [change139, setChange139] = useState(false);
    const [anchorEl139, setAnchorEl139] = useState(null);
    const open139 = Boolean(anchorEl139);
    const id139 = open139 ? 'simple-popover' : undefined;
    //

    const [change142, setChange142] = useState(false);
    const [anchorEl142, setAnchorEl142] = useState(null);
    const open142 = Boolean(anchorEl142);
    const id142 = open142 ? 'simple-popover' : undefined;
    //
    const [change143, setChange143] = useState(false);
    const [anchorEl143, setAnchorEl143] = useState(null);
    const open143 = Boolean(anchorEl143);
    const id143 = open143 ? 'simple-popover' : undefined;
    //
    const [change144, setChange144] = useState(false);
    const [anchorEl144, setAnchorEl144] = useState(null);
    const open144 = Boolean(anchorEl144);
    const id144 = open144 ? 'simple-popover' : undefined;
    //
    const [change145, setChange145] = useState(false);
    const [anchorEl145, setAnchorEl145] = useState(null);
    const open145 = Boolean(anchorEl145);
    const id145 = open145 ? 'simple-popover' : undefined;
    //
    const [change146, setChange146] = useState(false);
    const [anchorEl146, setAnchorEl146] = useState(null);
    const open146 = Boolean(anchorEl146);
    const id146 = open146 ? 'simple-popover' : undefined;
    //
    const [change147, setChange147] = useState(false);
    const [anchorEl147, setAnchorEl147] = useState(null);
    const open147 = Boolean(anchorEl147);
    const id147 = open147 ? 'simple-popover' : undefined;
    //
    const [change148, setChange148] = useState(false);
    const [anchorEl148, setAnchorEl148] = useState(null);
    const open148 = Boolean(anchorEl148);
    const id148 = open148 ? 'simple-popover' : undefined;
    //
    const [change149, setChange149] = useState(false);
    const [anchorEl149, setAnchorEl149] = useState(null);
    const open149 = Boolean(anchorEl149);
    const id149 = open149 ? 'simple-popover' : undefined;
    //
    const [change150, setChange150] = useState(false);
    const [anchorEl150, setAnchorEl150] = useState(null);
    const open150 = Boolean(anchorEl150);
    const id150 = open150 ? 'simple-popover' : undefined;
    //
    const [change151, setChange151] = useState(false);
    const [anchorEl151, setAnchorEl151] = useState(null);
    const open151 = Boolean(anchorEl151);
    const id151 = open151 ? 'simple-popover' : undefined;
    //
    const [change152, setChange152] = useState(false);
    const [anchorEl152, setAnchorEl152] = useState(null);
    const open152 = Boolean(anchorEl152);
    const id152 = open152 ? 'simple-popover' : undefined;
    //
    const [change153, setChange153] = useState(false);
    const [anchorEl153, setAnchorEl153] = useState(null);
    const open153 = Boolean(anchorEl153);
    const id153 = open153 ? 'simple-popover' : undefined;
    //
    const [change154, setChange154] = useState(false);
    const [anchorEl154, setAnchorEl154] = useState(null);
    const open154 = Boolean(anchorEl154);
    const id154 = open154 ? 'simple-popover' : undefined;
    //
    const [change155, setChange155] = useState(false);
    const [anchorEl155, setAnchorEl155] = useState(null);
    const open155 = Boolean(anchorEl155);
    const id155 = open155 ? 'simple-popover' : undefined;
    //
    const [change156, setChange156] = useState(false);
    const [anchorEl156, setAnchorEl156] = useState(null);
    const open156 = Boolean(anchorEl156);
    const id156 = open156 ? 'simple-popover' : undefined;
    //
    const [change157, setChange157] = useState(false);
    const [anchorEl157, setAnchorEl157] = useState(null);
    const open157 = Boolean(anchorEl157);
    const id157 = open157 ? 'simple-popover' : undefined;
    //
    const [change158, setChange158] = useState(false);
    const [anchorEl158, setAnchorEl158] = useState(null);
    const open158 = Boolean(anchorEl158);
    const id158 = open158 ? 'simple-popover' : undefined;
    //
    const [change159, setChange159] = useState(false);
    const [anchorEl159, setAnchorEl159] = useState(null);
    const open159 = Boolean(anchorEl159);
    const id159 = open159 ? 'simple-popover' : undefined;
    //
    const [change160, setChange160] = useState(false);
    const [anchorEl160, setAnchorEl160] = useState(null);
    const open160 = Boolean(anchorEl160);
    const id160 = open160 ? 'simple-popover' : undefined;
    //
    const [change161, setChange161] = useState(false);
    const [anchorEl161, setAnchorEl161] = useState(null);
    const open161 = Boolean(anchorEl161);
    const id161 = open161 ? 'simple-popover' : undefined;
    //
    const [change162, setChange162] = useState(false);
    const [anchorEl162, setAnchorEl162] = useState(null);
    const open162 = Boolean(anchorEl162);
    const id162 = open162 ? 'simple-popover' : undefined;
    //
    const [change163, setChange163] = useState(false);
    const [anchorEl163, setAnchorEl163] = useState(null);
    const open163 = Boolean(anchorEl163);
    const id163 = open163 ? 'simple-popover' : undefined;
    //
    const [change164, setChange164] = useState(false);
    const [anchorEl164, setAnchorEl164] = useState(null);
    const open164 = Boolean(anchorEl164);
    const id164 = open164 ? 'simple-popover' : undefined;
    //
    const [change165, setChange165] = useState(false);
    const [anchorEl165, setAnchorEl165] = useState(null);
    const open165 = Boolean(anchorEl165);
    const id165 = open165 ? 'simple-popover' : undefined;
    //
    const [change166, setChange166] = useState(false);
    const [anchorEl166, setAnchorEl166] = useState(null);
    const open166 = Boolean(anchorEl166);
    const id166 = open166 ? 'simple-popover' : undefined;
    //
    const [change167, setChange167] = useState(false);
    const [anchorEl167, setAnchorEl167] = useState(null);
    const open167 = Boolean(anchorEl167);
    const id167 = open167 ? 'simple-popover' : undefined;
    //
    const [change168, setChange168] = useState(false);
    const [anchorEl168, setAnchorEl168] = useState(null);
    const open168 = Boolean(anchorEl168);
    const id168 = open168 ? 'simple-popover' : undefined;
    //
    const [change169, setChange169] = useState(false);
    const [anchorEl169, setAnchorEl169] = useState(null);
    const open169 = Boolean(anchorEl169);
    const id169 = open169 ? 'simple-popover' : undefined;
    //
    const [change170, setChange170] = useState(false);
    const [anchorEl170, setAnchorEl170] = useState(null);
    const open170 = Boolean(anchorEl170);
    const id170 = open170 ? 'simple-popover' : undefined;
    //
    const [change171, setChange171] = useState(false);
    const [anchorEl171, setAnchorEl171] = useState(null);
    const open171 = Boolean(anchorEl171);
    const id171 = open171 ? 'simple-popover' : undefined;
    //
    const [change172, setChange172] = useState(false);
    const [anchorEl172, setAnchorEl172] = useState(null);
    const open172 = Boolean(anchorEl172);
    const id172 = open172 ? 'simple-popover' : undefined;
    //
    const [change173, setChange173] = useState(false);
    const [anchorEl173, setAnchorEl173] = useState(null);
    const open173 = Boolean(anchorEl173);
    const id173 = open173 ? 'simple-popover' : undefined;
    //
    const [change174, setChange174] = useState(false);
    const [anchorEl174, setAnchorEl174] = useState(null);
    const open174 = Boolean(anchorEl174);
    const id174 = open174 ? 'simple-popover' : undefined;
    //
    const [change175, setChange175] = useState(false);
    const [anchorEl175, setAnchorEl175] = useState(null);
    const open175 = Boolean(anchorEl175);
    const id175 = open175 ? 'simple-popover' : undefined;
    //


    //
    const [change177, setChange177] = useState(false);
    const [anchorEl177, setAnchorEl177] = useState(null);
    const open177 = Boolean(anchorEl177);
    const id177 = open177 ? 'simple-popover' : undefined;
    //

    //
    const [change178, setChange178] = useState(false);
    const [anchorEl178, setAnchorEl178] = useState(null);
    const open178 = Boolean(anchorEl178);
    const id178 = open178 ? 'simple-popover' : undefined;
    //

    //
    const [change179, setChange179] = useState(false);
    const [anchorEl179, setAnchorEl179] = useState(null);
    const open179 = Boolean(anchorEl179);
    const id179 = open179 ? 'simple-popover' : undefined;
    //

    //
    const [change180, setChange180] = useState(false);
    const [anchorEl180, setAnchorEl180] = useState(null);
    const open180 = Boolean(anchorEl180);
    const id180 = open180 ? 'simple-popover' : undefined;
    //

    //
    const [change181, setChange181] = useState(false);
    const [anchorEl181, setAnchorEl181] = useState(null);
    const open181 = Boolean(anchorEl181);
    const id181 = open181 ? 'simple-popover' : undefined;
    //

    //
    const [change182, setChange182] = useState(false);
    const [anchorEl182, setAnchorEl182] = useState(null);
    const open182 = Boolean(anchorEl182);
    const id182 = open182 ? 'simple-popover' : undefined;
    //





    //
    const [change183, setChange183] = useState(false);
    const [anchorEl183, setAnchorEl183] = useState(null);
    const open183 = Boolean(anchorEl183);
    const id183 = open183 ? 'simple-popover' : undefined;
    //





    //
    const [change185, setChange185] = useState(false);
    const [anchorEl185, setAnchorEl185] = useState(null);
    const open185 = Boolean(anchorEl185);
    const id185 = open185 ? 'simple-popover' : undefined;
    //

    //
    const [change186, setChange186] = useState(false);
    const [anchorEl186, setAnchorEl186] = useState(null);
    const open186 = Boolean(anchorEl186);
    const id186 = open186 ? 'simple-popover' : undefined;
    //

    //
    const [change187, setChange187] = useState(false);
    const [anchorEl187, setAnchorEl187] = useState(null);
    const open187 = Boolean(anchorEl187);
    const id187 = open187 ? 'simple-popover' : undefined;
    //


    //
    const [change188, setChange188] = useState(false);
    const [anchorEl188, setAnchorEl188] = useState(null);
    const open188 = Boolean(anchorEl188);
    const id188 = open188 ? 'simple-popover' : undefined;
    //

    //
    const [change189, setChange189] = useState(false);
    const [anchorEl189, setAnchorEl189] = useState(null);
    const open189 = Boolean(anchorEl189);
    const id189 = open189 ? 'simple-popover' : undefined;
    //
    const [change190, setChange190] = useState(false);
    const [anchorEl190, setAnchorEl190] = useState(null);
    const open190 = Boolean(anchorEl190);
    const id190 = open190 ? 'simple-popover' : undefined;
    //
    const [change191, setChange191] = useState(false);
    const [anchorEl191, setAnchorEl191] = useState(null);
    const open191 = Boolean(anchorEl191);
    const id191 = open191 ? 'simple-popover' : undefined;
    //
    const [change192, setChange192] = useState(false);
    const [anchorEl192, setAnchorEl192] = useState(null);
    const open192 = Boolean(anchorEl192);
    const id192 = open192 ? 'simple-popover' : undefined;
    //
    const [change193, setChange193] = useState(false);
    const [anchorEl193, setAnchorEl193] = useState(null);
    const open193 = Boolean(anchorEl193);
    const id193 = open193 ? 'simple-popover' : undefined;
    //
    const [change194, setChange194] = useState(false);
    const [anchorEl194, setAnchorEl194] = useState(null);
    const open194 = Boolean(anchorEl194);
    const id194 = open194 ? 'simple-popover' : undefined;
    //
    const [change195, setChange195] = useState(false);
    const [anchorEl195, setAnchorEl195] = useState(null);
    const open195 = Boolean(anchorEl195);
    const id195 = open195 ? 'simple-popover' : undefined;
    //
    const [change196, setChange196] = useState(false);
    const [anchorEl196, setAnchorEl196] = useState(null);
    const open196 = Boolean(anchorEl196);
    const id196 = open196 ? 'simple-popover' : undefined;
    //
    const [change197, setChange197] = useState(false);
    const [anchorEl197, setAnchorEl197] = useState(null);
    const open197 = Boolean(anchorEl197);
    const id197 = open197 ? 'simple-popover' : undefined;
    //
    const [change198, setChange198] = useState(false);
    const [anchorEl198, setAnchorEl198] = useState(null);
    const open198 = Boolean(anchorEl198);
    const id198 = open198 ? 'simple-popover' : undefined;
    //
    const [change199, setChange199] = useState(false);
    const [anchorEl199, setAnchorEl199] = useState(null);
    const open199 = Boolean(anchorEl199);
    const id199 = open199 ? 'simple-popover' : undefined;
    //



    const [flexion135, setflexion135] = useState("")
    const [abduction50, setabduction50] = useState("")
    const [InternalRotation45, setInternalRotation45] = useState("")

    const [Extension20, setExtension20] = useState("")
    const [Adduction45, setAdduction45] = useState("")
    const [alRotation45, setalRotation45] = useState("")

    const [compareTo, setCompareTo] = useState("")

    const [abduction50shoulder, setabduction50shoulder] = useState()
    const [abduction170, setabduction170] = useState()
    const [Flexion160shoulder, setFlexion160shoulder] = useState()
    const [Extension50, setExtension50] = useState()
    const [LateralRotation80, setLateralRotation80] = useState()

    // doctor new

    //carvical spine
    const [MedialRotation, setMedialRotation] = useState("");
    const [forwardFlexion45, setForwardFlexion45] = useState("");
    const [backwardExtension45, setBackwardExtension45] = useState("");
    const [lateralBending45, setLateralBending45] = useState("");
    const [lateralBendingLeft45, setLateralBendingLeft45] = useState("");
    const [rotationOver75, setRotationOver75] = useState("");
    const [rotationOverLeft75, setRotationOverLeft75] = useState("");

    // thoraco lumber spine
    const [forwordFlexion80, setForwordFlexion80] = useState("");
    const [lateralFlexionValue, setLateralFlexionValue] = useState("");
    const [extentionValue2030, setExtentionValue2030] = useState("");
    const [shoberTest, setShoberTest] = useState("");

    // knee 
    const [medialMeniscusSide, setMedialMeniscusSide] = useState("");
    const [lateralMeniscusSide, setLateralMeniscusSide] = useState("");
    const [kneeLigamentInjurySide, setKneeLigamentInjurySide] = useState("");
    const [kneeMeniscusInjusySide, setKneeMeniscusInjusySide] = useState("");
    const [kneeMedialRotationLigament, setKneeMedialRotationLigament] = useState("");
    const [kneeMedialRotationMeniscus, setKneeMedialRotationMeniscus] = useState("");
    const [kneeMedialLigamentSide, setKneeMedialLigamentSide] = useState("");
    const [kneeAnteriorCruciateSide, setKneeAnteriorCruciateSide] = useState("");
    const [kneeLateralLigamentSide, setKneeLateralLigamentSide] = useState("");
    const [kneeSagSignSide, setKneeSagSignSide] = useState("");
    const [kneePostCruciateLigamentSide, setKneePostCruciateLigamentSide] = useState("");
    const [kneeWilsonTestSide, setKneeWilsonTestSide] = useState("");
    const [kneePatellaApprehensionSide, setKneePatellaApprehensionSide] = useState("");
    const [kneeNobleCompressionSide, setKneeNobleCompressionSide] = useState("");
    // ankle and foot
    const [feelPalpationMTPJointSide, setFeelPalpationMTPJointSide] = useState("");
    // doctor new
    // sub history part 
    const [subHistoryShowAll, setSubHistoryShowAll] = useState(false)
    const [subHistoryElement, setSubHistoryElement] = useState("carvicle")
    const [carvicalHistory, setCarvicalHistory] = useState([
        { name: "Neck pain", id: 1 },
        { name: "Neck stiffness", id: 2 },
        { name: "Torticollis", id: 3 },
        { name: "Neck swelling", id: 4 },
        { name: "Neck lump", id: 5 },
        { name: "Radiculopathic arm pain", id: 6 },
    ]);
    const [carvicalHistoryValue, setCarvicalHistoryValue] = useState([]);
    const [thoracoHistory, setThoracoHistory] = useState([
        { name: "Thoracic spinal pain", id: 1 },
        { name: "Scapular area pain", id: 2 },
        { name: "Parasitical pain or burning sensation", id: 3 },
        { name: "Lumbar back pain ", id: 4 },
        { name: "Lower back pain", id: 5 },
        { name: "SI joint area pain", id: 6 },
        { name: "Radiculopathic pain ", id: 7 },
    ]);
    const [thoracoHistoryValue, setThoracoHistoryValue] = useState([]);
    const [hipHistory, setHipHistory] = useState([
        { name: "HIp pain", id: 1 },
        { name: "Hip joint stiffness", id: 2 },
        { name: "Buttock claudication", id: 3 },
        { name: "Abnormal gait", id: 4 },
    ]);
    const [hipHistoryValue, setHipHistoryValue] = useState([]);
    const [kneeHistory, setKneeHistory] = useState([
        { name: "Knee pain ", id: 1 },
        { name: "Knee swelling", id: 2 },
        { name: "Anterior knee pain ", id: 3 },
        { name: "Knee stiffness", id: 4 },
        { name: "Unable to kneel down", id: 4 },
        { name: "Knee giving away", id: 4 },
        { name: "Knee pain on going upstairs ", id: 4 },
        { name: "Knee pain on going down stairs", id: 4 },
        { name: "Leg pain", id: 4 },
        { name: "Leg Cramps", id: 4 },
        { name: "Night pain ", id: 4 },
    ]);
    const [kneeHistoryValue, setKneeHistoryValue] = useState([]);
    const [ankleHistory, setAnkleHistory] = useState([
        { name: "Ankle pain ", id: 1 },
        { name: "Ankle swelling ", id: 1 },
        { name: "Limping ", id: 1 },
        { name: "Cannot weight bare  ", id: 1 },
        { name: "Hot swollen at 1st MTP", id: 1 },
        { name: "Heel pain", id: 1 },
        { name: "Achilles tendon pain ", id: 1 },
        { name: "Pain in between toes ", id: 1 },
    ]);
    const [ankleHistoryValue, setAnkleHistoryValue] = useState([]);
    const [shoulderHistory, setShoulderHistory] = useState([
        { name: "Shoulder pain", id: 1 },
        { name: "Shoulder difficult to move", id: 1 },
        { name: "Shoulder stiffness", id: 1 },
        { name: "Shoulder swollen", id: 1 },
        { name: "Shoulder instability/excessive movements ", id: 1 },
    ]);
    const [shoulderHistoryValue, setShoulderHistoryValue] = useState([]);
    const [handHistory, setHandHistory] = useState([
        { name: "Hand pain", id: 1 },
        { name: "Hand swollen", id: 1 },
        { name: "Hand numbness", id: 1 },
        { name: "Wrist pain", id: 1 },
        { name: "Wrist decreased ROM", id: 1 },
        { name: "Finger pain", id: 1 },
        { name: "Finger swollen ", id: 1 },
        { name: "Finger joint pain", id: 1 },
        { name: "Mallet finger", id: 1 },
    ]);
    const [handHistoryValue, setHandHistoryValue] = useState([]);
    const [elbowHistory, setElbowHistory] = useState([
        { name: "Elbow pain", id: 1 },
        { name: "Elbow stiffness", id: 1 },
        { name: "Elbow difficult to move", id: 1 },
        { name: "Elbow decreased ROM", id: 1 },
        { name: "Elbow pain on lifting glass or cup", id: 1 },
    ]);
    const [elbowHistoryVal, setElbowHistoryVal] = useState([]);


    return (
        <div className='row'>
            <div className="history-main-header d-flex justify-content-between mb-2">
                <div>
                    <h6>Musculo Skeletal Examination</h6>
                </div>
                <div>
                    <img src={clinic4} alt="" className="img-fluid" />
                </div>
            </div>
            <div className="col-lg-2 col-3">
                <div className="he-history p-1">
                    <input
                        type="text"
                        placeholder="History"
                        className="form-control form-control-sm mt-1 mb-2"
                        onChange={historySearch}
                    />
                    <ul
                        className={`g-doc-scroll ${historyShowAll ? "full-height" : "half-height"
                            }`}
                    >
                        <li>
                            <div className="">
                                <div className="d-flex justify-content-between">
                                    <p className="w-75">Compare to</p>
                                    <div className="ms-1">
                                        <label className="switch me-1">
                                            <input
                                                name="Jaundiced"
                                                value="Jaundiced"
                                                type="checkbox"
                                                onChange={(e) => { setChange190(!change190); setCompareTo("") }}
                                                id="togBtn"
                                            />
                                            <div onClick={(e) => setAnchorEl190(e.currentTarget)} className="slider round"></div>
                                            {
                                                change190 &&
                                                <Popover
                                                    id={id190}
                                                    open={open190}
                                                    anchorEl={anchorEl190}
                                                    onClose={() => setAnchorEl190(null)}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >

                                                    <div className="left-popup">
                                                        <div className="form-check ms-1">
                                                            <input className="form-check-input" value="Left" type="radio" name="left" onChange={(e) => { setCompareTo(e.target.value) }} id="flexRadioDefault1" />
                                                            <label className="form-check-label" >
                                                                Left
                                                            </label>
                                                        </div>
                                                        <div className="form-check ms-1">
                                                            <input className="form-check-input" value="Right" type="radio" name="left" onChange={(e) => { setCompareTo(e.target.value) }} id="flexRadioDefault1" />
                                                            <label className="form-check-label" >
                                                                Right
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Popover>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="history-popup-value">
                                    <p>{compareTo}</p>
                                </div>
                            </div>
                        </li>
                        {

                            history.length > 0 ?
                                history.map((item, i) => <li key={i}>
                                    <div className="d-flex justify-content-between">
                                        <p className="w-75">{item.history_name}</p>
                                        <div className="ms-1">
                                            <label className="switch me-1">
                                                <input
                                                    name={item.id}
                                                    value={item.history_name}
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        const { checked, value } = e.target;

                                                        if (checked) {
                                                            setHistoryValue([...historyValue, value])
                                                        } else {
                                                            const dataNe = historyValue.filter(item => item !== value)
                                                            setHistoryValue(dataNe)
                                                        }
                                                    }}
                                                    id="togBtn"
                                                />
                                                <div className="slider round"></div>

                                            </label>
                                        </div>
                                    </div>
                                </li>)
                                :
                                <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                        }
                    </ul>
                    {!historyShowAll ? (
                        <span
                            onClick={() => setHistoryShowAll(!historyShowAll)}
                            className="history-see-all"
                        >
                            Show All <i className="ms-1 far fa-angle-down"></i>
                        </span>
                    ) : (
                        <span
                            onClick={() => setHistoryShowAll(!historyShowAll)}
                            className="history-see-all"
                        >
                            Show Less <i className="ms-1 far fa-angle-up"></i>
                        </span>
                    )}
                </div>
                {
                    subHistoryElement === "carvicle" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Cervical Spine History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                carvicalHistory.length > 0 ?
                                    carvicalHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setCarvicalHistoryValue([...carvicalHistoryValue, value])
                                                            } else {
                                                                const dataNe = carvicalHistoryValue.filter(item => item !== value)
                                                                setCarvicalHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "thoraco" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Thoraco-lumber Spine History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                thoracoHistory.length > 0 ?
                                    thoracoHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setThoracoHistoryValue([...thoracoHistoryValue, value])
                                                            } else {
                                                                const dataNe = thoracoHistoryValue.filter(item => item !== value)
                                                                setThoracoHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "hip" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Hip & Pelvis History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                hipHistory.length > 0 ?
                                    hipHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setHipHistoryValue([...hipHistoryValue, value])
                                                            } else {
                                                                const dataNe = hipHistoryValue.filter(item => item !== value)
                                                                setHipHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "knee" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Knee History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                kneeHistory.length > 0 ?
                                    kneeHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setKneeHistoryValue([...kneeHistoryValue, value])
                                                            } else {
                                                                const dataNe = kneeHistoryValue.filter(item => item !== value)
                                                                setKneeHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "ankle" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Ankle & Foot History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                ankleHistory.length > 0 ?
                                    ankleHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setAnkleHistoryValue([...ankleHistoryValue, value])
                                                            } else {
                                                                const dataNe = ankleHistoryValue.filter(item => item !== value)
                                                                setAnkleHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "shoulder" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Shoulder History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                shoulderHistory.length > 0 ?
                                    shoulderHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setShoulderHistoryValue([...shoulderHistoryValue, value])
                                                            } else {
                                                                const dataNe = shoulderHistoryValue.filter(item => item !== value)
                                                                setShoulderHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "hand" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Hand History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                handHistory.length > 0 ?
                                    handHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setHandHistoryValue([...handHistoryValue, value])
                                                            } else {
                                                                const dataNe = handHistoryValue.filter(item => item !== value)
                                                                setHandHistoryValue(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
                {
                    subHistoryElement === "elbow" &&
                    <div className="he-history mt-2 p-1">
                        <h6>Elbow History</h6>
                        <ul
                            className={`g-doc-scroll ${subHistoryShowAll ? "full-height" : "half-height"
                                }`}
                        >

                            {

                                elbowHistory.length > 0 ?
                                    elbowHistory.map((item, i) => <li key={i}>
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">{item.name}</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name={item.id}
                                                        value={item.name}
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const { checked, value } = e.target;

                                                            if (checked) {
                                                                setElbowHistoryVal([...elbowHistoryVal, value])
                                                            } else {
                                                                const dataNe = elbowHistoryVal.filter(item => item !== value)
                                                                setElbowHistoryVal(dataNe)
                                                            }
                                                        }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                    </li>)
                                    :
                                    <i style={{ fontSize: "26px", marginLeft: "40%" }} class="fas fa-spinner fa-spin"></i>
                            }
                        </ul>
                        {!subHistoryShowAll ? (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show All <i className="ms-1 far fa-angle-down"></i>
                            </span>
                        ) : (
                            <span
                                onClick={() => setSubHistoryShowAll(!subHistoryShowAll)}
                                className="history-see-all"
                            >
                                Show Less <i className="ms-1 far fa-angle-up"></i>
                            </span>
                        )}
                    </div>
                }
            </div>
            <div className="col-lg-10 col-9">
                <div className='history-main-header mb-2'>
                    <div className="d-flex">
                        <input type="text"
                            placeholder='Examination Search'
                            className="form-control form-control-sm w-75"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    window.find(e.target.value);
                                }
                            }}
                        />
                        <div className="w-50">
                            <button onClick={() => {
                                setsaveLoding(false)
                                setspiner(true)
                            }} className="vaital-setup-btn-cancel float-end">Reset</button>
                            {
                                saveLoding ?

                                    <>{
                                        spiner ?
                                            <button className="vaital-setup-btn float-end me-2"> <i class="fas fa-spinner fa-spin"></i></button>
                                            :
                                            <button className="vaital-setup-btn float-end me-2"><i className="fas fa-check-circle"></i></button>

                                    }
                                    </>
                                    :
                                    <button className="vaital-setup-btn float-end me-2" onClick={saveMusculoSkeletalData}>Save</button>

                            }

                        </div>
                    </div>
                </div>
                <div className="mus-container">
                    <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button onClick={() => { setSubHistoryElement("carvicle"); setSubHistoryShowAll(false) }} className="nav-link active" id="pills-home-tab-mus" data-bs-toggle="pill" data-bs-target="#pills-home-mus" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Cervical Spine</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => { setSubHistoryElement("thoraco"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-profile-tab-mus" data-bs-toggle="pill" data-bs-target="#pills-profile-mus" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Thoraco-lumber Spine</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => { setSubHistoryElement("hip"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-contact-tab-mus" data-bs-toggle="pill" data-bs-target="#pills-contact-mus" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Hip & Pelvis</button>
                        </li>
                        <li>
                            <button onClick={() => { setSubHistoryElement("knee"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-contact-tab-mus5" data-bs-toggle="pill" data-bs-target="#pills-contact-mus5" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Knee</button>
                        </li>
                        <li>
                            <button onClick={() => { setSubHistoryElement("ankle"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-contact-tab-mus6" data-bs-toggle="pill" data-bs-target="#pills-contact-mus6" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Ankle & Foot</button>
                        </li>
                        <li>
                            <button onClick={() => { setSubHistoryElement("shoulder"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-contact-tab-mus2" data-bs-toggle="pill" data-bs-target="#pills-contact-mus2" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Shoulder</button>
                        </li>
                        <li>
                            <button onClick={() => { setSubHistoryElement("hand"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-contact-tab-mus3" data-bs-toggle="pill" data-bs-target="#pills-contact-mus3" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Hand & Wrist</button>
                        </li>
                        <li>
                            <button onClick={() => { setSubHistoryElement("elbow"); setSubHistoryShowAll(false) }} className="nav-link" id="pills-contact-tab-mus4" data-bs-toggle="pill" data-bs-target="#pills-contact-mus4" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Elbow</button>
                        </li>

                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home-mus" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Posture</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange(!change); setPosture("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change &&
                                                            <Popover
                                                                id={id}
                                                                open={open}
                                                                anchorEl={anchorEl}
                                                                onClose={() => setAnchorEl(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    {
                                                                        posturArray.length > 0 && posturArray.map((item, i) =>
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.Posture_name} type="radio" name="left" onChange={(e) => { setPosture(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.Posture_name}
                                                                                </label>
                                                                            </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{posture}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <h6 className='my-1'>Palpation</h6>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Tender over spinous process at</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange2(!change2); setTender([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl2(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change2 &&
                                                            <Popover
                                                                id={id2}
                                                                open={open2}
                                                                anchorEl={anchorEl2}
                                                                onClose={() => setAnchorEl2(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup" style={{ width: "140px" }}>
                                                                    <div className="row">
                                                                        {
                                                                            tenderOverSpinusArray.length > 0 && tenderOverSpinusArray.map((item, i) => {
                                                                                return (
                                                                                    <div className="col-6">
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input" value={item.TenderOverSpinusProcessAt_name} type="checkbox" name={item.id} onChange={(e) => {
                                                                                                const { checked, value } = e.target;

                                                                                                if (checked) {
                                                                                                    setTender([...tender, value])
                                                                                                } else {
                                                                                                    const dataNe = tender.filter(item => item !== value)
                                                                                                    setTender(dataNe)
                                                                                                }
                                                                                            }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.TenderOverSpinusProcessAt_name}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                {
                                                    Object.keys(tender).map(key =>
                                                        <p >{tender[key]}</p>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Tenderness over facet joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange3(!change3); setTenerness([]); setTenernessSide("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl3(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change3 &&
                                                            <Popover
                                                                id={id3}
                                                                open={open3}
                                                                anchorEl={anchorEl3}
                                                                onClose={() => setAnchorEl3(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup" style={{ width: "200px" }}>
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            {
                                                                                tendernessOverLRBArr.length > 0 && tendernessOverLRBArr.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                type="radio"
                                                                                                value={item.name}
                                                                                                name="right"
                                                                                                onChange={(e) => { setTenernessSide(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }


                                                                        </div>

                                                                        <div className="col-8">
                                                                            <div className="row">
                                                                                {
                                                                                    tenernessOverFacetJoinArray.length > 0 && tenernessOverFacetJoinArray.map((item, i) => {
                                                                                        return (
                                                                                            <div key={i} className="col-6">
                                                                                                <div className="form-check ms-1">
                                                                                                    <input className="form-check-input" value={item.TenernessOverFacetJoin_name} type="checkbox" name={item.id} onChange={(e) => {
                                                                                                        const { checked, value } = e.target;

                                                                                                        if (checked) {
                                                                                                            setTenerness([...tenerness, value])
                                                                                                        } else {
                                                                                                            const dataNe = tenerness.filter(item => item !== value)
                                                                                                            setTenerness(dataNe)
                                                                                                        }

                                                                                                    }} id="flexRadioDefault1" />
                                                                                                    <label className="form-check-label" >
                                                                                                        {item.TenernessOverFacetJoin_name}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{tenernessSide}</p>
                                                    {
                                                        Object.keys(tenerness).map(key =>
                                                            <p className='me-2'>{tenerness[key]}</p>

                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <h6 className="mt-1">
                                        Movement
                                    </h6>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Forward Flexion to (45&#176;)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange191(!change191); setForwardFlexion45("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl191(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change191 &&
                                                            <Popover
                                                                id={id191}
                                                                open={open191}
                                                                anchorEl={anchorEl191}
                                                                onClose={() => setAnchorEl191(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "100px" }} className="left-popup">
                                                                    <input type="text" onChange={(e) => setForwardFlexion45(e.target.value)} className="form-control form-control-sm" />
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{forwardFlexion45}{forwardFlexion45 && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Backward Extension to (45&#176;)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange192(!change192); setBackwardExtension45("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl192(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change192 &&
                                                            <Popover
                                                                id={id192}
                                                                open={open192}
                                                                anchorEl={anchorEl192}
                                                                onClose={() => setAnchorEl192(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "100px" }} className="left-popup">
                                                                    <input type="text" onChange={(e) => setBackwardExtension45(e.target.value)} className="form-control form-control-sm" />
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{backwardExtension45}{backwardExtension45 && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Lateral bending- right to (45&#176;)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange193(!change193); setLateralBending45("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl193(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change193 &&
                                                            <Popover
                                                                id={id193}
                                                                open={open193}
                                                                anchorEl={anchorEl193}
                                                                onClose={() => setAnchorEl193(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "100px" }} className="left-popup">
                                                                    <input type="text" onChange={(e) => setLateralBending45(e.target.value)} className="form-control form-control-sm" />
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{lateralBending45}{lateralBending45 && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Lateral bending- left to (45&#176;)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange194(!change194); setLateralBendingLeft45("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl194(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change194 &&
                                                            <Popover
                                                                id={id194}
                                                                open={open194}
                                                                anchorEl={anchorEl194}
                                                                onClose={() => setAnchorEl194(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "100px" }} className="left-popup">
                                                                    <input type="text" onChange={(e) => setLateralBendingLeft45(e.target.value)} className="form-control form-control-sm" />
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{lateralBendingLeft45}{lateralBendingLeft45 && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Rotation over shoulder- right to (75&#176;)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange195(!change195); setRotationOver75("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl195(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change195 &&
                                                            <Popover
                                                                id={id195}
                                                                open={open195}
                                                                anchorEl={anchorEl195}
                                                                onClose={() => setAnchorEl195(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >

                                                                <div style={{ width: "100px" }} className="left-popup">
                                                                    <input type="text" onChange={(e) => setRotationOver75(e.target.value)} className="form-control form-control-sm" />
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{rotationOver75}{rotationOver75 && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Rotation over shoulder- left to (75&#176;)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange196(!change196); setRotationOverLeft75("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl196(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change196 &&
                                                            <Popover
                                                                id={id196}
                                                                open={open196}
                                                                anchorEl={anchorEl196}
                                                                onClose={() => setAnchorEl196(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >

                                                                <div style={{ width: "100px" }} className="left-popup">
                                                                    <input type="text" onChange={(e) => setRotationOverLeft75(e.target.value)} className="form-control form-control-sm" />
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{rotationOverLeft75}{rotationOverLeft75 && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile-mus" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Inspection (Look for deformity)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange4(!change4); setInspection("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl4(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change4 &&
                                                        <Popover
                                                            id={id4}
                                                            open={open4}
                                                            anchorEl={anchorEl4}
                                                            onClose={() => setAnchorEl4(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    inspectionArray.length > 0 && inspectionArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.Inspection_name} type="radio" name="right" onChange={(e) => { setInspection(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.Inspection_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{inspection}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Lumbar Lordosis</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange5(!change5); setLumberLordosis("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl5(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change5 &&
                                                        <Popover
                                                            id={id5}
                                                            open={open5}
                                                            anchorEl={anchorEl5}
                                                            onClose={() => setAnchorEl5(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    lumberLordosisArray.length > 0 && lumberLordosisArray.map((item, i) =>
                                                                        <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.LumberLordosis_name} type="radio" name="right" onChange={(e) => { setLumberLordosis(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.LumberLordosis_name}
                                                                            </label>
                                                                        </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{lumberLordosis}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Pain into knee</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange6(!change6); setPainIntoKnee("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl6(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change6 &&
                                                        <Popover
                                                            id={id6}
                                                            open={open6}
                                                            anchorEl={anchorEl6}
                                                            onClose={() => setAnchorEl6(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    painIntoKneeArray.length > 0 && painIntoKneeArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.PainIntoKnee_name} type="radio" name="right" onChange={(e) => { setPainIntoKnee(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.PainIntoKnee_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{painIntoKnee}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Saddle Anaesthesia</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange7(!change7); setSuddenAnaesthesia("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl7(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change7 &&
                                                        <Popover
                                                            id={id7}
                                                            open={open7}
                                                            anchorEl={anchorEl7}
                                                            onClose={() => setAnchorEl7(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    suddenAnaesthesiaArray.length > 0 && suddenAnaesthesiaArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.SuddenAnaesthesia_name} type="radio" name="right" onChange={(e) => { setSuddenAnaesthesia(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.SuddenAnaesthesia_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{suddenAnaesthesia}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Spine</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange10(!change10); setSpine("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl10(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change10 &&
                                                        <Popover
                                                            id={id10}
                                                            open={open10}
                                                            anchorEl={anchorEl10}
                                                            onClose={() => setAnchorEl10(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    spineArray.length > 0 && spineArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.Spine_name} type="radio" name="right" onChange={(e) => { setSpine(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.Spine_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{spine}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Slump Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange8(!change8); setSlumTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl8(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change8 &&
                                                        <Popover
                                                            id={id8}
                                                            open={open8}
                                                            anchorEl={anchorEl8}
                                                            onClose={() => setAnchorEl8(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setSlumTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setSlumTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{slumTest}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Thoracic Kyphosis</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange9(!change9); setThoracicKyphosis("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl9(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change9 &&
                                                        <Popover
                                                            id={id9}
                                                            open={open9}
                                                            anchorEl={anchorEl9}
                                                            onClose={() => setAnchorEl9(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    thoracicKyphosisArray.length > 0 && thoracicKyphosisArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.ThoracicKyphosis_name} type="radio" name="right" onChange={(e) => { setThoracicKyphosis(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.ThoracicKyphosis_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{thoracicKyphosis}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Pain along anterior and lateral aspect of thigh</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange11(!change11); setPainAlong("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl11(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change11 &&
                                                        <Popover
                                                            id={id11}
                                                            open={open11}
                                                            anchorEl={anchorEl11}
                                                            onClose={() => setAnchorEl11(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    painAlongArray.length > 0 && painAlongArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.name} type="radio" name="right" onChange={(e) => { setPainAlong(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{painAlong}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Pain over posterior or lateral aspect of greater trochanter</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange12(!change12); setPainOver("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl12(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change12 &&
                                                        <Popover
                                                            id={id12}
                                                            open={open12}
                                                            anchorEl={anchorEl12}
                                                            onClose={() => setAnchorEl12(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    painOverArray.length > 0 && painOverArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.name} type="radio" name="right" onChange={(e) => { setPainOver(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{painOver}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <h6 className="my-1">  Movement</h6>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Walk on heels (L5) and Toes (S1)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange13(!change13); setWalkOn("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl13(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change13 &&
                                                        <Popover
                                                            id={id13}
                                                            open={open13}
                                                            anchorEl={anchorEl13}
                                                            onClose={() => setAnchorEl13(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    walkOnArray.length > 0 && walkOnArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.name} type="radio" name="right" onChange={(e) => { setWalkOn(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{walkOn}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Forward Flexion to 80&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setForward(!forward); setForwordFlexion80("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => { setAnchorEl197(e.currentTarget); setChange197(!change197) }} className="slider round"></div>
                                                    {
                                                        change197 &&
                                                        <Popover
                                                            id={id197}
                                                            open={open197}
                                                            anchorEl={anchorEl197}
                                                            onClose={() => setAnchorEl197(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <input type="text" onChange={(e) => setForwordFlexion80(e.target.value)} className="form-control form-control-sm" />
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">
                                            <p>{forwordFlexion80} {forwordFlexion80 && <>&#176;</>}</p>

                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Lateral Flexion to 30&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange14(!change14); setLateralFlexion("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl14(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change14 &&
                                                        <Popover
                                                            id={id14}
                                                            open={open14}
                                                            anchorEl={anchorEl14}
                                                            onClose={() => setAnchorEl14(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="right" onChange={(e) => { setLateralFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="right" onChange={(e) => { setLateralFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <input type="text" onChange={(e) => { setLateralFlexionValue(e.target.value) }} className="form-control form-control-sm" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{lateralFlexion} {lateralFlexion && <>: {lateralFlexionValue} </>} {lateralFlexionValue && <>&#176;</>}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Extension -20&#176; - 30&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setExtension(!extension); setExtentionValue2030("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => { setChange198(!change198); setAnchorEl198(e.currentTarget) }} className="slider round"></div>
                                                    {
                                                        change198 &&
                                                        <Popover
                                                            id={id198}
                                                            open={open198}
                                                            anchorEl={anchorEl198}
                                                            onClose={() => setAnchorEl198(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <input type="text" onChange={(e) => { setExtentionValue2030(e.target.value) }} className="form-control form-control-sm" />

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{extentionValue2030}{extentionValue2030 && <>&#176;</>} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Palpation</h6>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Tender over spinous  process at</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange15(!change15); setTenderOver([]); setTenderOverL1ToL6([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl15(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change15 &&
                                                        <Popover
                                                            id={id15}
                                                            open={open15}
                                                            anchorEl={anchorEl15}
                                                            onClose={() => setAnchorEl15(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div style={{ width: "240px" }} className="left-popup">

                                                                <div className="border m-1 row">
                                                                    <div className="col-6">
                                                                        {
                                                                            tenderOverSpinusThoracoArray.length > 0 && tenderOverSpinusThoracoArray.map((item, i) =>
                                                                                <div key={i} className="">
                                                                                    <div className="form-check ms-1">
                                                                                        <input className="form-check-input" value={item.name} type="checkbox" name={item.id} onChange={(e) => {

                                                                                            const { checked, value } = e.target;

                                                                                            if (checked) {
                                                                                                setTenderOver([...tenderOver, value])
                                                                                            } else {
                                                                                                const dataNe = tenderOver.filter(item => item !== value)
                                                                                                setTenderOver(dataNe)
                                                                                            }
                                                                                        }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className=" m-1">
                                                                            {
                                                                                tenderTendernessL1ToL6ThoracoArray.length > 0 && tenderTendernessL1ToL6ThoracoArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.id} onChange={(e) => {
                                                                                        const { checked, value } = e.target;
                                                                                        if (checked) {
                                                                                            setTenderOver([...tenderOver, value])
                                                                                        } else {
                                                                                            const dataNe = tenderOver.filter(item => item !== value)
                                                                                            setTenderOver(dataNe)
                                                                                        }
                                                                                        // if (checked) {
                                                                                        //     setTenderOverL1ToL6([...tenderOverL1ToL6, value])
                                                                                        // } else {
                                                                                        //     const dataNe = tenderOverL1ToL6.filter(item => item !== value)
                                                                                        //     setTenderOverL1ToL6(dataNe)
                                                                                        // }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                {
                                                    Object.keys(tenderOver).map(key => <p className="me-2">{tenderOver[key]}</p>)


                                                }
                                                {
                                                    Object.keys(tenderOverL1ToL6).map(key => <p className="me-2">{tenderOverL1ToL6[key]}</p>)

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Tenderness over facet joint</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange16(!change16); setTendernessOver([]); setTendernessOverSide(""); setTendernessOverL1ToL6({}) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl16(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change16 &&
                                                        <Popover
                                                            id={id16}
                                                            open={open16}
                                                            anchorEl={anchorEl16}
                                                            onClose={() => setAnchorEl16(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div style={{ width: "250px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <div className="border m-1">

                                                                            {
                                                                                tendernessOverLRBArr.length > 0 && tendernessOverLRBArr.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                type="radio"
                                                                                                value={item.name}
                                                                                                name="right"
                                                                                                onChange={(e) => { setTendernessOverSide(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="border m-1">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    {
                                                                                        tendernessOverFacetThoracoArray.length > 0 && tendernessOverFacetThoracoArray.map((item, i) =>
                                                                                            <div key={i} className="">
                                                                                                <div key={i} className="form-check ms-1">
                                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.id} onChange={(e) => {
                                                                                                        const { checked, value } = e.target;

                                                                                                        if (checked) {
                                                                                                            setTendernessOver([...tendernessOver, value])
                                                                                                        } else {
                                                                                                            const dataNe = tendernessOver.filter(item => item !== value)
                                                                                                            setTendernessOver(dataNe)
                                                                                                        }
                                                                                                    }} id="flexRadioDefault1" />
                                                                                                    <label className="form-check-label" >
                                                                                                        {item.name}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    {
                                                                                        tenderTendernessL1ToL6ThoracoArray2.length > 0 && tenderTendernessL1ToL6ThoracoArray2.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input" value={item.name} type="checkbox" name={item.id} onChange={(e) => {
                                                                                                const { checked, value } = e.target;

                                                                                                if (checked) {
                                                                                                    setTendernessOver([...tendernessOver, value])
                                                                                                } else {
                                                                                                    const dataNe = tendernessOver.filter(item => item !== value)
                                                                                                    setTendernessOver(dataNe)
                                                                                                }
                                                                                                // setTendernessOverL1ToL6(tendernessOverL1ToL6[e.target.name] ? { ...tendernessOverL1ToL6, [e.target.name]: "" } : { ...tendernessOverL1ToL6, [e.target.name]: e.target.value })
                                                                                            }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>)
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{tendernessOverSide}</p>
                                                {
                                                    Object.keys(tendernessOver).map(key => <p className="me-2">{tendernessOver[key]}</p>)

                                                }

                                                {
                                                    Object.keys(tendernessOverL1ToL6).map(key => <p className="me-2">{tendernessOverL1ToL6[key]}</p>)

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Sl joint line tenderness</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange17(!change17); setSlJoint("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl17(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change17 &&
                                                        <Popover
                                                            id={id17}
                                                            open={open17}
                                                            anchorEl={anchorEl17}
                                                            onClose={() => setAnchorEl17(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="right" onChange={(e) => { setSlJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="right" onChange={(e) => { setSlJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{slJoint}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Hip joint line tenderness</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange18(!change18); setHipJoint("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl18(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change18 &&
                                                        <Popover
                                                            id={id18}
                                                            open={open18}
                                                            anchorEl={anchorEl18}
                                                            onClose={() => setAnchorEl18(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="right" onChange={(e) => { setHipJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="right" onChange={(e) => { setHipJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{hipJoint}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Sitting</h6>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Valsalva’s Maneuver (Increased intrathecal Pressure causing leg pain)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange19(!change19); setValsalva("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl19(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change19 &&
                                                        <Popover
                                                            id={id19}
                                                            open={open19}
                                                            anchorEl={anchorEl19}
                                                            onClose={() => setAnchorEl19(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setValsalva(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setValsalva(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{valsalva}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <h6 className="my-1"> Lying on couch</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Straight Leg Raising test Normal 80&#176; - 90&#176; <br /> <i className="far fa-angle-left"></i> 60&#176; Herniated disc L4/5 or L5/S1</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange20(!change20); setStraight("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl20(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change20 &&
                                                        <Popover
                                                            id={id20}
                                                            open={open20}
                                                            anchorEl={anchorEl20}
                                                            onClose={() => setAnchorEl20(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">

                                                                {
                                                                    straightLegRaising.length > 0 && straightLegRaising.map((item, i) => {
                                                                        return (
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    type="radio"
                                                                                    value={item.name}
                                                                                    name="right"
                                                                                    onChange={(e) => { setStraight(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{straight}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Femoral Stretch test Herniated disc L2 to L4</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange21(!change21); setFemoral("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl21(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change21 &&
                                                        <Popover
                                                            id={id21}
                                                            open={open21}
                                                            anchorEl={anchorEl21}
                                                            onClose={() => setAnchorEl21(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setFemoral(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setFemoral(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{femoral}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Hoover test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange22(!change22); setHooverTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl22(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change22 &&
                                                        <Popover
                                                            id={id22}
                                                            open={open22}
                                                            anchorEl={anchorEl22}
                                                            onClose={() => setAnchorEl22(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}

                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setHooverTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setHooverTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{hooverTest}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Gaenslen’s sign</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange23(!change23); setGaenslen("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl23(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change23 &&
                                                        <Popover
                                                            id={id23}
                                                            open={open23}
                                                            anchorEl={anchorEl23}
                                                            onClose={() => setAnchorEl23(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setGaenslen(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setGaenslen(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{gaenslen}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Patrick’s (Fabere) test or Bucket Handle test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange24(!change24); setBucket("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl24(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change24 &&
                                                        <Popover
                                                            id={id24}
                                                            open={open24}
                                                            anchorEl={anchorEl24}
                                                            onClose={() => setAnchorEl24(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            className="mt-2"
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setBucket(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setBucket(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <div className="d-flex">
                                                <p className="me-2">{bucket}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">

                                <div className="row mt-2">
                                    <div className="col-4">
                                        <h6 className="my-1"> Test</h6>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Shober’s test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange25(!change25); setShober(""); setShoberTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl25(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change25 &&
                                                            <Popover
                                                                id={id25}
                                                                open={open25}
                                                                anchorEl={anchorEl25}
                                                                onClose={() => setAnchorEl25(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                className="mt-2"
                                                            >

                                                                <div style={{ width: "250px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <span>Shober’s test</span>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <input type="text" onChange={(e) => setShober(e.target.value)} className="form-control form-control-sm d-inline w-75" />
                                                                            <span>cm</span>
                                                                        </div>
                                                                        <div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setShoberTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Positive
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setShoberTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Negative
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <span className='mt-2'> distance between lines increased
                                                                            <i className="far fa-angle-left"></i> 5cm = positive test and <i className="far fa-angle-right"></i>5cm negative test
                                                                            Positive test = inflammatory, disc or sever spondylosis</span>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2"> {shoberTest} {shober} {shober && "cm"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Quadrant test (facet joint arthropathy)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange26(!change26); setQuadrant("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl26(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change26 &&
                                                            <Popover
                                                                id={id26}
                                                                open={open26}
                                                                anchorEl={anchorEl26}
                                                                onClose={() => setAnchorEl26(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                className="mt-2"
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setQuadrant(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setQuadrant(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{quadrant}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Trendelenburg Test. Abductor weakness on stance side (Sag side safe)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange27(!change27); setTrendelenburg("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl27(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change27 &&
                                                            <Popover
                                                                id={id27}
                                                                open={open27}
                                                                anchorEl={anchorEl27}
                                                                onClose={() => setAnchorEl27(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                className="mt-2"
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setTrendelenburg(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setTrendelenburg(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{trendelenburg}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Spondylosis Test. Extension in one leg standing (stress fracture of pars interartucularis, Facet joint pathology)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange28(!change28); setSpondylosis("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl28(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change28 &&
                                                            <Popover
                                                                id={id28}
                                                                open={open28}
                                                                anchorEl={anchorEl28}
                                                                onClose={() => setAnchorEl28(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                className="mt-2"
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="right" onChange={(e) => { setSpondylosis(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="right" onChange={(e) => { setSpondylosis(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{spondylosis}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-6">
                                                <h6 className="my-1"> Power</h6>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L1 and L2. Hip Flexion</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange29(!change29); setHipFlexion("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl29(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change29 &&
                                                                    <Popover
                                                                        id={id29}
                                                                        open={open29}
                                                                        anchorEl={anchorEl29}
                                                                        onClose={() => setAnchorEl29(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">

                                                                            {
                                                                                l1L2HipFlexionArray.length > 0 && l1L2HipFlexionArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                type="radio"
                                                                                                value={item.name}
                                                                                                name="right"
                                                                                                onChange={(e) => { setHipFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{hipFlexion}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L3- Resisted knee extension Power on lower leg Quadriceps muscle</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange30(!change30); setResisted("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl30(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change30 &&
                                                                    <Popover
                                                                        id={id30}
                                                                        open={open30}
                                                                        anchorEl={anchorEl30}
                                                                        onClose={() => setAnchorEl30(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l3resistedPowerArray.length > 0 && l3resistedPowerArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                type="radio"
                                                                                                value={item.name}
                                                                                                name="right"
                                                                                                onChange={(e) => { setResisted(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{resisted}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L4- Dorsiflexion of fool and ankle Tibialia anterior muscle action</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange31(!change31); setDorsiflexion("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl31(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change31 &&
                                                                    <Popover
                                                                        id={id31}
                                                                        open={open31}
                                                                        anchorEl={anchorEl31}
                                                                        onClose={() => setAnchorEl31(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l4DorsiflexionPowerArray.length > 0 && l4DorsiflexionPowerArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                type="radio"
                                                                                                value={item.name}
                                                                                                name="right"
                                                                                                onChange={(e) => { setDorsiflexion(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{dorsiflexion}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L5- Extend great toe distal Phalanx Extensor hallucis longus extension</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange32(!change32); setExtend("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl32(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change32 &&
                                                                    <Popover
                                                                        id={id32}
                                                                        open={open32}
                                                                        anchorEl={anchorEl32}
                                                                        onClose={() => setAnchorEl32(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l5extendPowerArray.length > 0 && l5extendPowerArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                value={item.name}
                                                                                                type="radio"
                                                                                                name="right"
                                                                                                onChange={(e) => { setExtend(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{extend}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <h6 className="my-1">Sensation</h6>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L1 and L2</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange33(!change33); setL1("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl33(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change33 &&
                                                                    <Popover
                                                                        id={id33}
                                                                        open={open33}
                                                                        anchorEl={anchorEl33}
                                                                        onClose={() => setAnchorEl33(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}

                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l1AndL2SensationArray.length > 0 && l1AndL2SensationArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                value={item.name}
                                                                                                type="radio"
                                                                                                name="right"
                                                                                                onChange={(e) => { setL1(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{l1}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L3 area</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange34(!change34); setL3Area("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl34(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change34 &&
                                                                    <Popover
                                                                        id={id34}
                                                                        open={open34}
                                                                        anchorEl={anchorEl34}
                                                                        onClose={() => setAnchorEl34(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}

                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l3AreaSensationArray.length > 0 && l3AreaSensationArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                value={item.name}
                                                                                                type="radio"
                                                                                                name="right"
                                                                                                onChange={(e) => { setL3Area(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{l3Area}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L4 area</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange35(!change35); setL4Area("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl35(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change35 &&
                                                                    <Popover
                                                                        id={id35}
                                                                        open={open35}
                                                                        anchorEl={anchorEl35}
                                                                        onClose={() => setAnchorEl35(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}

                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l4AreaSensationArray.length > 0 && l4AreaSensationArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                value={item.name}
                                                                                                type="radio"
                                                                                                name="right"
                                                                                                onChange={(e) => { setL4Area(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{l4Area}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">L5 area</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange36(!change36); setL5Area("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl36(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change36 &&
                                                                    <Popover
                                                                        id={id36}
                                                                        open={open36}
                                                                        anchorEl={anchorEl36}
                                                                        onClose={() => setAnchorEl36(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}

                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                l5AreaSensationArray.length > 0 && l5AreaSensationArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                value={item.name}
                                                                                                type="radio"
                                                                                                name="right"
                                                                                                onChange={(e) => { setL5Area(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{l5Area}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="exam-bg-white p-1 mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="w-75">S1 Area</p>
                                                        <div className="ms-1">
                                                            <label className="switch me-1">
                                                                <input
                                                                    name="Jaundiced"
                                                                    value="Jaundiced"
                                                                    type="checkbox"
                                                                    onChange={(e) => { setChange38(!change38); setS1Area("") }}
                                                                    id="togBtn"
                                                                />
                                                                <div onClick={(e) => setAnchorEl38(e.currentTarget)} className="slider round"></div>
                                                                {
                                                                    change38 &&
                                                                    <Popover
                                                                        id={id38}
                                                                        open={open38}
                                                                        anchorEl={anchorEl38}
                                                                        onClose={() => setAnchorEl38(null)}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}

                                                                        className="mt-2"
                                                                    >

                                                                        <div className="left-popup">
                                                                            {
                                                                                s1AreaArray.length > 0 && s1AreaArray.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="form-check ms-1">
                                                                                            <input className="form-check-input"
                                                                                                value={item.name}
                                                                                                type="radio"
                                                                                                name="right"
                                                                                                onChange={(e) => { setS1Area(e.target.value) }} id="flexRadioDefault1" />
                                                                                            <label className="form-check-label" >
                                                                                                {item.name}
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="history-popup-value">
                                                        <div className="d-flex">
                                                            <p className="me-2">{s1Area}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">S1- Plantar flexion of foot Gastrocnemius and soleus muscle</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange37(!change37); setPlanterFlexion("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl37(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change37 &&
                                                            <Popover
                                                                id={id37}
                                                                open={open37}
                                                                anchorEl={anchorEl37}
                                                                onClose={() => setAnchorEl37(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}

                                                                className="mt-2"
                                                            >

                                                                <div className="left-popup">
                                                                    {
                                                                        s1planterFlexionArray.length > 0 && s1planterFlexionArray.map((item, i) => {
                                                                            return (
                                                                                <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input"
                                                                                        value={item.name}
                                                                                        type="radio"
                                                                                        name="right"
                                                                                        onChange={(e) => { setPlanterFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{planterFlexion}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">S2-S4 Supply of urinary bladder and intrinsic muscle of foot</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange39(!change39); setS4Supply("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl39(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change39 &&
                                                            <Popover
                                                                id={id39}
                                                                open={open39}
                                                                anchorEl={anchorEl39}
                                                                onClose={() => setAnchorEl39(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}

                                                                className="mt-2"
                                                            >

                                                                <div className="left-popup">
                                                                    {
                                                                        s4SupplyArray.length > 0 && s4SupplyArray.map((item, i) => {
                                                                            return (
                                                                                <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input"
                                                                                        value={item.name}
                                                                                        type="radio"
                                                                                        name="right"
                                                                                        onChange={(e) => { setS4Supply(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{s4Supply}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="pills-contact-mus" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Hip & Pelvis</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange40(!change40); setHip("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl40(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change40 &&
                                                        <Popover
                                                            id={id40}
                                                            open={open40}
                                                            anchorEl={anchorEl40}
                                                            onClose={() => setAnchorEl40(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="left" onChange={(e) => { setHip(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="left" onChange={(e) => { setHip(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{hip}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Inspection (Look)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange44(!change44); setInspectionLook("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl44(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change44 &&
                                                        <Popover
                                                            id={id44}
                                                            open={open44}
                                                            anchorEl={anchorEl44}
                                                            onClose={() => setAnchorEl44(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    inspectionLookHipPelvisArray.length > 0 && inspectionLookHipPelvisArray.map((item, i) => {
                                                                        return (
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio"
                                                                                    name="test1"
                                                                                    onChange={(e) => setInspectionLook(e.target.value)} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{inspectionLook}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Gait - Limping</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange47(!change47); setOberTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl47(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change47 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id47}
                                                            open={open47}
                                                            anchorEl={anchorEl47}
                                                            onClose={() => setAnchorEl47(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    gaitArray.length > 0 &&
                                                                    gaitArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.name} type="radio" name="test1" onChange={(e) => setGait(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{gait}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-2">Palpation (Feel)</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Hip Joint tenderness</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange51(!change51); setHipJointtenderness("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl51(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change51 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id51}
                                                            open={open51}
                                                            anchorEl={anchorEl51}
                                                            onClose={() => setAnchorEl51(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Present" type="radio" name="test1" onChange={(e) => setHipJointtenderness(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Present
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Absent" type="radio" name="test1" onChange={(e) => setHipJointtenderness(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Absent
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{hipJointtenderness}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Greater trochanter - Higher on... abnormal</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange52(!change52); setGreaterTrochanter("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl52(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change52 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id52}
                                                            open={open52}
                                                            anchorEl={anchorEl52}
                                                            onClose={() => setAnchorEl52(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setGreaterTrochanter(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setGreaterTrochanter(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{greaterTrochanter}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Gluteus Medius tendonitis</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange53(!change53); setGluteusMedius("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl53(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change53 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id53}
                                                            open={open53}
                                                            anchorEl={anchorEl53}
                                                            onClose={() => setAnchorEl53(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Present" type="radio" name="test1" onChange={(e) => setGluteusMedius(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Present
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Absent" type="radio" name="test1" onChange={(e) => setGluteusMedius(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Absent
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{gluteusMedius}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Trochanteric Bursitis</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange54(!change54); setTrochantericBursi("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl54(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change54 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id54}
                                                            open={open54}
                                                            anchorEl={anchorEl54}
                                                            onClose={() => setAnchorEl54(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Present" type="radio" name="test1" onChange={(e) => setTrochantericBursi(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Present
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Absent" type="radio" name="test1" onChange={(e) => setTrochantericBursi(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Absent
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{trochantericBursi}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="mt-2">Move</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Flexion 135&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange177(!change177); setflexion135() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl177(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change177 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id177}
                                                            open={open177}
                                                            anchorEl={anchorEl177}
                                                            onClose={() => setAnchorEl177(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setflexion135(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <strong>&#176;</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">
                                            <p className='me-2'>{flexion135 && <>Flexion : {flexion135}&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Abduction 50&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange178(!change178); setabduction50() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl178(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change178 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id178}
                                                            open={open178}
                                                            anchorEl={anchorEl178}
                                                            onClose={() => setAnchorEl178(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setabduction50(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <strong>&#176;</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{abduction50 && <>Abduction : {abduction50}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Internal Rotation 45&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange179(!change179); setabduction50() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl179(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change179 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id179}
                                                            open={open179}
                                                            anchorEl={anchorEl179}
                                                            onClose={() => setAnchorEl179(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setInternalRotation45(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <strong>&#176;</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{InternalRotation45 && <>Internal Rotation : {InternalRotation45}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Extension 20&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange180(!change180); setExtension20() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl180(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change180 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id180}
                                                            open={open180}
                                                            anchorEl={anchorEl180}
                                                            onClose={() => setAnchorEl180(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setExtension20(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{Extension20 && <> Extension : {Extension20}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Adduction 45&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange181(!change181); setAdduction45() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl181(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change181 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id181}
                                                            open={open181}
                                                            anchorEl={anchorEl181}
                                                            onClose={() => setAnchorEl181(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setAdduction45(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{Adduction45 && <>Adduction: {Adduction45}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Al Rotation 45&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange182(!change182); setalRotation45() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl182(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change182 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id182}
                                                            open={open182}
                                                            anchorEl={anchorEl182}
                                                            onClose={() => setAnchorEl182(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'Right',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setalRotation45(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{alRotation45 && <>Al Rotation: {alRotation45}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>


                                <p className='mt-2'>Internal rotation abduction and extension restricted in Osteoarthritis hip. In children Measure rotation, abduction and adduction with hip and knee flex for perthes and Slipped upper femoral epiphysis</p>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">True leg length</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange55(!change55); setlegLength({ left: "", right: "" }) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl55(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change55 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id55}
                                                            open={open55}
                                                            anchorEl={anchorEl55}
                                                            onClose={() => setAnchorEl55(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row mb-2">
                                                                    <label className="col-sm-2 col-form-label col-form-label-sm">Left</label>
                                                                    <div className="col-sm-6">
                                                                        <input type="text" name="left" onChange={(e) => setlegLength({ ...legLength, [e.target.name]: e.target.value })} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>mm</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-2">
                                                                    <label className="col-sm-2 col-form-label col-form-label-sm">Right</label>
                                                                    <div className="col-sm-6">
                                                                        <input type="text" name="right" onChange={(e) => setlegLength({ ...legLength, [e.target.name]: e.target.value })} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>mm</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{`${legLength.left && "Left : "}`}{legLength.left}{`${legLength.left && " mm"}`}</p>
                                            <p>{`${legLength.right && "Right : "}`}{legLength.right}{`${legLength.right && " mm"}`}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Apparent leg length Right</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange255(!change255); setApparentLegLength({ left: "", right: "" }) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl255(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change55 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id255}
                                                            open={open255}
                                                            anchorEl={anchorEl255}
                                                            onClose={() => setAnchorEl255(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}


                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row mb-2">
                                                                    <label className="col-sm-2 col-form-label col-form-label-sm">Left</label>
                                                                    <div className="col-sm-6">
                                                                        <input type="text" name="left" onChange={(e) => setApparentLegLength({ ...apparentLegLength, [e.target.name]: e.target.value })} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>mm</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-2">
                                                                    <label className="col-sm-2 col-form-label col-form-label-sm">Right</label>
                                                                    <div className="col-sm-6">
                                                                        <input type="text" name="right" onChange={(e) => setApparentLegLength({ ...apparentLegLength, [e.target.name]: e.target.value })} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>mm</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">
                                            <p className='me-2'>{`${apparentLegLength.left && "Left : "}`}{apparentLegLength.left}{`${apparentLegLength.left && " mm"}`}</p>
                                            <p>{`${apparentLegLength.right && "Right : "}`}{apparentLegLength.right}{`${apparentLegLength.right && " mm"}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-2"> <b> True leg length</b> = Anterior superior iliac spine to medial malleolus , <b> Apparent leg length</b> = Umbilicus to medial malleous <br></br>
                                    True leg length difference in hip disease and apparent leg length difference in pelvic tilt</p>

                                <div className='row'>
                                    <h6 className="my-1">Test</h6>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Thomas test for fixed flexion deformity</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange45(!change45); setThomasTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl45(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change45 &&
                                                            <Popover
                                                                id={id45}
                                                                open={open45}
                                                                anchorEl={anchorEl45}
                                                                onClose={() => setAnchorEl45(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setThomasTest(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setThomasTest(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{thomasTest}</p>
                                            </div>
                                        </div>

                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Hip joint compression</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange466(!change466); setHipJointCompression("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl466(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change466 &&
                                                            <Popover
                                                                id={id466}
                                                                open={open466}
                                                                anchorEl={anchorEl466}
                                                                onClose={() => setAnchorEl466(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setHipJointCompression(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setHipJointCompression(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{hipJointCompression}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Ober’s test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange46(!change46); setOberTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl46(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change46 &&
                                                            <Popover
                                                                id={id46}
                                                                open={open46}
                                                                anchorEl={anchorEl46}
                                                                onClose={() => setAnchorEl46(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setOberTest(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setOberTest(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{oberTest}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Patrick’s (Fabere) test or Bucket Handle test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange48(!change48); setPatrick("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl48(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change48 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id48}
                                                                open={open48}
                                                                anchorEl={anchorEl48}
                                                                onClose={() => setAnchorEl48(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setPatrick(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setPatrick(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{patrick}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Piriformis test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange49(!change49); setPiriformis("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl49(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change49 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id49}
                                                                open={open49}
                                                                anchorEl={anchorEl49}
                                                                onClose={() => setAnchorEl49(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setPiriformis(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setPiriformis(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{piriformis}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Ely’s test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange50(!change50); setElyTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl50(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change50 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id50}
                                                                open={open50}
                                                                anchorEl={anchorEl50}
                                                                onClose={() => setAnchorEl50(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setElyTest(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setElyTest(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{elyTest}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 ">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Sacro-iliac joint compression test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange42(!change42); setSacroIliac() }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl42(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change42 &&
                                                            <Popover
                                                                id={id42}
                                                                open={open42}
                                                                anchorEl={anchorEl42}
                                                                onClose={() => setAnchorEl42(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setSacroIliac(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setSacroIliac(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{sacroIliac}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Ortolani & Barlow test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange43(!change43); setOrtolani() }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl43(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change43 &&
                                                            <Popover
                                                                id={id43}
                                                                open={open43}
                                                                anchorEl={anchorEl43}
                                                                onClose={() => setAnchorEl43(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Positive" type="radio" name="test1" onChange={(e) => setOrtolani(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Positive
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Negative" type="radio" name="test1" onChange={(e) => setOrtolani(e.target.value)} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Negative
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{ortolani}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="tab-pane fade" id="pills-contact-mus2" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Shoulder</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange56(!change56); setShoulder("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl56(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change56 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id56}
                                                            open={open56}
                                                            anchorEl={anchorEl56}
                                                            onClose={() => setAnchorEl56(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setShoulder(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setShoulder(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{shoulder}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Inspection</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Shape & Contour</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange57(!change57); setShoulder("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl57(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change57 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id57}
                                                            open={open57}
                                                            anchorEl={anchorEl57}
                                                            onClose={() => setAnchorEl57(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {

                                                                    shapeCounterArray.length > 0 &&
                                                                    shapeCounterArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.ShapeAndContour_name} type="checkbox" name={item.id} onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setShapeCounter([...shapCounter, value])
                                                                            } else {
                                                                                const dataNe = shapCounter.filter(item => item !== value)
                                                                                setShapeCounter(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.ShapeAndContour_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(shapCounter).map((item, i) => <p key={i}>{shapCounter[item]}</p>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Anterior View</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange58(!change58); setAnteriorView([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl58(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change58 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id58}
                                                            open={open58}
                                                            anchorEl={anchorEl58}
                                                            onClose={() => setAnchorEl58(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    anteriorViewArray.length > 0 &&
                                                                    anteriorViewArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.AnteriorView_name} type="checkbox" name={item.id} onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setAnteriorView([...anteriorView, value])
                                                                            } else {
                                                                                const dataNe = anteriorView.filter(item => item !== value)
                                                                                setAnteriorView(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.AnteriorView_name}
                                                                        </label>
                                                                    </div>)
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                anteriorView.map((item, i) => <p key={i}>{anteriorView[item]}</p>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Posterior View</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange59(!change59); setLevelOfSpine(""); setWingedScapula("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl59(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change59 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id59}
                                                            open={open59}
                                                            anchorEl={anchorEl59}
                                                            onClose={() => setAnchorEl59(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="exam-bg-white p-1">
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className="w-75">Level of spine of scapula</p>
                                                                        <div className="ms-1">
                                                                            <label className="switch me-1">
                                                                                <input
                                                                                    name="Jaundiced"
                                                                                    value="Jaundiced"
                                                                                    type="checkbox"
                                                                                    onChange={(e) => { setChange60(!change60); setLevelOfSpine("") }}
                                                                                    id="togBtn"
                                                                                />
                                                                                <div onClick={(e) => setAnchorEl60(e.currentTarget)} className="slider round"></div>
                                                                                {
                                                                                    change60 &&
                                                                                    <Popover
                                                                                        className='mt-2'
                                                                                        id={id60}
                                                                                        open={open60}
                                                                                        anchorEl={anchorEl60}
                                                                                        onClose={() => setAnchorEl60(null)}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'center',
                                                                                            horizontal: 'right',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'right',
                                                                                        }}
                                                                                    >

                                                                                        <div className="left-popup">
                                                                                            {
                                                                                                levelOfSpineArray.length > 0 &&
                                                                                                levelOfSpineArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                                    <input className="form-check-input" value={item.LevelOfSpineOfScapula_name} type="radio" name="test1" onChange={(e) => setLevelOfSpine(e.target.value)} id="flexRadioDefault1" />
                                                                                                    <label className="form-check-label" >
                                                                                                        {item.LevelOfSpineOfScapula_name}
                                                                                                    </label>
                                                                                                </div>)
                                                                                            }
                                                                                        </div>
                                                                                    </Popover>
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="history-popup-value">
                                                                        <p>{levelOfSpine}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="exam-bg-white p-1">
                                                                    <div className="d-flex justify-content-between">
                                                                        <p className="w-75">Winged scapula</p>
                                                                        <div className="ms-1">
                                                                            <label className="switch me-1">
                                                                                <input
                                                                                    name="Jaundiced"
                                                                                    value="Jaundiced"
                                                                                    type="checkbox"
                                                                                    onChange={(e) => { setChange61(!change61); setWingedScapula("") }}
                                                                                    id="togBtn"
                                                                                />
                                                                                <div onClick={(e) => setAnchorEl61(e.currentTarget)} className="slider round"></div>
                                                                                {
                                                                                    change61 &&
                                                                                    <Popover
                                                                                        className='mt-2'
                                                                                        id={id61}
                                                                                        open={open61}
                                                                                        anchorEl={anchorEl61}
                                                                                        onClose={() => setAnchorEl61(null)}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'center',
                                                                                            horizontal: 'right',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'right',
                                                                                        }}
                                                                                    >

                                                                                        <div className="left-popup">
                                                                                            {
                                                                                                wingedScapulaArray.length > 0 &&
                                                                                                wingedScapulaArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                                    <input className="form-check-input" value={item.WingedScapula_name} type="radio" name="test1" onChange={(e) => setWingedScapula(e.target.value)} id="flexRadioDefault1" />
                                                                                                    <label className="form-check-label" >
                                                                                                        {item.WingedScapula_name}
                                                                                                    </label>
                                                                                                </div>)
                                                                                            }
                                                                                        </div>
                                                                                    </Popover>
                                                                                }
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="history-popup-value">
                                                                        <p>{wingedScapula}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{levelOfSpine}</p>
                                            <p>{wingedScapula}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className='d-flex'>
                                    <h6 className="my-1">Palpation</h6>
                                    <div className='d-flex ml-2'>
                                        <input className='custom-radio me-1' type="checkbox" />
                                        <label>All Normal</label>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Sternum</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange62(!change62); sternum == "" ? setSternum("Tender") : setSternum("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl62(e.currentTarget)} className="slider round"></div>


                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{sternum}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Coracoid Process</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange63(!change63); coracoidProcess == "" ? setCoracoidProcess("Tender") : setCoracoidProcess("") }}
                                                        id="togBtn"

                                                    />
                                                    <div onClick={(e) => setAnchorEl63(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{coracoidProcess}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Scapula</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange64(!change64); scapula == "" ? setScapula("Tender") : setScapula("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl64(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{scapula}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Axillary Lymphadenopathy</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange65(!change65); axillaryLymphadenopathy == "" ? setAxillaryLymphadenopathy("Tender") : setAxillaryLymphadenopathy("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl65(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{axillaryLymphadenopathy}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">AC - Joint</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange66(!change66); ACJoint == "" ? setACJoint("Tender") : setACJoint("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl66(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{ACJoint}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Joint Margin</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange67(!change67); jointMargin == "" ? setJointMargin("Tender") : setJointMargin("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl67(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{jointMargin}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Infraspinatus Tendon</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange68(!change68); infraspinatusTendon == "" ? setInfraspinatusTendon("Tender") : setInfraspinatusTendon("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl68(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{infraspinatusTendon}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Subacromial Space</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange699(!change699); subacromialSpace == "" ? setSubacromialSpace("Tender") : setSubacromialSpace("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl699(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{subacromialSpace}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Spine of Scapula</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange69(!change69); spineofScapula == "" ? setSpineofScapula("Tender") : setSpineofScapula("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl69(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{spineofScapula}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Supraspinatus Tendon</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange70(!change70); supraSpinatusTendon == "" ? setSupraSpinatusTendon("Tender") : setSupraSpinatusTendon("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl70(e.currentTarget)} className="slider round"></div>

                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{supraSpinatusTendon}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Movements</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Abduction (170&#176; - 180&#176;)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange183(!change183); setabduction170() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl183(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change183 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id183}
                                                            open={open183}
                                                            anchorEl={anchorEl183}
                                                            onClose={() => setAnchorEl183(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setabduction170(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{abduction170 && <>Abduction: {abduction170}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Adduction (50&#176; - 75&#176;)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange189(!change189); setabduction50shoulder() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl189(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change189 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id189}
                                                            open={open189}
                                                            anchorEl={anchorEl189}
                                                            onClose={() => setAnchorEl189(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setabduction50shoulder(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{abduction50shoulder && <>Abduction: {abduction50shoulder}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Flexion (160&#176; - 180&#176;)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange185(!change185); setFlexion160shoulder() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl185(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change185 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id185}
                                                            open={open185}
                                                            anchorEl={anchorEl185}
                                                            onClose={() => setAnchorEl185(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setFlexion160shoulder(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{Flexion160shoulder && <>Flexion: {Flexion160shoulder}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Extension (50&#176; - 60&#176;)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange186(!change186); setExtension50() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl186(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change186 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id186}
                                                            open={open186}
                                                            anchorEl={anchorEl186}
                                                            onClose={() => setAnchorEl186(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setExtension50(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{Extension50 && <>Extension: {Extension50}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Lateral Rotation (80&#176; - 90&#176; )</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange187(!change187); setLateralRotation80() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl187(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change187 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id187}
                                                            open={open187}
                                                            anchorEl={anchorEl187}
                                                            onClose={() => setAnchorEl187(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setLateralRotation80(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{LateralRotation80 && <>Lateral Rotation: {LateralRotation80}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Medial Rotation</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange188(!change188); setMedialRotation() }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl188(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change188 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id188}
                                                            open={open188}
                                                            anchorEl={anchorEl188}
                                                            onClose={() => setAnchorEl188(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-sm-9">
                                                                        <input type="text" name="left" onChange={(e) => setMedialRotation(e.target.value)} className="form-control form-control-sm" id="colFormLabelSm" />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value d-flex">

                                            <p className='me-2'>{MedialRotation && <>Medial Rotation: {MedialRotation}&#176;</>}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Test</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Painful Arc</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange71(!change71); setPainfulArc("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl71(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change71 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id71}
                                                            open={open71}
                                                            anchorEl={anchorEl71}
                                                            onClose={() => setAnchorEl71(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    painfulArcArray.length > 0 && painfulArcArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio" name="test1"
                                                                                    onChange={(e) => setPainfulArc(e.target.value)} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{painfulArc}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Resisted Movement</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange72(!change72); setResistedMovement([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl72(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change72 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id72}
                                                            open={open72}
                                                            anchorEl={anchorEl72}
                                                            onClose={() => setAnchorEl72(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className='row'>
                                                                    {
                                                                        resistedMovementArray.length > 0 && resistedMovementArray.map((item, i) =>

                                                                            <div key={i} className="col-4">
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setResistedMovement([...resistedMovement, value])
                                                                                        } else {
                                                                                            const dataNe = resistedMovement.filter(item => item !== value)
                                                                                            setResistedMovement(dataNe)
                                                                                        }
                                                                                    }} type="checkbox" name={item.id} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div className="col-8">
                                                                        <div className="row pr-3">
                                                                            <div className="col-6">
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" name="sa" value="Positive" onChange={e => setResistedMovementside(e.target.value)} type="radio" id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" name="sa" value="Negative" onChange={e => setResistedMovementside(e.target.value)} type="radio" id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(resistedMovement).map((item, i) => <p key={i}>{resistedMovement[item]}</p>)
                                            }
                                            <p>{resistedMovementside}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className='d-flex'>
                                        <h6 className="my-1">Special Test</h6>
                                        <div className='d-flex ml-2'>
                                            <input className='me-1 custom-radio' type="checkbox" />
                                            <label>All Normal</label>
                                        </div>
                                    </div>
                                    <div className={`g-doc-scroll shoulder-special-test ${specialTestShowAll ? "special-test-full-height" : "special-test-half-height"
                                        }`}>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Acromioclavicular Joint O’Briens Test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange7333(!change7333); acromioclavicular == "" ? setAcromioclavicular("Positive") : setAcromioclavicular("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl7333(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{acromioclavicular}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Scapular Assistance Test (SAT)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange73(!change73); scapularAssistanceTest == "" ? setScapularAssistanceTest("Positive") : setScapularAssistanceTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl73(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{scapularAssistanceTest}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Lateral Slide Test (LST)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange7444(!change7444); lateralSlideTest == "" ? setLateralSlideTest("Positive") : setLateralSlideTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl7444(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{lateralSlideTest}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Impingement  - Neer test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange74(!change74); impigementNeerTest == "" ? setImpigementNeerTest("Positive") : setImpigementNeerTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl74(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{impigementNeerTest}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Impingement  - Hawkins/kennedy test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange75(!change75); impigementHawkinskennedyTest == "" ? setImpigementHawkinskennedyTest("Positive") : setImpigementHawkinskennedyTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl75(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{impigementHawkinskennedyTest}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Instability - load and shift test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange76(!change76); instabilityLoadAndShiftTest == "" ? setInstabilityLoadAndShiftTest("Positive") : setInstabilityLoadAndShiftTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl76(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{instabilityLoadAndShiftTest}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Instability - apprehension augmentation, relocation test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange7777(!change7777); instabilityApprehension == "" ? setInstabilityApprehension("Positive") : setInstabilityApprehension("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl7777(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{instabilityApprehension}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Inferior - sulcus sign</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange77(!change77); inferiorSulcusSign == "" ? setInferiorSulcusSign("Positive") : setInferiorSulcusSign("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl77(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{inferiorSulcusSign}</p>
                                            </div>
                                        </div>

                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">SLAP leison O’Briens test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange79(!change79); SLAPLeisonOBriensTest == "" ? setSLAPLeisonOBriensTest("Positive") : setSLAPLeisonOBriensTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl79(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{SLAPLeisonOBriensTest}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Specific palpation for trigger point</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange80(!change80); specificPalpation == "" ? setSpecificPalpation("Positive") : setSpecificPalpation("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl80(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{specificPalpation}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Upper limb neuro - dynamic test</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange81(!change81); upperLimbNeuroDynamicTest == "" ? setUpperLimbNeuroDynamicTest("Positive") : setUpperLimbNeuroDynamicTest("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl81(e.currentTarget)} className="slider round"></div>

                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{upperLimbNeuroDynamicTest}</p>
                                            </div>
                                        </div>
                                        {!specialTestShowAll ? (
                                            <span
                                                onClick={() => setSpecialTestShowAll(!specialTestShowAll)}
                                                className="history-see-all"
                                            >
                                                Show All <i className="ms-1 far fa-angle-down"></i>
                                            </span>
                                        ) : (
                                            <span
                                                onClick={() => setSpecialTestShowAll(!specialTestShowAll)}
                                                className="history-see-all"
                                            >
                                                Show Less <i className="ms-1 far fa-angle-up"></i>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact-mus3" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Hand & Wrist</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange82(!change82); setHandWrist("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl82(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change82 &&
                                                        <Popover
                                                            className='mt-2'
                                                            id={id82}
                                                            open={open82}
                                                            anchorEl={anchorEl82}
                                                            onClose={() => setAnchorEl82(null)}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setHandWrist(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setHandWrist(e.target.value)} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{handWrist}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <h6 className="my-1">Inspection (Look)</h6>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Wrist</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange83(!change83); setWrist([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl83(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change83 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id83}
                                                                open={open83}
                                                                anchorEl={anchorEl83}
                                                                onClose={() => setAnchorEl83(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div className="left-popup">
                                                                    {
                                                                        wristArray.length > 0 &&
                                                                        wristArray.map((item, i) =>
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.Wrist_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setWrist([...wrist, value])
                                                                                    } else {
                                                                                        const dataNe = wrist.filter(item => item !== value)
                                                                                        setWrist(dataNe)
                                                                                    }
                                                                                }
                                                                                } id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.Wrist_name}
                                                                                </label>
                                                                            </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                {
                                                    Object.keys(wrist).map((item, i) => <p key={i}>{wrist[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Distal Interphalangeal Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange84(!change84); setDistalInterphalangealJointSide(""); setDistalInterphalangealJointNumber(""); setDistalInterphalangealJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl84(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change84 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id84}
                                                                open={open84}
                                                                anchorEl={anchorEl84}
                                                                onClose={() => setAnchorEl84(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setDistalInterphalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setDistalInterphalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setDistalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setDistalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setDistalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setDistalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setDistalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            distalInterphalangealJointArray.length > 0 &&
                                                                            distalInterphalangealJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.DistalInterphalangealJoint_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setDistalInterphalangealJoint([...distalInterphalangealJoint, value])
                                                                                    } else {
                                                                                        const dataNe = distalInterphalangealJoint.filter(item => item !== value)
                                                                                        setDistalInterphalangealJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.DistalInterphalangealJoint_name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{distalInterphalangealJointSide}</p>
                                                    <p className="me-2">{distalInterphalangealJointNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(distalInterphalangealJoint).map((item, i) => <p key={i}>{distalInterphalangealJoint[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Metacarpophalangeal Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange8555(!change8555); setMetacarpophalangealJointSide(""); setMetacarpophalangealJointNumber(""); setMetacarpophalangealJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl8555(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change8555 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id8555}
                                                                open={open8555}
                                                                anchorEl={anchorEl8555}
                                                                onClose={() => setAnchorEl8555(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setMetacarpophalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setMetacarpophalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            metacarpophalangealJointArray.length > 0 &&
                                                                            metacarpophalangealJointArray.map((item, i) =>
                                                                                <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.MetacarpophalangealJoint_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setMetacarpophalangealJoint([...metacarpophalangealJoint, value])
                                                                                        } else {
                                                                                            const dataNe = metacarpophalangealJoint.filter(item => item !== value)
                                                                                            setMetacarpophalangealJoint(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.MetacarpophalangealJoint_name}
                                                                                    </label>
                                                                                </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{metacarpophalangealJointSide}</p>
                                                    <p className="me-2">{metacarpophalangealJointNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(metacarpophalangealJoint).map((item, i) => <p key={i}>{metacarpophalangealJoint[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Nails</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange86(!change86); setNailsSide(""); setNailsNumber(""); setNails([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl86(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change86 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id86}
                                                                open={open86}
                                                                anchorEl={anchorEl86}
                                                                onClose={() => setAnchorEl86(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setNailsSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setNailsSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setNailsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setNailsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setNailsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setNailsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setNailsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            nailsArray.length > 0 &&
                                                                            nailsArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setNails([...nails, value])
                                                                                    } else {
                                                                                        const dataNe = nails.filter(item => item !== value)
                                                                                        setNails(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{nailsSide}</p>
                                                    <p className="me-2">{nailsNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(nails).map((item, i) => <p key={i}>{nails[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Proximal Interphalangeal Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange87(!change87); setProximalInterphalangealJointSide(""); setProximalInterphalangealJointNumber(""); setProximalInterphalangealJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl87(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change87 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id87}
                                                                open={open87}
                                                                anchorEl={anchorEl87}
                                                                onClose={() => setAnchorEl87(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setProximalInterphalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setProximalInterphalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            proximalInterphalangealJointArray.length > 0 &&
                                                                            proximalInterphalangealJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.ProximalInterphalangealJoint_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setProximalInterphalangealJoint([...proximalInterphalangealJoint, value])
                                                                                    } else {
                                                                                        const dataNe = proximalInterphalangealJoint.filter(item => item !== value)
                                                                                        setProximalInterphalangealJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.ProximalInterphalangealJoint_name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{proximalInterphalangealJointSide}</p>
                                                    <p className="me-2">{proximalInterphalangealJointNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(proximalInterphalangealJoint).map((item, i) => <p key={i}>{proximalInterphalangealJoint[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <h6 className='mt-2'>Passive</h6>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Flexion</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange101(!change101); setHandMovementPassiveFlexion("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl101(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change101 &&
                                                            <Popover
                                                                id={id101}
                                                                open={open101}
                                                                anchorEl={anchorEl101}
                                                                onClose={() => setAnchorEl101(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Normal" type="radio" name="cough1" onChange={(e) => { setHandMovementPassiveFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Normal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Restricted" type="radio" name="cough1" onChange={(e) => { setHandMovementPassiveFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Restricted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementPassiveFlexion}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Extension</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange102(!change102); setHandMovementPassiveExtension("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl102(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change102 &&
                                                            <Popover
                                                                id={id102}
                                                                open={open102}
                                                                anchorEl={anchorEl102}
                                                                onClose={() => setAnchorEl102(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Normal" type="radio" name="cough1" onChange={(e) => { setHandMovementPassiveExtension(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Normal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Restricted" type="radio" name="cough1" onChange={(e) => { setHandMovementPassiveExtension(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Restricted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementPassiveExtension}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Palpation</h6>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Dorsum of Hand</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange88(!change88); setDorsumofHand("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl88(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change88 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id88}
                                                                open={open88}
                                                                anchorEl={anchorEl88}
                                                                onClose={() => setAnchorEl88(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    {
                                                                        dorsumofHandArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.name} type="radio" name="test1" onChange={(e) => setDorsumofHand(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>)
                                                                    }

                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{dorsumofHand}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Fingers</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange89(!change89); setFingersSide(""); setFingersNumber(""); setFingersHand([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl89(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change89 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id89}
                                                                open={open89}
                                                                anchorEl={anchorEl89}
                                                                onClose={() => setAnchorEl89(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setFingersSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setFingersSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setFingersNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setFingersNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setFingersNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setFingersNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setFingersNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            fingersArray.length > 0 &&
                                                                            fingersArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFingersHand([...fingersHand, value])
                                                                                    } else {
                                                                                        const dataNe = fingersHand.filter(item => item !== value)
                                                                                        setFingersHand(dataNe)
                                                                                    }
                                                                                }
                                                                                } id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{fingersSide}</p>
                                                    <p className="me-2">{fingersNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(fingersHand).map((item, i) => <p key={i}>{fingersHand[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Proximal Interphalangeal Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange90(!change90); setPalpationProximalInterphalangealJointSide(""); setPalpationProximalInterphalangealJointNumber(""); setPalpationProximalInterphalangealJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl90(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change90 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id90}
                                                                open={open90}
                                                                anchorEl={anchorEl90}
                                                                onClose={() => setAnchorEl90(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setPalpationProximalInterphalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setPalpationProximalInterphalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setPalpationProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setPalpationProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setPalpationProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setPalpationProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setPalpationProximalInterphalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            proximalInterphalangealJointArray.length > 0 &&
                                                                            proximalInterphalangealJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.ProximalInterphalangealJoint_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPalpationProximalInterphalangealJoint([...palpationProximalInterphalangealJoint, value])
                                                                                    } else {
                                                                                        const dataNe = palpationProximalInterphalangealJoint.filter(item => item !== value)
                                                                                        setPalpationProximalInterphalangealJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.ProximalInterphalangealJoint_name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{palpationProximalInterphalangealJointSide}</p>
                                                    <p className="me-2">{palpationProximalInterphalangealJointNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(palpationProximalInterphalangealJoint).map((item, i) => <p key={i}>{palpationProximalInterphalangealJoint[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Tenders</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange92(!change92); setPalpationTenders([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl92(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change92 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id92}
                                                                open={open92}
                                                                anchorEl={anchorEl92}
                                                                onClose={() => setAnchorEl92(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <div className="left-popup">
                                                                    {
                                                                        palpationTendersArray.length > 0 &&
                                                                        palpationTendersArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.name} type="Checkbox" name={item.name} onChange={(e) => {
                                                                                const { checked, value } = e.target;

                                                                                if (checked) {
                                                                                    setPalpationTenders([...palpationTenders, value])
                                                                                } else {
                                                                                    const dataNe = palpationTenders.filter(item => item !== value)
                                                                                    setPalpationTenders(dataNe)
                                                                                }
                                                                            }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                {
                                                    Object.keys(palpationTenders).map((item, i) => <p key={i}>{palpationTenders[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Wrist</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange93(!change93); setPalpationWrist([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl93(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change93 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id93}
                                                                open={open93}
                                                                anchorEl={anchorEl93}
                                                                onClose={() => setAnchorEl93(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div className="left-popup">
                                                                    {
                                                                        wristArray.length > 0 &&
                                                                        wristArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.Wrist_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                const { checked, value } = e.target;

                                                                                if (checked) {
                                                                                    setPalpationWrist([...palpationWrist, value])
                                                                                } else {
                                                                                    const dataNe = palpationWrist.filter(item => item !== value)
                                                                                    setPalpationWrist(dataNe)
                                                                                }
                                                                            }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.Wrist_name}
                                                                            </label>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                {
                                                    Object.keys(palpationWrist).map((item, i) => <p key={i}>{palpationWrist[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Flexon Tendons</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange94(!change94); setFlexonTendonsSide(""); setFlexonTendonsNumber(""); setFlexonTendons([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl94(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change94 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id94}
                                                                open={open94}
                                                                anchorEl={anchorEl94}
                                                                onClose={() => setAnchorEl94(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setFlexonTendonsSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setFlexonTendonsSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setFlexonTendonsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setFlexonTendonsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setFlexonTendonsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setFlexonTendonsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setFlexonTendonsNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            flexonTendonsArray.length > 0 &&
                                                                            flexonTendonsArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.name} type="Checkbox" name={item.name} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFlexonTendons([...flexonTendons, value])
                                                                                    } else {
                                                                                        const dataNe = flexonTendons.filter(item => item !== value)
                                                                                        setFlexonTendons(dataNe)
                                                                                    }
                                                                                }
                                                                                } id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{flexonTendonsSide}</p>
                                                    <p className="me-2">{flexonTendonsNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(flexonTendons).map((item, i) => <p key={i}>{flexonTendons[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75"> Metacarpophalangeal Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange85(!change85); setPalpationMetacarpophalangealJointSide(""); setPalpationMetacarpophalangealJointNumber(""); setPalpationMetacarpophalangealJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl85(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change85 &&
                                                            <Popover
                                                                className='mt-2'
                                                                id={id85}
                                                                open={open85}
                                                                anchorEl={anchorEl85}
                                                                onClose={() => setAnchorEl85(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >


                                                                <div style={{ width: "350px" }} className="left-popup row">
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="test1" onChange={(e) => setPalpationMetacarpophalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="test1" onChange={(e) => setPalpationMetacarpophalangealJointSide(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="1st" type="radio" name="test2" onChange={(e) => setPalpationMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                1st
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="2nd" type="radio" name="test2" onChange={(e) => setPalpationMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                2nd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="3rd" type="radio" name="test2" onChange={(e) => setPalpationMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                3rd
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="4th" type="radio" name="test2" onChange={(e) => setPalpationMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                4th
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="5th" type="radio" name="test2" onChange={(e) => setPalpationMetacarpophalangealJointNumber(e.target.value)} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                5th
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        {
                                                                            metacarpophalangealJointArray.length > 0 &&
                                                                            metacarpophalangealJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input" value={item.MetacarpophalangealJoint_name} type="Checkbox" name={item.id} onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPalpationMetacarpophalangealJoint([...palpationMetacarpophalangealJoint, value])
                                                                                    } else {
                                                                                        const dataNe = palpationMetacarpophalangealJoint.filter(item => item !== value)
                                                                                        setPalpationMetacarpophalangealJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.MetacarpophalangealJoint_name}
                                                                                </label>
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <div className="d-flex">
                                                    <p className="me-2">{palpationMetacarpophalangealJointSide}</p>
                                                    <p className="me-2">{palpationMetacarpophalangealJointNumber}</p>
                                                </div>
                                                {
                                                    Object.keys(palpationMetacarpophalangealJoint).map((item, i) => <p key={i}>{palpationMetacarpophalangealJoint[item]}</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Movement</h6>
                                    <div className="">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Wrist</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange95(!change95); setHandMovementWrist("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl95(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change95 &&
                                                            <Popover
                                                                id={id95}
                                                                open={open95}
                                                                anchorEl={anchorEl95}
                                                                onClose={() => setAnchorEl95(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setHandMovementWrist(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Left
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setHandMovementWrist(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Right
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementWrist}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Finger</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange96(!change96); setHandMovementFinger("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl96(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change96 &&
                                                            <Popover
                                                                id={id96}
                                                                open={open96}
                                                                anchorEl={anchorEl96}
                                                                onClose={() => setAnchorEl96(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="1st" type="radio" name="cough1" onChange={(e) => { setHandMovementFinger(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            1st
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="2nd" type="radio" name="cough1" onChange={(e) => { setHandMovementFinger(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            2nd
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="3rd" type="radio" name="cough1" onChange={(e) => { setHandMovementFinger(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            3rd
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="4th" type="radio" name="cough1" onChange={(e) => { setHandMovementFinger(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            4th
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="5th" type="radio" name="cough1" onChange={(e) => { setHandMovementFinger(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            5th
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementFinger}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <h6 className="mt-2">Active</h6>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Flexion</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange97(!change97); setHandMovementFlexion("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl97(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change97 &&
                                                            <Popover
                                                                id={id97}
                                                                open={open97}
                                                                anchorEl={anchorEl97}
                                                                onClose={() => setAnchorEl97(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Normal" type="radio" name="cough1" onChange={(e) => { setHandMovementFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Normal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Restricted" type="radio" name="cough1" onChange={(e) => { setHandMovementFlexion(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Restricted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementFlexion}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Extension</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange98(!change98); setHandMovementExtension("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl98(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change98 &&
                                                            <Popover
                                                                id={id98}
                                                                open={open98}
                                                                anchorEl={anchorEl98}
                                                                onClose={() => setAnchorEl98(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Normal" type="radio" name="cough1" onChange={(e) => { setHandMovementExtension(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Normal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Restricted" type="radio" name="cough1" onChange={(e) => { setHandMovementExtension(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Restricted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementExtension}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Supination</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange99(!change99); setHandMovementSupination("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl99(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change99 &&
                                                            <Popover
                                                                id={id99}
                                                                open={open99}
                                                                anchorEl={anchorEl99}
                                                                onClose={() => setAnchorEl99(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Normal" type="radio" name="cough1" onChange={(e) => { setHandMovementSupination(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Normal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Restricted" type="radio" name="cough1" onChange={(e) => { setHandMovementSupination(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Restricted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementSupination}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Pronation</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange100(!change100); setHandMovementPronation("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl100(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change100 &&
                                                            <Popover
                                                                id={id100}
                                                                open={open100}
                                                                anchorEl={anchorEl100}
                                                                onClose={() => setAnchorEl100(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Normal" type="radio" name="cough1" onChange={(e) => { setHandMovementPronation(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Normal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Restricted" type="radio" name="cough1" onChange={(e) => { setHandMovementPronation(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Restricted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{handMovementPronation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Test</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Finkelstein’s Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange103(!change103); setFinkelsteinTest(!finkelsteinTest) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl103(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change103 &&
                                                        <Popover
                                                            id={id103}
                                                            open={open103}
                                                            anchorEl={anchorEl103}
                                                            onClose={() => setAnchorEl103(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "300px" }} className="left-popup">
                                                                <p>Ask the patient to make a first with the thumb inside fingers examiner stabilizes the forearm and ulnar deviates the wrist</p>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Phalen’s Wrist Flexion Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange104(!change104); setWrishFlexionTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl104(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change104 &&
                                                        <Popover
                                                            id={id104}
                                                            open={open104}
                                                            anchorEl={anchorEl104}
                                                            onClose={() => setAnchorEl104(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <table className="popup-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Positive | Paresthesia over medium nerves distribution" type="radio" name="cough1" onChange={(e) => { setWrishFlexionTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Paresthesia over medium nerves distribution
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Negative | Ask the patient to make a fist, if the distal phalanx of one finger dose not flex" type="radio" name="cough1" onChange={(e) => { setWrishFlexionTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Ask the patient to make a fist, if the distal phalanx of one finger does not flex
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{wrishFlexionTest}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Flexor Digitorum Profundus Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange105(!change105); setFlexorDigitorumProfundusTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl105(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change105 &&
                                                        <Popover
                                                            id={id105}
                                                            open={open105}
                                                            anchorEl={anchorEl105}
                                                            onClose={() => setAnchorEl105(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "350px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-4 border">
                                                                        {
                                                                            flexorDigitorumProfundusTestArray.length > 0 && flexorDigitorumProfundusTestArray.map((item, i) => {
                                                                                return (

                                                                                    <div key={i} className="form-check ms-1">
                                                                                        <input className="form-check-input"
                                                                                            value={item.name}
                                                                                            type="radio"
                                                                                            name="cough1"
                                                                                            onChange={(e) => { setFlexorDigitorumProfundusTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>

                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="col-8 border">
                                                                        <span>Stabilize the MJP & IPJ in extension. Ask the patient to flex the DIP</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{flexorDigitorumProfundusTest}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Murphy’s Sign</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange106(!change106); setHandMurphySign("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl106(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change106 &&
                                                        <Popover
                                                            id={id106}
                                                            open={open106}
                                                            anchorEl={anchorEl106}
                                                            onClose={() => setAnchorEl106(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <table className="popup-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Positive | Lunate Dislocation" type="radio" name="cough1" onChange={(e) => { setHandMurphySign(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Lunate Dislocation
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Negative | Ask the patient to make a fist, if the distal phalanx of one finger does not flex" type="radio" name="cough1" onChange={(e) => { setHandMurphySign(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Ask the patient to make a fist, if the distal phalanx of one finger does not flex
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{handMurphySign}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Tinel’s Sign</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange107(!change107); setHandTinelSign("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl107(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change107 &&
                                                        <Popover
                                                            id={id107}
                                                            open={open107}
                                                            anchorEl={anchorEl107}
                                                            onClose={() => setAnchorEl107(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <table className="popup-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Positive | Paresthesia over medium nerves distribution" type="radio" name="cough1" onChange={(e) => { setHandTinelSign(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Paresthesia over medium nerves distribution
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Negative | Examiner tap over Carpel tunnel at wrist positive tests indicate tingling and paraesthesia over the thumb, index, and medial half of the middle finger" type="radio" name="cough1" onChange={(e) => { setHandTinelSign(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Examiner tap over Carpel tunnel at wrist positive tests indicate tingling and paraesthesia over the thumb, index, and medial half of the middle finger
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{handTinelSign}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Watson Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange108(!change108); setWatsonTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl108(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change108 &&
                                                        <Popover
                                                            id={id108}
                                                            open={open108}
                                                            anchorEl={anchorEl108}
                                                            onClose={() => setAnchorEl108(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <table className="popup-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Positive | Test for scapho-lunate instability" type="radio" name="cough1" onChange={(e) => { setWatsonTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Test for scapho-lunate instability
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Negative | Place thumb on scaphoid with wrist in ulner deviation then deviate wrist radically while pressure applied to scaphoid. Pain felt dorsally or click" type="radio" name="cough1" onChange={(e) => { setWatsonTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Place thumb on scaphoid with wrist in ulner deviation then deviate wrist radically while pressure applied to scaphoid. Pain felt dorsally or click
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{watsonTest}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Sweater Finger Sign</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange109(!change109); setSweaterFingerSign("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl109(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change109 &&
                                                        <Popover
                                                            id={id109}
                                                            open={open109}
                                                            anchorEl={anchorEl109}
                                                            onClose={() => setAnchorEl109(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <table className="popup-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Positive | Ruptured flexor digitorum profunds tendon" type="radio" name="cough1" onChange={(e) => { setSweaterFingerSign(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Ruptured flexor digitorum profunds tendon
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Negative | Ask the patient to make a fist, if the head of the 3rd metacarpal is level with the 2nd and 4th metacarpal" type="radio" name="cough1" onChange={(e) => { setSweaterFingerSign(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Ask the patient to make a fist, if the head of the 3rd metacarpal is level with the 2nd and 4th metacarpal
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{sweaterFingerSign}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Flexor Digitorum Superficialis Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange110(!change110); setFlexorDigitorumSuperficialisTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl110(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change110 &&
                                                        <Popover
                                                            id={id110}
                                                            open={open110}
                                                            anchorEl={anchorEl110}
                                                            onClose={() => setAnchorEl110(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div style={{ width: "350px" }} className="left-popup">
                                                                <div className="row">

                                                                    <div className="col-4 border">
                                                                        {
                                                                            flexorDigitorumSuperficialisTestArray.length > 0 && flexorDigitorumSuperficialisTestArray.map((item, i) => {
                                                                                return (
                                                                                    <div key={i} className="form-check ms-1">
                                                                                        <input className="form-check-input"
                                                                                            value={item.name}
                                                                                            type="radio"
                                                                                            name="cough1"
                                                                                            onChange={(e) => { setFlexorDigitorumSuperficialisTest(e.target.value) }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="col-8 border">
                                                                        <span>Hold patient finger in extension, expect finger been tested. Ask the patient to flex finger in Question at PIP. If he can do FDS intact</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{flexorDigitorumSuperficialisTest}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">de Quervain’s Tenosynovitis</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange111(!change111); setQuervainTenosynovitis("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl111(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change111 &&
                                                        <Popover
                                                            id={id111}
                                                            open={open111}
                                                            anchorEl={anchorEl111}
                                                            onClose={() => setAnchorEl111(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <table className="popup-table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Positive | Pain over abductor pollicis longus and extensor policis brevis" type="radio" name="cough1" onChange={(e) => { setQuervainTenosynovitis(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Positive
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                Pain over abductor pollicis longus and extensor policis brevis
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check ms-1">
                                                                                    <input className="form-check-input" value="Negative | No pain over abductor policies longus and extensor policies bravos" type="radio" name="cough1" onChange={(e) => { setQuervainTenosynovitis(e.target.value) }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        Negative
                                                                                    </label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                No pain over abductor policies longus and extensor policies bravos
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{quervainTenosynovitis}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact-mus4" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Elbow Joint</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange112(!change112); setElbowJoint("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl112(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change112 &&
                                                        <Popover
                                                            id={id112}
                                                            open={open112}
                                                            anchorEl={anchorEl112}
                                                            onClose={() => setAnchorEl112(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setElbowJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setElbowJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowJoint}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">History</h6>
                                {
                                    elbowHistoryArray.length > 0 &&
                                    elbowHistoryArray.map((item, i) => <div key={i} className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">{item.name}</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name={item.name}
                                                            value={item.name}
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                const { checked, value } = e.target;

                                                                if (checked) {
                                                                    setElbowHistoryValue([...elbowHistoryValue, value])
                                                                } else {
                                                                    const dataNe = elbowHistoryValue.filter(item => item !== value)
                                                                    setElbowHistoryValue(dataNe)
                                                                }
                                                            }}
                                                            id="togBtn"
                                                        />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Examination</h6>
                                <span style={{ fontWeight: "500", cursor: "pointer" }} data-bs-toggle="tooltip" title="Ask the patient to straighten elbows beside body and in supination to determine carring angels" className="my-1">Inspection</span>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Female (10&#176;  - 15&#176;  )</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange113(!change113); setElbowFemale("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl113(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change113 &&
                                                        <Popover
                                                            id={id113}
                                                            open={open113}
                                                            anchorEl={anchorEl113}
                                                            onClose={() => setAnchorEl113(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    inspectionFemaleArray.length > 0 && inspectionFemaleArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio" name={item.name}
                                                                                    onChange={(e) => { setElbowFemale(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    <i className="fad fa-angle-right"></i> {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowFemale}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Male (5&#176;  - 10&#176;  )</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange114(!change114); setElbowMale("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl114(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change114 &&
                                                        <Popover
                                                            id={id114}
                                                            open={open114}
                                                            anchorEl={anchorEl114}
                                                            onClose={() => setAnchorEl114(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    inspectionMaleArray.length > 0 && inspectionMaleArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio" name="cough1"
                                                                                    onChange={(e) => { setElbowMale(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    <i className="fad fa-angle-right"></i> {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowMale}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Contractual deformity</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setContractualDeformity(!contractualDeformity) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Soft tissue control normal</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setSoftTissueControlNormal(!softTissueControlNormal) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Scars</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setElbowScars(!elbowScars) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Swellings</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setSwellings(!swellings) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Palpation</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Tenderness</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setElbowTenderness(!elbowTenderness) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Crepitus</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setElbowCrepitus(!elbowCrepitus) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Deformity</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setElbowDeformity(!elbowDeformity) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Muscle Wasting</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setElbowMuscleWasting(!elbowMuscleWasting) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Effusion</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setElbowEffussion(!elbowEffussion) }}
                                                        id="togBtn"
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">At 90&#176;   Flexion</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange115(!change115); setAtFlextion([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl115(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change115 &&
                                                        <Popover
                                                            id={id115}
                                                            open={open115}
                                                            anchorEl={anchorEl115}
                                                            onClose={() => setAnchorEl115(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <p>Olecranon, the medial and the lateral epicondyles</p>
                                                                {
                                                                    at90FlextionArray.length > 0 && at90FlextionArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="checkbox"
                                                                                    name={item.name}
                                                                                    onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setAtFlextion([...atFlexion, value])
                                                                                        } else {
                                                                                            const dataNe = atFlexion.filter(item => item !== value)
                                                                                            setAtFlextion(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(atFlexion).map((item, i) => <p key={i}>{atFlexion[item]}</p>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">At Full Flexion</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange116(!change116); setAtFullFlextion([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl116(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change116 &&
                                                        <Popover
                                                            id={id116}
                                                            open={open116}
                                                            anchorEl={anchorEl116}
                                                            onClose={() => setAnchorEl116(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <p>Olecranon, the medial and the lateral epicondyles</p>
                                                                {
                                                                    atFullFlextionPalpationArray.length > 0 && atFullFlextionPalpationArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="checkbox"
                                                                                    name={item.name}
                                                                                    onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setAtFullFlextion([...atFullFlexion, value])
                                                                                        } else {
                                                                                            const dataNe = atFullFlexion.filter(item => item !== value)
                                                                                            setAtFullFlextion(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(atFullFlexion).map((item, i) => <p key={i}>{atFullFlexion[item]}</p>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Examination</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Flexion (140° - 150° )</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange117(!change117); setElbowExamFlextion(""); setElbowExamFlextionNumber("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl117(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change117 &&
                                                        <Popover
                                                            id={id117}
                                                            open={open117}
                                                            anchorEl={anchorEl117}
                                                            onClose={() => setAnchorEl117(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "200px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        {
                                                                            flextion140to150ExaminationArray.length > 0 && flextion140to150ExaminationArray.map((item, i) => {
                                                                                return (
                                                                                    <div key={i} className="form-check ms-1">
                                                                                        <input className="form-check-input"
                                                                                            value={item.name}
                                                                                            type="radio"
                                                                                            name="cough1"
                                                                                            onChange={(e) => { setElbowExamFlextion(e.target.value) }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <input type="text" onChange={(e) => setElbowExamFlextionNumber(e.target.value)} className="form-control form-control-sm" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowExamFlextion} {elbowExamFlextionNumber && " | "} {elbowExamFlextionNumber}{elbowExamFlextionNumber && <>&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Extension (0&#176;  - 10&#176;)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange118(!change118); setElbowExamExtention(""); setElbowExamExtentionNumber("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl118(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change118 &&
                                                        <Popover
                                                            id={id118}
                                                            open={open118}
                                                            anchorEl={anchorEl118}
                                                            onClose={() => setAnchorEl118(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "200px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        {
                                                                            extention0to10ExaminationArray.length > 0 && extention0to10ExaminationArray.map((item, i) => {
                                                                                return (
                                                                                    <div key={i} className="form-check ms-1">
                                                                                        <input className="form-check-input"
                                                                                            value={item.name}
                                                                                            type="radio"
                                                                                            name="cough1"
                                                                                            onChange={(e) => { setElbowExamExtention(e.target.value) }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <input type="text" onChange={(e) => setElbowExamExtentionNumber(e.target.value)} className="form-control form-control-sm" />&#176;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowExamExtention} {elbowExamExtentionNumber && " | "} {elbowExamExtentionNumber}{elbowExamExtentionNumber && <>&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Supination 90&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange119(!change119); setElbowExamSupination(""); setElbowExamSupinationNumber("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl119(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change119 &&
                                                        <Popover
                                                            id={id119}
                                                            open={open119}
                                                            anchorEl={anchorEl119}
                                                            onClose={() => setAnchorEl119(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div style={{ width: "200px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        {
                                                                            supination90ExaminationArray.length > 0 && supination90ExaminationArray.map((item, i) => {
                                                                                return (
                                                                                    <div key={i} className="form-check ms-1">
                                                                                        <input className="form-check-input"
                                                                                            value={item.name}
                                                                                            type="radio"
                                                                                            name="cough1"
                                                                                            onChange={(e) => { setElbowExamSupination(e.target.value) }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <input type="text" onChange={(e) => setElbowExamSupinationNumber(e.target.value)} className="form-control form-control-sm" />&#176;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowExamSupination} {elbowExamSupinationNumber && " | "} {elbowExamSupinationNumber}{elbowExamSupinationNumber && <>&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Pronation (80&#176;  - 90&#176;)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange120(!change120); setElbowExamPronation(""); setElbowExamPronationNumber("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl120(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change120 &&
                                                        <Popover
                                                            id={id120}
                                                            open={open120}
                                                            anchorEl={anchorEl120}
                                                            onClose={() => setAnchorEl120(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "200px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        {
                                                                            pronation80to90ExaminationArray.length > 0 && pronation80to90ExaminationArray.map((item, i) => {
                                                                                return (
                                                                                    <div key={i} className="form-check ms-1">
                                                                                        <input className="form-check-input"
                                                                                            value={item.name}
                                                                                            type="radio"
                                                                                            name="cough1"
                                                                                            onChange={(e) => { setElbowExamPronation(e.target.value) }} id="flexRadioDefault1" />
                                                                                        <label className="form-check-label" >
                                                                                            {item.name}
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <input type="text" onChange={(e) => setElbowExamPronationNumber(e.target.value)} className="form-control form-control-sm" />&#176;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{elbowExamPronation} {elbowExamPronationNumber && " | "} {elbowExamPronationNumber}{elbowExamPronationNumber && <>&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact-mus5" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Knee Joint</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange121(!change121); setKneeJoint("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl121(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change121 &&
                                                        <Popover
                                                            id={id121}
                                                            open={open121}
                                                            anchorEl={anchorEl121}
                                                            onClose={() => setAnchorEl121(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setKneeJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setKneeJoint(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeJoint}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Inspection (Look)</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Anterior View</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange122(!change122); setKneeAnteriorView([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl122(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change122 &&
                                                        <Popover
                                                            id={id122}
                                                            open={open122}
                                                            anchorEl={anchorEl122}
                                                            onClose={() => setAnchorEl122(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    kneeAnteriorViewArray.length > 0 &&
                                                                    kneeAnteriorViewArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setKneeAnteriorView([...kneeAnteriorView, value])
                                                                            } else {
                                                                                const dataNe = kneeAnteriorView.filter(item => item !== value)
                                                                                setKneeAnteriorView(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.name}
                                                                        </label>
                                                                    </div>)
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(kneeAnteriorView).map((item, i) => <p key={i}>{kneeAnteriorView[item]}</p>)
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Lateral View</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange123(!change123); setLateralView([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl123(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change123 &&
                                                        <Popover
                                                            id={id123}
                                                            open={open123}
                                                            anchorEl={anchorEl123}
                                                            onClose={() => setAnchorEl123(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    lateralViewKneeArray.length > 0 && lateralViewKneeArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="checkbox"
                                                                                    name="Genu Recurvatum (hyper-extended knee)"
                                                                                    onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setLateralView([...lateralView, value])
                                                                                        } else {
                                                                                            const dataNe = lateralView.filter(item => item !== value)
                                                                                            setLateralView(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(lateralView).map((item, i) => <p key={i}>{lateralView[item]}</p>)
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Posterior View</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange124(!change124); setKneePosteriorView([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl124(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change124 &&
                                                        <Popover
                                                            id={id124}
                                                            open={open124}
                                                            anchorEl={anchorEl124}
                                                            onClose={() => setAnchorEl124(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    posteriorViewKneeArray.length > 0 && posteriorViewKneeArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="checkbox"
                                                                                    name="Swelling (Baker’s cyst)"
                                                                                    onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setKneePosteriorView([...kneePosteriorView, value])
                                                                                        } else {
                                                                                            const dataNe = kneePosteriorView.filter(item => item !== value)
                                                                                            setKneePosteriorView(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(kneePosteriorView).map((item, i) => <p key={i}>{kneePosteriorView[item]}</p>)
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Stance</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Swat: (ask the patient to sit with knee flexed 90 Deg or dangling over couch)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange125(!change125); setStance([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl125(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change125 &&
                                                        <Popover
                                                            id={id125}
                                                            open={open125}
                                                            anchorEl={anchorEl125}
                                                            onClose={() => setAnchorEl125(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    stanceArray.length > 0 &&
                                                                    stanceArray.map((item, i) => <div className="form-check ms-1">
                                                                        <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setStance([...stance, value])
                                                                            } else {
                                                                                const dataNe = stance.filter(item => item !== value)
                                                                                setStance(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            {item.name}
                                                                        </label>
                                                                    </div>)
                                                                }

                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            {
                                                Object.keys(stance).map((item, i) => <p key={i}>{stance[item]}</p>)
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Palpation (lying down position)</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Brush or wipe test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange126(!change126); setBrushOrWipeTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl126(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change126 &&
                                                        <Popover
                                                            id={id126}
                                                            open={open126}
                                                            anchorEl={anchorEl126}
                                                            onClose={() => setAnchorEl126(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="cough1" onChange={(e) => { setBrushOrWipeTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setBrushOrWipeTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{brushOrWipeTest}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Patellar tap test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange127(!change127); setPatellarTapTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl127(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change127 &&
                                                        <Popover
                                                            id={id127}
                                                            open={open127}
                                                            anchorEl={anchorEl127}
                                                            onClose={() => setAnchorEl127(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="cough1" onChange={(e) => { setPatellarTapTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setPatellarTapTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{patellarTapTest}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Zohler's Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange128(!change128); setZohlerTest("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl128(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change128 &&
                                                        <Popover
                                                            id={id128}
                                                            open={open128}
                                                            anchorEl={anchorEl128}
                                                            onClose={() => setAnchorEl128(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Positive" type="radio" name="cough1" onChange={(e) => { setZohlerTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Positive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setZohlerTest(e.target.value) }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Negative
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{zohlerTest}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Joint Margin</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange129(!change129); setJointMargin("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl129(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change129 &&
                                                        <Popover
                                                            id={id129}
                                                            open={open129}
                                                            anchorEl={anchorEl129}
                                                            onClose={() => setAnchorEl129(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    jointMarginArray.length > 0 && jointMarginArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio"
                                                                                    name="cough1"
                                                                                    onChange={(e) => { setJointMargin(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{jointMargin}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Condyle</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange130(!change130); setKneeCondyle("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl130(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change130 &&
                                                        <Popover
                                                            id={id130}
                                                            open={open130}
                                                            anchorEl={anchorEl130}
                                                            onClose={() => setAnchorEl130(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    condyleArray.length > 0 && condyleArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio"
                                                                                    name="cough1"
                                                                                    onChange={(e) => { setKneeCondyle(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeCondyle}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Tibial Tubercle</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange131(!change131); setTibialTubercle("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl131(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change131 &&
                                                        <Popover
                                                            id={id131}
                                                            open={open131}
                                                            anchorEl={anchorEl131}
                                                            onClose={() => setAnchorEl131(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                {
                                                                    tibialTubercleArray.length > 0 && tibialTubercleArray.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="form-check ms-1">
                                                                                <input className="form-check-input"
                                                                                    value={item.name}
                                                                                    type="radio"
                                                                                    name="cough1"
                                                                                    onChange={(e) => { setTibialTubercle(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{tibialTubercle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Above knee measurement</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange132(!change132); setAboveKneeMeasurementSide(""); setAboveKneeMeasurement(""); setAboveKneeMeasurement1("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl132(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change132 &&
                                                        <Popover
                                                            id={id132}
                                                            open={open132}
                                                            anchorEl={anchorEl132}
                                                            onClose={() => setAnchorEl132(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div style={{ width: "200px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setAboveKneeMeasurementSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setAboveKneeMeasurementSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <div className='d-flex'>
                                                                            <input type="text" onChange={(e) => setAboveKneeMeasurement(e.target.value)} className="form-control form-control-sm" />
                                                                            <span className="ms-1">cm</span>
                                                                        </div>
                                                                        <div className="mt-2 d-flex">
                                                                            <input type="text" onChange={(e) => setAboveKneeMeasurement1(e.target.value)} className="form-control form-control-sm" />
                                                                            <span className="ms-1">cm</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{aboveKneeMeasurementSide} {aboveKneeMeasurement} {aboveKneeMeasurement1}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Below knee measurement</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange133(!change133); setBellowKneeMeasurementSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl133(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change133 &&
                                                        <Popover
                                                            id={id133}
                                                            open={open133}
                                                            anchorEl={anchorEl133}
                                                            onClose={() => setAnchorEl133(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div style={{ width: "200px" }} className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setBellowKneeMeasurementSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setBellowKneeMeasurementSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        <div className="d-flex">
                                                                            <input type="text" onChange={(e) => setBellowKneeMeasurement(e.target.value)} className="form-control form-control-sm" />
                                                                            <span className='ms-1'>cm</span>
                                                                        </div>
                                                                        <div className="mt-2 d-flex">
                                                                            <input type="text" onChange={(e) => setBellowKneeMeasurement(e.target.value)} className="form-control form-control-sm" />
                                                                            <span className='ms-1'>cm</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{bellowKneeMeasurementSide} {bellowKneeMeasurement && "|"} {bellowKneeMeasurement}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Movement</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Flexion 0&#176; - 130&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange134(!change134); setKneeFlexion("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl134(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change134 &&
                                                        <Popover
                                                            id={id134}
                                                            open={open134}
                                                            anchorEl={anchorEl134}
                                                            onClose={() => setAnchorEl134(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">

                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <input type="text" onChange={(e) => { setKneeFlexion(e.target.value) }} className="form-control form-control-sm" />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeFlexion} {kneeFlexion && <>&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Extension 0&#176; - 15&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange135(!change135); setKneeExtention("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl135(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change135 &&
                                                        <Popover
                                                            id={id135}
                                                            open={open135}
                                                            anchorEl={anchorEl135}
                                                            onClose={() => setAnchorEl135(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div style={{ width: "100px" }} className="left-popup">

                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        <input type="text" onChange={(e) => { setKneeExtention(e.target.value) }} className="form-control form-control-sm" />
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <span>&#176;</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeExtention} {kneeExtention && <>&#176;</>}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Test</h6>
                                <div className="col-4">
                                    <h6 className="my-1" style={{ cursor: "pointer" }} data-bs-toggle="tooltip" title="Which way the heel turned then that is the meniscus we are testing">Mc Murray’s Test</h6>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Medial Meniscus</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange136(!change136); setMedialMeniscus("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl136(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change136 &&
                                                        <Popover
                                                            id={id136}
                                                            open={open136}
                                                            anchorEl={anchorEl136}
                                                            onClose={() => setAnchorEl136(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setMedialMeniscus(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setMedialMeniscus(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setMedialMeniscusSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setMedialMeniscusSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{medialMeniscusSide} {medialMeniscus}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Lateral Meniscus</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange137(!change137); setLateralMeniscus("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl137(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change137 &&
                                                        <Popover
                                                            id={id137}
                                                            open={open137}
                                                            anchorEl={anchorEl137}
                                                            onClose={() => setAnchorEl137(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setLateralMeniscus(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setLateralMeniscus(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setLateralMeniscusSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setLateralMeniscusSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{lateralMeniscusSide} {lateralMeniscus}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Apley’s Test</h6>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Rotation with Distraction (ligament injury)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange138(!change138); setKneeLigamentInjury(""); setKneeLigamentInjurySide(""); setKneeMedialRotationLigament("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl138(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change138 &&
                                                        <Popover
                                                            id={id138}
                                                            open={open138}
                                                            anchorEl={anchorEl138}
                                                            onClose={() => setAnchorEl138(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeLigamentInjury(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeLigamentInjury(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneeLigamentInjurySide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneeLigamentInjurySide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Medial" type="radio" name="cough3" onChange={(e) => { setKneeMedialRotationLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Medial
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Lateral" type="radio" name="cough3" onChange={(e) => { setKneeMedialRotationLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Lateral
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeLigamentInjurySide} {kneeLigamentInjury} {kneeMedialRotationLigament}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Rotation with compression (meniscus injury)</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange139(!change139); setKneeMeniscusInjusy(""); setKneeMeniscusInjusySide(""); setKneeMedialRotationMeniscus("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl139(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change139 &&
                                                        <Popover
                                                            id={id139}
                                                            open={open139}
                                                            anchorEl={anchorEl139}
                                                            onClose={() => setAnchorEl139(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">

                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeMeniscusInjusy(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeMeniscusInjusy(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneeMeniscusInjusySide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneeMeniscusInjusySide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Medial" type="radio" name="cough3" onChange={(e) => { setKneeMedialRotationMeniscus(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Medial
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Lateral" type="radio" name="cough3" onChange={(e) => { setKneeMedialRotationMeniscus(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Lateral
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeMeniscusInjusySide} {kneeMeniscusInjusy} {kneeMedialRotationMeniscus}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-1">Ligament Test</h6>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Medial Ligament</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange142(!change142); setKneeMedialLigament(""); setKneeMedialLigamentSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl142(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change142 &&
                                                        <Popover
                                                            id={id142}
                                                            open={open142}
                                                            anchorEl={anchorEl142}
                                                            onClose={() => setAnchorEl142(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeMedialLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeMedialLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough11" onChange={(e) => { setKneeMedialLigamentSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough11" onChange={(e) => { setKneeMedialLigamentSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeMedialLigamentSide} {kneeMedialLigament}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Lateral Ligament</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange199(!change199); setKneeLateralLigament(""); setKneeLateralLigamentSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl199(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change199 &&
                                                        <Popover
                                                            id={id199}
                                                            open={open199}
                                                            anchorEl={anchorEl199}
                                                            onClose={() => setAnchorEl199(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeLateralLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeLateralLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough11" onChange={(e) => { setKneeLateralLigamentSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough11" onChange={(e) => { setKneeLateralLigamentSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeLateralLigamentSide} {kneeLateralLigament}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Anterior Cruciate</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange143(!change143); setKneeAnteriorCruciate(""); setKneeAnteriorCruciateSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl143(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change143 &&
                                                        <Popover
                                                            id={id143}
                                                            open={open143}
                                                            anchorEl={anchorEl143}
                                                            onClose={() => setAnchorEl143(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeAnteriorCruciate(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeAnteriorCruciate(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneeAnteriorCruciateSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneeAnteriorCruciateSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeAnteriorCruciateSide} {kneeAnteriorCruciate}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Sag Sign</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange145(!change145); setKneeSagSign(""); setKneeSagSignSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl145(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change145 &&
                                                        <Popover
                                                            id={id145}
                                                            open={open145}
                                                            anchorEl={anchorEl145}
                                                            onClose={() => setAnchorEl145(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeSagSign(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeSagSign(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneeSagSignSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneeSagSignSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p> {kneeSagSignSide} {kneeSagSign}</p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Post Cruciate Ligament</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange149(!change149); setKneePostCruciateLigament(""); setKneePostCruciateLigamentSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl149(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change149 &&
                                                        <Popover
                                                            id={id149}
                                                            open={open149}
                                                            anchorEl={anchorEl149}
                                                            onClose={() => setAnchorEl149(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">

                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneePostCruciateLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneePostCruciateLigament(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneePostCruciateLigamentSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneePostCruciateLigamentSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneePostCruciateLigamentSide} {kneePostCruciateLigament}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Wilson Test</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange146(!change146); setKneeWilsonTest(""); setKneeWilsonTestSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl146(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change146 &&
                                                        <Popover
                                                            id={id146}
                                                            open={open146}
                                                            anchorEl={anchorEl146}
                                                            onClose={() => setAnchorEl146(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeWilsonTest(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeWilsonTest(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneeWilsonTestSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneeWilsonTestSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeWilsonTestSide} {kneeWilsonTest}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Patella Apprehension</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange147(!change147); setKneePatellaApprehension(""); setKneePatellaApprehensionSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl147(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change147 &&
                                                        <Popover
                                                            id={id147}
                                                            open={open147}
                                                            anchorEl={anchorEl147}
                                                            onClose={() => setAnchorEl147(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                        >

                                                            <div className="left-popup">

                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneePatellaApprehension(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneePatellaApprehension(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneePatellaApprehensionSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneePatellaApprehensionSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneePatellaApprehensionSide} {kneePatellaApprehension}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-4">
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Noble Compression</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange148(!change148); setKneeNobleCompression(""); setKneeNobleCompressionSide("") }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl148(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change148 &&
                                                        <Popover
                                                            id={id148}
                                                            open={open148}
                                                            anchorEl={anchorEl148}
                                                            onClose={() => setAnchorEl148(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">

                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Positve" type="radio" name="cough1" onChange={(e) => { setKneeNobleCompression(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Positve
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Negative" type="radio" name="cough1" onChange={(e) => { setKneeNobleCompression(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Negative
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Left" type="radio" name="cough2" onChange={(e) => { setKneeNobleCompressionSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Left
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check ms-1">
                                                                            <input className="form-check-input" value="Right" type="radio" name="cough2" onChange={(e) => { setKneeNobleCompressionSide(e.target.value) }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                Right
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>{kneeNobleCompressionSide} {kneeNobleCompression}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact-mus6" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className="row">
                                <div className="col-4">
                                    <h6 className="my-6">Inspection (Look)</h6>
                                    <div className="ankle-scroll g-doc-scroll">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Inspection (Look)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange150(!change150); setFootInspectio("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl150(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change150 &&
                                                            <Popover
                                                                id={id150}
                                                                open={open150}
                                                                anchorEl={anchorEl150}
                                                                onClose={() => setAnchorEl150(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setFootInspectio(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Left
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setFootInspectio(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Right
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{footInspectio}</p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">MTP Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange152(!change152); setMTPJointNumber([]); setMTPJointSymptom([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl152(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change152 &&
                                                            <Popover
                                                                id={id152}
                                                                open={open152}
                                                                anchorEl={anchorEl152}
                                                                onClose={() => setAnchorEl152(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "180px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="toe1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setMTPJointNumber([...MTPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = MTPJointNumber.filter(item => item !== value)
                                                                                        setMTPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="toe2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setMTPJointNumber([...MTPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = MTPJointNumber.filter(item => item !== value)
                                                                                        setMTPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="toe3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setMTPJointNumber([...MTPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = MTPJointNumber.filter(item => item !== value)
                                                                                        setMTPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="toe4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setMTPJointNumber([...MTPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = MTPJointNumber.filter(item => item !== value)
                                                                                        setMTPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="toe5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setMTPJointNumber([...MTPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = MTPJointNumber.filter(item => item !== value)
                                                                                        setMTPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {
                                                                                MTPJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setMTPJointSymptom([...MTPJointSymptom, value])
                                                                                        } else {
                                                                                            const dataNe = MTPJointSymptom.filter(item => item !== value)
                                                                                            setMTPJointSymptom(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(MTPJointNumber).map((item, i) => <span key={i} className='me-1'>{MTPJointNumber[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(MTPJointSymptom).map((item, i) => <span key={i} className='me-1'>{MTPJointSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">PIP Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange153(!change153); setPIPJointNumber([]); setPIPJointSymptom([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl153(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change153 &&
                                                            <Popover
                                                                id={id153}
                                                                open={open153}
                                                                anchorEl={anchorEl153}
                                                                onClose={() => setAnchorEl153(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "180px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="toe1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPIPJointNumber([...PIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = PIPJointNumber.filter(item => item !== value)
                                                                                        setPIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="toe2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPIPJointNumber([...PIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = PIPJointNumber.filter(item => item !== value)
                                                                                        setPIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="toe3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPIPJointNumber([...PIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = PIPJointNumber.filter(item => item !== value)
                                                                                        setPIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="toe4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPIPJointNumber([...PIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = PIPJointNumber.filter(item => item !== value)
                                                                                        setPIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="toe5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setPIPJointNumber([...PIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = PIPJointNumber.filter(item => item !== value)
                                                                                        setPIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {
                                                                                PIPJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setPIPJointSymptom([...PIPJointSymptom, value])
                                                                                        } else {
                                                                                            const dataNe = PIPJointSymptom.filter(item => item !== value)
                                                                                            setPIPJointSymptom(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(PIPJointNumber).map((item, i) => <span key={i} className='me-1'>{PIPJointNumber[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(PIPJointSymptom).map((item, i) => <span key={i} className='me-1'>{PIPJointSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">DIP Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange154(!change154); setDIPJointNumber([]); setDIPJointSymptom([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl154(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change154 &&
                                                            <Popover
                                                                id={id154}
                                                                open={open154}
                                                                anchorEl={anchorEl154}
                                                                onClose={() => setAnchorEl154(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "180px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="toe1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setDIPJointNumber([...DIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = DIPJointNumber.filter(item => item !== value)
                                                                                        setDIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="toe2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setDIPJointNumber([...DIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = DIPJointNumber.filter(item => item !== value)
                                                                                        setDIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="toe3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setDIPJointNumber([...DIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = DIPJointNumber.filter(item => item !== value)
                                                                                        setDIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="toe4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setDIPJointNumber([...DIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = DIPJointNumber.filter(item => item !== value)
                                                                                        setDIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="toe5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setDIPJointNumber([...DIPJointNumber, value])
                                                                                    } else {
                                                                                        const dataNe = DIPJointNumber.filter(item => item !== value)
                                                                                        setDIPJointNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {
                                                                                DIPJointArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setDIPJointSymptom([...DIPJointSymptom, value])
                                                                                        } else {
                                                                                            const dataNe = DIPJointSymptom.filter(item => item !== value)
                                                                                            setDIPJointSymptom(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(DIPJointNumber).map((item, i) => <span key={i} className='me-1'>{DIPJointNumber[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(DIPJointSymptom).map((item, i) => <span key={i} className='me-1'>{DIPJointSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Toes</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange155(!change155); setToesSymptomNumber([]); setToesSymptomRight([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl155(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change155 &&
                                                            <Popover
                                                                id={id155}
                                                                open={open155}
                                                                anchorEl={anchorEl155}
                                                                onClose={() => setAnchorEl155(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "180px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="toe1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setToesSymptomNumber([...toesSymptomNumber, value])
                                                                                    } else {
                                                                                        const dataNe = toesSymptomNumber.filter(item => item !== value)
                                                                                        setToesSymptomNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="toe2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setToesSymptomNumber([...toesSymptomNumber, value])
                                                                                    } else {
                                                                                        const dataNe = toesSymptomNumber.filter(item => item !== value)
                                                                                        setToesSymptomNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="toe3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setToesSymptomNumber([...toesSymptomNumber, value])
                                                                                    } else {
                                                                                        const dataNe = toesSymptomNumber.filter(item => item !== value)
                                                                                        setToesSymptomNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="toe4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setToesSymptomNumber([...toesSymptomNumber, value])
                                                                                    } else {
                                                                                        const dataNe = toesSymptomNumber.filter(item => item !== value)
                                                                                        setToesSymptomNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="toe5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setToesSymptomNumber([...toesSymptomNumber, value])
                                                                                    } else {
                                                                                        const dataNe = toesSymptomNumber.filter(item => item !== value)
                                                                                        setToesSymptomNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            {
                                                                                toesSymptomArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setToesSymptomRight([...toesSymptomRight, value])
                                                                                        } else {
                                                                                            const dataNe = toesSymptomRight.filter(item => item !== value)
                                                                                            setToesSymptomRight(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(toesSymptomNumber).map((item, i) => <span key={i} className='me-1'>{toesSymptomNumber[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(toesSymptomRight).map((item, i) => <span key={i} className='me-1'>{toesSymptomRight[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Dorsum of foot</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange156(!change156); setDorsumSymptom([]); }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl156(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change156 &&
                                                            <Popover
                                                                id={id156}
                                                                open={open156}
                                                                anchorEl={anchorEl156}
                                                                onClose={() => setAnchorEl156(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    {
                                                                        dorsumSymptomArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                const { checked, value } = e.target;

                                                                                if (checked) {
                                                                                    setDorsumSymptom([...dorsumSymptom, value])
                                                                                } else {
                                                                                    const dataNe = dorsumSymptom.filter(item => item !== value)
                                                                                    setDorsumSymptom(dataNe)
                                                                                }
                                                                            }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(dorsumSymptom).map((item, i) => <span key={i} className='me-1'>{dorsumSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Plantar aspect of foot</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange157(!change157); setPlanterSymptom([]); }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl157(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change157 &&
                                                            <Popover
                                                                id={id157}
                                                                open={open157}
                                                                anchorEl={anchorEl157}
                                                                onClose={() => setAnchorEl157(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    {
                                                                        planterSymptomArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                const { checked, value } = e.target;

                                                                                if (checked) {
                                                                                    setPlanterSymptom([...planterSymptom, value])
                                                                                } else {
                                                                                    const dataNe = planterSymptom.filter(item => item !== value)
                                                                                    setPlanterSymptom(dataNe)
                                                                                }
                                                                            }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(planterSymptom).map((item, i) => <span key={i} className='me-1'>{planterSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Nail</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange158(!change158); setNailNumber([]); setNailSymptom([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl158(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change158 &&
                                                            <Popover
                                                                id={id158}
                                                                open={open158}
                                                                anchorEl={anchorEl158}
                                                                onClose={() => setAnchorEl158(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "180px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-4">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="toe1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setNailNumber([...nailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = nailNumber.filter(item => item !== value)
                                                                                        setNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="toe2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setNailNumber([...nailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = nailNumber.filter(item => item !== value)
                                                                                        setNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="toe3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setNailNumber([...nailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = nailNumber.filter(item => item !== value)
                                                                                        setNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="toe4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setNailNumber([...nailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = nailNumber.filter(item => item !== value)
                                                                                        setNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="toe5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setNailNumber([...nailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = nailNumber.filter(item => item !== value)
                                                                                        setNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            {
                                                                                nailSymptomArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                                    <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                        const { checked, value } = e.target;

                                                                                        if (checked) {
                                                                                            setNailSymptom([...nailSymptom, value])
                                                                                        } else {
                                                                                            const dataNe = nailSymptom.filter(item => item !== value)
                                                                                            setNailSymptom(dataNe)
                                                                                        }
                                                                                    }} id="flexRadioDefault1" />
                                                                                    <label className="form-check-label" >
                                                                                        {item.name}
                                                                                    </label>
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(nailNumber).map((item, i) => <span key={i} className='me-1'>{nailNumber[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(nailSymptom).map((item, i) => <span key={i} className='me-1'>{nailSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Feel (Palpation)</h6>
                                    <div className="ankle-scroll g-doc-scroll">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Feel (Palpation)</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange159(!change159); setFeelPalpation(""); setFeelPalpationToesLeft([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl159(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change159 &&
                                                            <Popover
                                                                id={id159}
                                                                open={open159}
                                                                anchorEl={anchorEl159}
                                                                onClose={() => setAnchorEl159(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setFeelPalpation(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label me-2" >
                                                                                    Left
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setFeelPalpation(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label me-2" >
                                                                                    Right
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Both" type="radio" name="cough1" onChange={(e) => { setFeelPalpation(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label me-2" >
                                                                                    Both
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationToesLeft([...feelPalpationToesLeft, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationToesLeft.filter(item => item !== value)
                                                                                        setFeelPalpationToesLeft(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationToesLeft([...feelPalpationToesLeft, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationToesLeft.filter(item => item !== value)
                                                                                        setFeelPalpationToesLeft(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationToesLeft([...feelPalpationToesLeft, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationToesLeft.filter(item => item !== value)
                                                                                        setFeelPalpationToesLeft(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationToesLeft([...feelPalpationToesLeft, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationToesLeft.filter(item => item !== value)
                                                                                        setFeelPalpationToesLeft(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationToesLeft([...feelPalpationToesLeft, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationToesLeft.filter(item => item !== value)
                                                                                        setFeelPalpationToesLeft(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{feelPalpation}</p>
                                                {
                                                    Object.keys(feelPalpationToesLeft).map((item, i) => <span key={i} className='me-1'>{feelPalpationToesLeft[item]}</span>)
                                                }
                                            </div>
                                        </div>

                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">MTP Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange161(!change161); setFeelPalpationMTPJoint(""); setFeelPalpationMTPJointSide("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl161(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change161 &&
                                                            <Popover
                                                                id={id161}
                                                                open={open161}
                                                                anchorEl={anchorEl161}
                                                                onClose={() => setAnchorEl161(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Left" type="radio" name="cough1" onChange={(e) => { setFeelPalpationMTPJointSide(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label me-2" >
                                                                                    Left
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Right" type="radio" name="cough1" onChange={(e) => { setFeelPalpationMTPJointSide(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label me-2" >
                                                                                    Right
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Both" type="radio" name="cough1" onChange={(e) => { setFeelPalpationMTPJointSide(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label me-2" >
                                                                                    Both
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationMTPJoint([...feelPalpationMTPJoint, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationMTPJoint.filter(item => item !== value)
                                                                                        setFeelPalpationMTPJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationMTPJoint([...feelPalpationMTPJoint, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationMTPJoint.filter(item => item !== value)
                                                                                        setFeelPalpationMTPJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationMTPJoint([...feelPalpationMTPJoint, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationMTPJoint.filter(item => item !== value)
                                                                                        setFeelPalpationMTPJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationMTPJoint([...feelPalpationMTPJoint, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationMTPJoint.filter(item => item !== value)
                                                                                        setFeelPalpationMTPJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationMTPJoint([...feelPalpationMTPJoint, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationMTPJoint.filter(item => item !== value)
                                                                                        setFeelPalpationMTPJoint(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{feelPalpationMTPJointSide}</p>
                                                {
                                                    Object.keys(feelPalpationMTPJoint).map((item, i) => <span key={i} className='me-1'>{feelPalpationMTPJoint[item]}</span>)
                                                }

                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">PIP Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange162(!change162); setFeelPalpationPIPJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl162(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change162 &&
                                                            <Popover
                                                                id={id162}
                                                                open={open162}
                                                                anchorEl={anchorEl162}
                                                                onClose={() => setAnchorEl162(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="1" type="checkbox" name="1" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationPIPJoint([...feelPalpationPIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationPIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationPIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            1
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="2" type="checkbox" name="2" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationPIPJoint([...feelPalpationPIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationPIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationPIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            2
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="3" type="checkbox" name="3" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationPIPJoint([...feelPalpationPIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationPIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationPIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            3
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="4" type="checkbox" name="4" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationPIPJoint([...feelPalpationPIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationPIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationPIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            4
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="5" type="checkbox" name="5" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationPIPJoint([...feelPalpationPIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationPIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationPIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            5
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                {
                                                    Object.keys(feelPalpationPIPJoint).map((item, i) => <span key={i} className='me-1'>{feelPalpationPIPJoint[item]}</span>)
                                                }

                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">DIP Joint</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange163(!change163); setFeelPalpationDIPJoint([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl163(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change163 &&
                                                            <Popover
                                                                id={id163}
                                                                open={open163}
                                                                anchorEl={anchorEl163}
                                                                onClose={() => setAnchorEl163(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="1" type="checkbox" name="1" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationDIPJoint([...feelPalpationDIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationDIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationDIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            1
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="2" type="checkbox" name="2" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationDIPJoint([...feelPalpationDIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationDIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationDIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            2
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="3" type="checkbox" name="3" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationDIPJoint([...feelPalpationDIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationDIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationDIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            3
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="4" type="checkbox" name="4" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationDIPJoint([...feelPalpationDIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationDIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationDIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            4
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="5" type="checkbox" name="5" onChange={(e) => {
                                                                            const { checked, value } = e.target;

                                                                            if (checked) {
                                                                                setFeelPalpationDIPJoint([...feelPalpationDIPJoint, value])
                                                                            } else {
                                                                                const dataNe = feelPalpationDIPJoint.filter(item => item !== value)
                                                                                setFeelPalpationDIPJoint(dataNe)
                                                                            }
                                                                        }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            5
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                {
                                                    Object.keys(feelPalpationDIPJoint).map((item, i) => <span key={i} className='me-1'>{feelPalpationDIPJoint[item]}</span>)
                                                }

                                            </div>
                                        </div>

                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Medial Malleolus Tender</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setFeelPalpationMedialMalleolus(!feelPalpationMedialMalleolus) }}
                                                            id="togBtn"
                                                        />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Lateral Malleolus Tender</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setFeelPalpationLateralMalleolus(!feelPalpationLateralMalleolus) }}
                                                            id="togBtn"
                                                        />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Dorsum of foot</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange165(!change165); setFeelPalpationTenderOver([]); setFeelPalpationSwollenOver([]); setTibialisPosteriorPluse(""); setArteriaDorsalisPedisPluse("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl165(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change165 &&
                                                            <Popover
                                                                id={id165}
                                                                open={open165}
                                                                anchorEl={anchorEl165}
                                                                onClose={() => setAnchorEl165(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div>
                                                                        <span>Tender - over </span>
                                                                        <div className="left-popup">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationTenderOver([...feelPalpationTenderOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationTenderOver.filter(item => item !== value)
                                                                                        setFeelPalpationTenderOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationTenderOver([...feelPalpationTenderOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationTenderOver.filter(item => item !== value)
                                                                                        setFeelPalpationTenderOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationTenderOver([...feelPalpationTenderOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationTenderOver.filter(item => item !== value)
                                                                                        setFeelPalpationTenderOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationTenderOver([...feelPalpationTenderOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationTenderOver.filter(item => item !== value)
                                                                                        setFeelPalpationTenderOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationTenderOver([...feelPalpationTenderOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationTenderOver.filter(item => item !== value)
                                                                                        setFeelPalpationTenderOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Flexor Tendon" type="checkbox" name="Flexor Tendon" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationTenderOver([...feelPalpationTenderOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationTenderOver.filter(item => item !== value)
                                                                                        setFeelPalpationTenderOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Flexor Tendon
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <span>Swollen - over </span>
                                                                        <div className="left-popup">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationSwollenOver([...feelPalpationSwollenOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationSwollenOver.filter(item => item !== value)
                                                                                        setFeelPalpationSwollenOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationSwollenOver([...feelPalpationSwollenOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationSwollenOver.filter(item => item !== value)
                                                                                        setFeelPalpationSwollenOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationSwollenOver([...feelPalpationSwollenOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationSwollenOver.filter(item => item !== value)
                                                                                        setFeelPalpationSwollenOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationSwollenOver([...feelPalpationSwollenOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationSwollenOver.filter(item => item !== value)
                                                                                        setFeelPalpationSwollenOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationSwollenOver([...feelPalpationSwollenOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationSwollenOver.filter(item => item !== value)
                                                                                        setFeelPalpationSwollenOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Flexor Tendon" type="checkbox" name="Flexor Tendon" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationSwollenOver([...feelPalpationSwollenOver, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationSwollenOver.filter(item => item !== value)
                                                                                        setFeelPalpationSwollenOver(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Flexor Tendon
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <span>Arteria dorsalis pedis Pluse</span>
                                                                        <div className="left-popup">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Palpable" type="radio" name="Palpable" onChange={(e) => { setArteriaDorsalisPedisPluse(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Palpable
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Not Palpable" type="radio" name="Palpable" onChange={(e) => { setArteriaDorsalisPedisPluse(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Not Palpable
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mt-1'>
                                                                        <span>Tibialis Posterior Pluse</span>
                                                                        <div className="left-popup">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Palpable" type="radio" name="Palpable" onChange={(e) => { setTibialisPosteriorPluse(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Palpable
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Not Palpable" type="radio" name="Palpable" onChange={(e) => { setTibialisPosteriorPluse(e.target.value) }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Not Palpable
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(feelPalpationTenderOver).map((item, i) => <span key={i} className='me-1'>{feelPalpationTenderOver[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(feelPalpationSwollenOver).map((item, i) => <span key={i} className='me-1'>{feelPalpationSwollenOver[item]}</span>)
                                                    }
                                                </p>
                                                <p>{arteriaDorsalisPedisPluse}</p>
                                                <p>{tibialisPosteriorPluse}</p>

                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Plantar aspect of foot</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange166(!change166); setPlanterAspectOfFoot([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl166(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change166 &&
                                                            <Popover
                                                                id={id166}
                                                                open={open166}
                                                                anchorEl={anchorEl166}
                                                                onClose={() => setAnchorEl166(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "180px" }} className="left-popup">
                                                                    {
                                                                        planterAspectOfFootArray.map((item, i) => <div key={i} className="form-check ms-1">
                                                                            <input className="form-check-input" value={item.name} type="checkbox" name={item.name} onChange={(e) => {
                                                                                const { checked, value } = e.target;

                                                                                if (checked) {
                                                                                    setPlanterAspectOfFoot([...planterAspectOfFoot, value])
                                                                                } else {
                                                                                    const dataNe = planterAspectOfFoot.filter(item => item !== value)
                                                                                    setPlanterAspectOfFoot(dataNe)
                                                                                }
                                                                            }} id="flexRadioDefault1" />
                                                                            <label className="form-check-label" >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>)
                                                                    }
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(planterAspectOfFoot).map((item, i) => <span key={i} className='me-1'>{planterAspectOfFoot[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Nail</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange168(!change168); setFeelPalpationNailSymptom([]); setFeelPalpationNailNumber([]); setFeelPalpationNailSide([]) }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl168(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change168 &&
                                                            <Popover
                                                                id={id168}
                                                                open={open168}
                                                                anchorEl={anchorEl168}
                                                                onClose={() => setAnchorEl168(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div style={{ width: "220px" }} className="left-popup">
                                                                    <div className="row">
                                                                        <div className="col-3">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Left" type="checkbox" name="Left" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailSide([...feelPalpationNailSide, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailSide.filter(item => item !== value)
                                                                                        setFeelPalpationNailSide(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Left
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Right" type="checkbox" name="Right" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailSide([...feelPalpationNailSide, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailSide.filter(item => item !== value)
                                                                                        setFeelPalpationNailSide(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Right
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="1" type="checkbox" name="toe1" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailNumber([...feelPalpationNailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailNumber.filter(item => item !== value)
                                                                                        setFeelPalpationNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    1
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="2" type="checkbox" name="toe2" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailNumber([...feelPalpationNailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailNumber.filter(item => item !== value)
                                                                                        setFeelPalpationNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    2
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="3" type="checkbox" name="toe3" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailNumber([...feelPalpationNailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailNumber.filter(item => item !== value)
                                                                                        setFeelPalpationNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    3
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="4" type="checkbox" name="toe4" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailNumber([...feelPalpationNailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailNumber.filter(item => item !== value)
                                                                                        setFeelPalpationNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    4
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="5" type="checkbox" name="toe5" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailNumber([...feelPalpationNailNumber, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailNumber.filter(item => item !== value)
                                                                                        setFeelPalpationNailNumber(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    5
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Swelling" type="checkbox" name="Swelling" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailSymptom([...feelPalpationNailSymptom, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailSymptom.filter(item => item !== value)
                                                                                        setFeelPalpationNailSymptom(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Swelling
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check ms-1">
                                                                                <input className="form-check-input" value="Redness" type="checkbox" name="Redness" onChange={(e) => {
                                                                                    const { checked, value } = e.target;

                                                                                    if (checked) {
                                                                                        setFeelPalpationNailSymptom([...feelPalpationNailSymptom, value])
                                                                                    } else {
                                                                                        const dataNe = feelPalpationNailSymptom.filter(item => item !== value)
                                                                                        setFeelPalpationNailSymptom(dataNe)
                                                                                    }
                                                                                }} id="flexRadioDefault1" />
                                                                                <label className="form-check-label" >
                                                                                    Redness
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>
                                                    {
                                                        Object.keys(feelPalpationNailSide).map((item, i) => <span key={i} className='me-1'>{feelPalpationNailSide[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(feelPalpationNailNumber).map((item, i) => <span key={i} className='me-1'>{feelPalpationNailNumber[item]}</span>)
                                                    }
                                                </p>
                                                <p>
                                                    {
                                                        Object.keys(feelPalpationNailSymptom).map((item, i) => <span key={i} className='me-1'>{feelPalpationNailSymptom[item]}</span>)
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Tender</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setFeelPalpationTender(!feelPalpationTender) }}
                                                            id="togBtn"
                                                        />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Warm</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setFeelPalpationWarm(!feelPalpationWarm) }}
                                                            id="togBtn"
                                                        />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Pitting oedema</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setFeelPalpationPittingOedema(!feelPalpationPittingOedema) }}
                                                            id="togBtn"
                                                        />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <h6 className="my-1">Move</h6>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Dorsi Flexion <i className="far fa-angle-left"></i> 20&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange167(!change167); setDorsiFlexionMove([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl167(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change167 &&
                                                        <Popover
                                                            id={id167}
                                                            open={open167}
                                                            anchorEl={anchorEl167}
                                                            onClose={() => setAnchorEl167(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Inversion" type="checkbox" name="Inversion" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setDorsiFlexionMove([...dorsiFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = dorsiFlexionMove.filter(item => item !== value)
                                                                            setDorsiFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Inversion
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Normal" type="checkbox" name="Normal" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setDorsiFlexionMove([...dorsiFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = dorsiFlexionMove.filter(item => item !== value)
                                                                            setDorsiFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Normal
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Tender" type="checkbox" name="Tender" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setDorsiFlexionMove([...dorsiFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = dorsiFlexionMove.filter(item => item !== value)
                                                                            setDorsiFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Tender
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Restricted" type="checkbox" name="Restricted" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setDorsiFlexionMove([...dorsiFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = dorsiFlexionMove.filter(item => item !== value)
                                                                            setDorsiFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Restricted
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>
                                                {
                                                    Object.keys(dorsiFlexionMove).map((item, i) => <p key={i} className='me-1'>{dorsiFlexionMove[item]}</p>)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Plantar Flexion <i className="far fa-angle-left"></i> 50&#176;</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange169(!change169); setPlanterFlexionMove([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl169(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change169 &&
                                                        <Popover
                                                            id={id169}
                                                            open={open169}
                                                            anchorEl={anchorEl169}
                                                            onClose={() => setAnchorEl169(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Inversion" type="checkbox" name="Inversion" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setPlanterFlexionMove([...planterFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = planterFlexionMove.filter(item => item !== value)
                                                                            setPlanterFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Inversion
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Normal" type="checkbox" name="Normal" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setPlanterFlexionMove([...planterFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = planterFlexionMove.filter(item => item !== value)
                                                                            setPlanterFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Normal
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Tender" type="checkbox" name="Tender" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setPlanterFlexionMove([...planterFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = planterFlexionMove.filter(item => item !== value)
                                                                            setPlanterFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Tender
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Restricted" type="checkbox" name="Restricted" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setPlanterFlexionMove([...planterFlexionMove, value])
                                                                        } else {
                                                                            const dataNe = planterFlexionMove.filter(item => item !== value)
                                                                            setPlanterFlexionMove(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Restricted
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>
                                                {
                                                    Object.keys(planterFlexionMove).map((item, i) => <p key={i} className='me-1'>{planterFlexionMove[item]}</p>)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="exam-bg-white p-1 mt-2">
                                        <div className="d-flex justify-content-between">
                                            <p className="w-75">Achilles Tendon</p>
                                            <div className="ms-1">
                                                <label className="switch me-1">
                                                    <input
                                                        name="Jaundiced"
                                                        value="Jaundiced"
                                                        type="checkbox"
                                                        onChange={(e) => { setChange170(!change170); setAchilleTendonType([]); setAchilleTendon([]) }}
                                                        id="togBtn"
                                                    />
                                                    <div onClick={(e) => setAnchorEl170(e.currentTarget)} className="slider round"></div>
                                                    {
                                                        change170 &&
                                                        <Popover
                                                            id={id170}
                                                            open={open170}
                                                            anchorEl={anchorEl170}
                                                            onClose={() => setAnchorEl170(null)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                        >

                                                            <div className="left-popup">
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Swollen" type="checkbox" name="Swollen" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setAchilleTendonType([...achilleTendonType, value])
                                                                        } else {
                                                                            const dataNe = achilleTendonType.filter(item => item !== value)
                                                                            setAchilleTendonType(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Swollen
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Tender" type="checkbox" name="Tender" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setAchilleTendonType([...achilleTendonType, value])
                                                                        } else {
                                                                            const dataNe = achilleTendonType.filter(item => item !== value)
                                                                            setAchilleTendonType(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Tender
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Left" type="checkbox" name="Left" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setAchilleTendon([...achilleTendon, value])
                                                                        } else {
                                                                            const dataNe = achilleTendon.filter(item => item !== value)
                                                                            setAchilleTendon(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Left
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-1">
                                                                    <input className="form-check-input" value="Right" type="checkbox" name="Right" onChange={(e) => {
                                                                        const { checked, value } = e.target;

                                                                        if (checked) {
                                                                            setAchilleTendon([...achilleTendon, value])
                                                                        } else {
                                                                            const dataNe = achilleTendon.filter(item => item !== value)
                                                                            setAchilleTendon(dataNe)
                                                                        }
                                                                    }} id="flexRadioDefault1" />
                                                                    <label className="form-check-label" >
                                                                        Right
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="history-popup-value">
                                            <p>
                                                {
                                                    Object.keys(achilleTendonType).map((item, i) => <p key={i} className='me-1'>{achilleTendonType[item]}</p>)
                                                }
                                            </p>
                                            <p>
                                                {
                                                    Object.keys(achilleTendon).map((item, i) => <p key={i} className='me-1'>{achilleTendon[item]}</p>)
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <h6 className="my-">Test</h6>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Plantar Reflex</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange171(!change171); setPlanterReflex("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl171(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change171 &&
                                                            <Popover
                                                                id={id171}
                                                                open={open171}
                                                                anchorEl={anchorEl171}
                                                                onClose={() => setAnchorEl171(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Present" type="radio" name="Present" onChange={(e) => { setPlanterReflex(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Present
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Absent" type="radio" name="Present" onChange={(e) => { setPlanterReflex(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Absent
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{planterReflex} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">Ankle Jerk</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange172(!change172); setAnkleJerk("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl172(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change172 &&
                                                            <Popover
                                                                id={id172}
                                                                open={open172}
                                                                anchorEl={anchorEl172}
                                                                onClose={() => setAnchorEl172(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="0" type="radio" name="0" onChange={(e) => { setAnkleJerk(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            0
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="1" type="radio" name="0" onChange={(e) => { setAnkleJerk(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            1
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="+" type="radio" name="0" onChange={(e) => { setAnkleJerk(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            +
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="++" type="radio" name="0" onChange={(e) => { setAnkleJerk(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            ++
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="+++" type="radio" name="0" onChange={(e) => { setAnkleJerk(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            +++
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="++++" type="radio" name="0" onChange={(e) => { setAnkleJerk(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            ++++
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{ankleJerk} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <span>Sensation : Over :</span>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">S 1</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange173(!change173); setAnkleS1("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl173(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change173 &&
                                                            <Popover
                                                                id={id173}
                                                                open={open173}
                                                                anchorEl={anchorEl173}
                                                                onClose={() => setAnchorEl173(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Present" type="radio" name="Present" onChange={(e) => { setAnkleS1(e.target.value) }} id="pres1" />
                                                                        <label className="form-check-label" >
                                                                            Present
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Absent" type="radio" name="Present" onChange={(e) => { setAnkleS1(e.target.value) }} id="pres1" />
                                                                        <label className="form-check-label" >
                                                                            Absent
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{ankleS1} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">L 4</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange174(!change174); setAnkleL4("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl174(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change174 &&
                                                            <Popover
                                                                id={id174}
                                                                open={open174}
                                                                anchorEl={anchorEl174}
                                                                onClose={() => setAnchorEl174(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Present" type="radio" name="Present" onChange={(e) => { setAnkleL4(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Present
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Absent" type="radio" name="Present" onChange={(e) => { setAnkleL4(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Absent
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{ankleL4} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="exam-bg-white p-1 mt-2">
                                            <div className="d-flex justify-content-between">
                                                <p className="w-75">L 5</p>
                                                <div className="ms-1">
                                                    <label className="switch me-1">
                                                        <input
                                                            name="Jaundiced"
                                                            value="Jaundiced"
                                                            type="checkbox"
                                                            onChange={(e) => { setChange175(!change175); setAnkleL5("") }}
                                                            id="togBtn"
                                                        />
                                                        <div onClick={(e) => setAnchorEl175(e.currentTarget)} className="slider round"></div>
                                                        {
                                                            change175 &&
                                                            <Popover
                                                                id={id175}
                                                                open={open175}
                                                                anchorEl={anchorEl175}
                                                                onClose={() => setAnchorEl175(null)}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >

                                                                <div className="left-popup">
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Present" type="radio" name="Present" onChange={(e) => { setAnkleL5(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Present
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-1">
                                                                        <input className="form-check-input" value="Absent" type="radio" name="Present" onChange={(e) => { setAnkleL5(e.target.value) }} id="flexRadioDefault1" />
                                                                        <label className="form-check-label" >
                                                                            Absent
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Popover>
                                                        }
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="history-popup-value">
                                                <p>{ankleL5} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Masculo;