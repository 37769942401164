import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./GreatDoc.css";
import { LiaTimesCircle } from "react-icons/lia";
import axios from "axios";
import { NewModal as ReactModal } from "../../../common/components/NewModal";
const VitalSignModal = (props) => {
  const customStyles = {
    content: {
      top: "32%",
      left: "25%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "400px",
      padding: "10px",
      zIndex: "3000",
    },
  };
  const [vitalSetup, setVitalSetup] = useState([]);
  useEffect(() => {
    Modal.setAppElement("body");
    axios.get('vital-sign').then((res) => {
      setVitalSetup(res?.data?.vitalSign)
    })
  }, []);
  console.log(props?.vitalSign, "vitalSign00")
  return (
    <div className="vital-modal">
      <ReactModal size="lg" isOpen={props.modalIsOpen} onClose={props.closeModal}>
        <ReactModal.Header onClose={props.closeModal}>
          <ReactModal.Title>
            Patients Vital Signs
          </ReactModal.Title>
        </ReactModal.Header>
        <ReactModal.Body>
          <div style={{ minHeight: "400px" }}>
            <div className=" all-vital-sign-container">
              {props?.vitalSign?.length > 0 ? (
                <table className="vital-sign-modal-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      {
                        vitalSetup.map((item, i) => {
                          return (
                            <th key={i}>{item.name}</th>
                          )
                        })
                      }

                    </tr>
                  </thead>
                  <tbody>
                    {
                      props?.vitalSign?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.date}</td>
                            {
                              item?.vital_signs?.map((vt, i) => <td key={i}>{vt?.value ? vt?.value : "-"}</td>
                              )
                            }

                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>

              ) : (
                <h6 className="text-danger text-center">
                  No vital sign added !
                </h6>
              )}
            </div>
          </div>
        </ReactModal.Body>
      </ReactModal>
      {/* <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <span
          className="float-end"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={props.closeModal}
        >
          <LiaTimesCircle size={20} />
        </span>
        <h6 className="card-title">Patients Vital Signs</h6>
        <div className="card-body">
          <div className="vital-patient-container">
            <div className="my-vital-signs mt-3">
              <div className="row all-vital-sign-container">

                {props?.vitalSign?.length > 0 ? (
                  <table className="vital-sign-modal-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        {
                          vitalSetup.map((item, i) => {
                            return (
                              <th key={i}>{item.name}</th>
                            )
                          })
                        }
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        props?.vitalSign?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.date}</td>
                              {
                                item?.vital_signs?.map((vt, i) => <td key={i}>{vt?.value ? vt?.value : "-"}</td>
                                )
                              }
                              <td></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>

                ) : (
                  <h6 className="text-danger text-center">
                    No vital sign added !
                  </h6>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default VitalSignModal;
