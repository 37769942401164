import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import BillingSetupSidebar from '../../admin_setup_billing/billing_setup_sidebar/BillingSetUpSidebar';
import MaterialTable from 'material-table';
import Swal from "sweetalert2";


class Procedure extends Component {

    // pass data to table row
    state = {
        ProcedureName: [],
        loading: true,
    }


    // get all data from api
    async componentDidMount() {

        const res = await axios.get('/procedure-name');
        console.log(res);
        if (res.data.status === 200) {
            this.setState({
                ProcedureName: res.data.procedureName,
                loading: false,
            });
        }
    }



    deleteProcedureName = async (e, id) => {

        e.preventDefault();
        const thisClicked = e.currentTarget;

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/delete-procedure-name/${id}`).then(res => {
                    if (res.data.status === 200) {
                        thisClicked.closest("tr").remove();
                    }
                });
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                )
            }
        })

    }

    columns = [
        {
            title: "SL", field: "", render: (row) => <div>{row.tableData.id + 1}</div>,
            cellStyle: {
                maxWidth: 40
            },
            
        },
        {
            title: "Procedure Name", field: `procedure_name`

            , cellStyle: {
                width: 600
            },
        },
        {
            title: "Accounts", render: (row) => <div>{row.accounts?.name}</div>,

            cellStyle: {
                width: 200
            },
        },
        {
            title: "Accounts Type", render: (row) => <div>{row.accounts_type?.name}</div>

            , cellStyle: {
                width: 200
            },
        },
        // {
        //     title: "Accounts Group", render: (row) => <div>{row.accounts_group?.fee_name}</div>

        //     , cellStyle: {
        //         width: 600,
        //         marginLeft: 50
        //     },
        // },
       

        {
            title: "Action", field: "patient", render: (row) => <div><Link to={`/edit-procedure-name/${row.id}`} class="btn btn-info btn-sm action-btn"><i class="fas fa-edit"></i></Link>&nbsp;
                <button onClick={(e) => this.deleteProcedureName(e, row.id)} className="btn btn-danger btn-sm action-btn"> <i class="fas fa-trash"></i> </button></div>
        },
    ];

    render() {
        var html_table = "";
        if (this.state.loading) {
            html_table = <tr>
                <td>Loading..</td>
            </tr>
        }

        return (
            <>

                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <BillingSetupSidebar />
                        </div>
                        <div className="col-md-9 mt-2">
                            <div className='card'>
                                <div className="card-header">
                                    <h6 className="card-title">Procedure Name
                                        <Link to='/add-procedure-name' className="btn btn-primary btn-sm float-end"> Add </Link>
                                    </h6>
                                </div>
                                <div className="card-body">

                                    {/* <table id="categories" className="table dataTable no-footer dtr-inline">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Frequency Name</th>
                                                <th>Created At</th>
                                                <th>Updated At</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {html_table}
                                        </tbody>
                                    </table> */}
                                    <MaterialTable
                                        columns={this.columns}
                                        data={this.state.ProcedureName}
                                        options={{
                                            search: true,
                                            // filtering: filter,
                                            showTitle: false,
                                            searchFieldAlignment: "left",
                                            pageSize: 5,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [5, 10, 20, 50, 100]
                                        }}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>

        );
    }
}

export default Procedure;



