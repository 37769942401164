import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import GastroIntestinalSidebar from '../gastro_int_setup_mainsidebar/GastroIntestinalSidebar';

function AddHandAndFinger() {

    const [handandfinger, sethandandfinger] = useState({
        handandfinger_name: "",
        error_list: [],
    });

    const handleInput = (e) => {
        sethandandfinger({
            ...handandfinger, [e.target.name]: e.target.value
        });
    }


    const formData = new FormData();

    formData.append('handandfinger_name', handandfinger.handandfinger_name);

    const submitHandAndFinger = (e) => {
        // alert("hgfdhgf")
        e.preventDefault();
        console.log(e)
        axios.post(`/save-hand-and-finger`, formData).then(res => {
            if (res.data.status == 200) {
                swal("Success", res.data.message, "success");
                sethandandfinger({
                    handandfinger_name: "",
                    error_list: [],

                });

            }
            else if (res.data.status == 400) {
                sethandandfinger({ ...handandfinger, error_list: res.data.errors });

            }

        })
    }

    return (
        <div>
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <GastroIntestinalSidebar />
                        </div>
                        <div className="col-md-9 mt-2">
                            <div className="card">
                                <div className="card-header">
                                    <h6 className="card-title"> Add Hand And Finger
                                        <Link to={'/hand-and-finger'} className="btn btn-primary btn-sm float-end"> Back </Link>
                                    </h6>
                                </div>
                                <div className="card-body">
                                    <form id="HandAndFingerForm" onSubmit={submitHandAndFinger}>
                                        <div className="card-body">

                                            <div className="col-md-12">

                                                <div className="form-group">
                                                    <label htmlFor="history_name" className="col-form-label-sm"> Name </label>
                                                    <input type="text" value={handandfinger.handandfinger_name} className="form-control" name="handandfinger_name" onChange={handleInput} />
                                                    <span className="text-danger">{handandfinger.error_list.handandfinger_name}</span>

                                                </div>

                                                <div className="float-right">
                                                    <button type="submit" className="btn btn-sm btn-primary text-uppercase float-end mt-2" ><i
                                                        className="fas fa-save"  ></i> Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        </div>
    )
}

export default AddHandAndFinger;
