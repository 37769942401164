

export default function TermsAndConditions({ setTermsModelOpen }) {
    return (
        <>
            <span className='float-end' style={{ fontSize: "15px", cursor: "pointer", marginTop: "-5px" }} onClick={() => setTermsModelOpen(false)}><i className="fal fa-times"></i></span>
            <h2 className="text-center lead fw-semibold">Terms & Conditions</h2>

            <hr className='top-hr' />
            <div className="row">
                <div className="col-12 px-3 condition-privacy">
                    <h5>
                        Date of Last Revision: June 21, 2023
                    </h5>
                    <p>
                        Macrohealthplus software Pty Limited is a Limited Liability company with a registered office at 9 Westbrook Avenue Wahroonga, NSW 2076, Australia that manages <a target="_blank" href="https://www.macrohealthplus.com">www.macrohealthplus.org</a> (the “Website”), an international software company dealing with healthcare software around the world to customers. This document comprises the Terms of Use, hereinafter referred to as the “Agreement”, and constitutes a legally binding agreement between you, the customer (healthcare provider, doctors, patients)  as defined herein, and Macrohealthplus.
                        By (a) using the Website as a Customer or registering as a Customers on the Website or (b) clicking on a button or link indicating your acceptance thereof, you understand and hereby agree to the terms of the Agreement. You also confirm that you have the designated authority to act on behalf of the companies. Macrohealthplus reserves the right to change or modify these terms at any time and in our sole discretion. If the company makes changes to these terms, we will provide you with notice of such changes, such as by sending an email, posting a notice on our Website or updating the date at the top of the Agreement. Your continued use of the Website or our services will confirm your acceptance of the revised terms.
                        Macrohealthplus and companies shall jointly be referred to as “Parties' ' and separately as “Party' '.
                        WHEREAS,
                    </p>
                    <ol className="mx-3" type="A">
                        <li>
                            Macrohealthplus will list the Software details and features of its website for Customers to research and find information.
                        </li>
                        <li>
                            The Customer may use the Website to find information and submit inquiries to the Macrohealthplus for software services and support.
                        </li>
                    </ol>
                    <p className="my-2">NOW THEREFORE, in consideration of the mutual promises and covenants set forth in this </p>
                    <p>Agreement, the Parties hereby agree as follows:</p>
                    <p>Definitions and Interpretation </p>
                    <p>In this Agreement, unless the context requires otherwise, the following terms shall have the following meanings:</p>
                    <p>
                        “Customer” means a software user including, but not limited to, hospital, clinic, doctor, caregiver, rehabilitation / physiotherapy center, dialysis clinic, wellness center, spa center, pharmacy or e-pharmacy, lab services, medical tourism agent/facilitator or diagnostic center. The term shall also include an administrator of a customer’s account on the Website. <br />
                        “Customer” means those visitors to or users of the Website and/or individuals who contact Macrohealthplus seeking information on our software Services. Unless expressly specified otherwise, reference to a Customer shall also include the relatives, guardian, or other agent of a Customer who uses the Website or communicates with Macrohealthplus on behalf of the Company. <br />
                        “Customer” shall refer to the healthcare, medicinal, surgical, therapeutic, or other services to be provided by a Health Customer. <br />
                        "Intellectual Property Rights" means copyrights, trademarks, service marks, trade dress, trade secrets, publicity rights, database rights, patents, and other intellectual property rights or proprietary rights recognized by law. <br />
                        “Rates” means the fees charged by the MacroHealthplus to the Customer for the Software Services. <br />
                        In this Agreement (unless the context requires otherwise):
                    </p>
                    <ol className="ms-3" type="a">
                        <li>
                            Reference to the singular includes a reference to the plural and vice versa, and reference to any gender includes a reference to all other genders;
                        </li>
                        <li>Reference to natural persons includes reference to legal persons;</li>
                        <li>
                            Reference to statutory provisions shall be construed as meaning and including references also to any amendment or re-enactment (whether before or after the date of this Agreement) for the time being in force and to all statutory instruments or orders made pursuant to statutory provisions;
                        </li>
                        <li>
                            Reference to any Article, Clause, Schedule or Annexure shall be deemed to be a reference to such Article, Clause, Schedule or Annexure of or to this Agreement.
                        </li>
                    </ol>
                    <br />
                    <ol className="ms-3">
                        <li>
                            <b>Websites Terms</b> <br />
                            <p>Subject to compliance with this Agreement, Macrohealthplus grants the Customer a limited, non-exclusive license to use the Website and services for commercial use. To the fullest extent permitted by applicable law, this license granted to use is non-transferable. The Customer may not copy (except as expressly permitted by this license and any other applicable terms, conditions, or usage rules), decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Website or the content therein. The Customer is responsible for maintaining the secrecy of their passwords, login name/user-id and account information at all times and shall be directly responsible for any and all use of the Website by anyone using their login information and password whether with or without their permission. <br />
                                WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE ACCURATE CONTENT, WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO ACCURACY OF THE DATA PROVIDED BY THE CUSTOMERS, OR ANY OTHER CONTENT AVAILABLE THROUGH THE SERVICES. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON ANY SUCH CONTENT.
                            </p>
                        </li>
                        <li>
                            <b>Intellectual Property</b> <br />
                            <p>All Intellectual Property Rights in and to the Website and its entire contents, features and functionality (including but not limited to all information, software, code, algorithms, database, text, displays, images, video and audio, and the design, selection and arrangement thereof) are owned by Macrohealthplus, its suppliers, and licensors. Macrohealthplus reserves all rights not expressly granted to you. <br />
                                All rights in and to the Customer’s intellectual property not expressly granted to Macrohealthplus in this Agreement are reserved by Customer.
                            </p>
                        </li>
                        <li>
                            <b>Arrangement</b>
                            <br />
                            <p>
                                Macrohealthplus shall list the Customers and Healthcare Services on its Website and enable the Customers to find a Customer and details for the Healthcare Services required. The Customer may offer its designated facilities and medical care and treatment including health checks, diagnosis, tests, investigations, surgeries, facilitation of health and travel services, dispensation of medicines in its super and subspecialties to the Customers using the Website. Customer may list special packages on the Website or provide discount codes and the Customer shall ensure these packages are properly fulfilled and discount codes are honored.
                            </p>
                        </li>
                        <li>
                            <b>Grant of Rights</b> <br />
                            <p>
                                Subject to the terms of the Agreement, Macrohealthplus grants to the Customer and they accept a non-exclusive, non-transferable, limited right to have access to and to use the Website and Services for the term of the Agreement. <br />
                                The aforementioned right does not extend to: (a) make the Service, in whole or in part, available to any other person, entity or business; (b) modify the contents of the Service and the Website or use such content for any commercial purpose, or any public display, performance, sale or rental other than envisaged in the Agreement; (c) copy, reverse engineer, decompile or disassemble the Service or the Website, in whole or in part, or otherwise attempt to discover the source code to the software used; or (d) modify or combine the System with any other services not provided or approved by Macrohealthplus.
                            </p>
                        </li>
                        <li>
                            <b>Term of Agreement</b>
                            <br />
                            <p><span className="text-danger">
                                The initial term of this Agreement shall be for a period of two (2) years, commencing from the Effective Date unless terminated earlier as provided under this Agreement. Thereafter, the Agreement shall be renewed automatically for successive one (1) year term(s) unless either Party intimates its intention for non-renewal by providing a thirty (30) day notice to the other Party before the expiry of any renewed term thereof.
                            </span></p>
                        </li>
                        <li>
                            <b>Account Registration</b> <br />
                            <p>
                                The Customer must register an Account with Macrohealthplus in order to use the Services. Macrohealthplus may assist the Customer in the registration procedures or can even create the account and profiles on behalf of the Customer, if requested, and send the link to verify the account by email. In the situation in which the Customer does not provide any information and requests Macrohealthplus to identify the necessary or relevant types of information in order to complete the profile details, the Customer takes full responsibility for any wrong or inaccurate information filled in by Macrohealthplus in the account. The Customer has the obligation to check and eventually correct any inaccurate information. The Customer acknowledges that the account is personal or proprietary to the Customer or their practice and that they are expected to treat the account as confidential. The Customer agrees to provide accurate, current and complete information about the practice and Customer’s credentials as prompted by the registration and profile forms. <br />
                                By establishing an account with Macrohealthplus, the Customer represents that they are at least 18 years of age (in case of a doctor), or of the legal age of majority where the Customer resides if that jurisdiction has an older age of majority.

                            </p>
                        </li>
                        <li>
                            Access to the System and Use of Services
                            <ol type="i" className="mx-3">
                                <li>
                                    Verification <br />
                                    Customers agree that their receipt of Services is subject to verification by Macrohealthplus of their identity and credentials as a healthcare practitioner and to their ongoing qualification as such. <br />
                                    As part of the registration process and at any time thereafter, Customer may be required to provide Macrohealthplus with various information such as licenses, qualifications, area of expertise, accreditations and other information in order to prove their credentials as a valid healthcare practitioner in the field they claim ("Credential Information''). Macrohealthplus may verify such Credential Information or may ask the Customer for additional information. Macrohealthplus may also make enquiries from third parties to verify the authenticity of their Credential Information. Customer authorizes Macrohealthplus to make such enquiries from such third parties, and Customer agrees to hold them and Macrohealthplus harmless from any claim or liability arising from the request for or disclosure of such information. Customers agree that Macrohealthplus may suspend or terminate their access to or use of the Services at any time if they are unable at any time to determine or verify their Credential Information. Macrohealthplus reserves the right to carry out re-verification of Credential Information as and when required, and the above rights and commitments will extend to re-verification as well. <br />
                                    Customers shall also keep their Credential Information updated and will inform Macrohealthplus immediately should any portion of their Credential Information be revoked, canceled or expire.
                                </li>
                                <li>
                                    Safeguards <br />
                                    The Customer will implement and maintain appropriate administrative, physical and technical safeguards to protect their account from access, use, or alteration.
                                </li>
                                <li>
                                    No Third Party Access <br />
                                    Customers will not permit any third party to have access to or to use the account or the Services without Macrohealthplus’s prior written consent. Customer will not allow any third party to access the account or provide information to Customers on the Website. Customers will promptly notify Macrohealthplus of any order or demand for compulsory disclosure of disrupted  information if the disclosure requires access to or use of the account or Services.
                                </li>
                                <li>
                                    Submitted Content <br />
                                    Macrohealthplus does not claim ownership of any materials the Customer makes available through the Website, including contents. At Macrohealthplus's sole discretion, such materials may be included in the Service in whole or in part or in a modified form. With respect to such materials the Customer submits or makes available for inclusion on the Website, they grant Macrohealthplus a non-perpetual, terminable upon end of contractual relationship, worldwide, royalty-free and non-exclusive license to use, copy, distribute, publicly display, modify, create derivative works, and sublicense such materials or any part of such materials (as well as use the name that you submit in connection with such submitted content). The Customer hereby represents, warrants and covenants that any materials provided do not include anything (including, but not limited to, text, images, music or video) to which they do not have the full right to grant the license. Macrohealthplus takes no responsibility and assumes no liability for any submitted content posted or submitted by the Customer.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Scope <br />
                            <ol type="A" className="mx-3">
                                <li>The scope of service of each Party shall be as defined below with the role of Macrohealthplus being primarily to present the list and details of Customers to the Customers and to enable the Customers to contact the Customers to receive information and/or estimates for the Services offered.</li>
                                <li>

                                    The specific obligations of each Party shall be as follows:
                                    <ol type="i" className="mx-3 ">
                                        <li>Duties and obligations of the Customer:
                                            <ol type="A" className="ms-3 ">
                                                <li><span style={{
                                                    textDecoration: "underline",
                                                }}>General</span> <br /> <span >Customer shall:</span>
                                                    <ol type="a" className="ms-3">
                                                        <li>Provide Customers with timely and accurate information regarding the Healthcare Services and any attendant risks;</li>
                                                        <li>
                                                            Maintain as confidential and keep secure all information regarding a Customer and any other data received from Macrohealthplus, and not disclose or use any information regarding a Customer or any other data received from Macrohealthplus other than for the purposes of this Agreement or the provision of the relevant Healthcare Services;
                                                        </li>
                                                        <li>
                                                            Comply with all applicable privacy and information laws and regulations so far as they relate to the Customer's collection of Customer’s personal and health information
                                                        </li>
                                                        <li>
                                                            Have no objection to the display of its name on any advertisement or publicity material;
                                                        </li>
                                                        <li>
                                                            Provide up to date information of the Customer, specialties, Doctors and Doctor profiles to Macrohealthplus at all times. Updates can be either made directly by the Customer, or sent over by email to Macrohealthplus, in which case the Customer fully authorized Macrohealthplus to update and/or edit/remove information on the profile on their behalf. The Customer authorizes Macrohealthplus to make updates and/or any edits/removals on their profile as Macrohealthplus directly finds updated information, and also to add other information about Macrohealthplus’s complementary collaborators;
                                                        </li>
                                                        <li>
                                                            Not make derogatory remarks about Macrohealthplus or its business model and not do anything which has the effect of disparaging the image of Macrohealthplus in the eyes of the public in general;
                                                        </li>
                                                        <li>
                                                            Extend maximum co-operation to Macrohealthplus for the fulfillment of the objectives and purpose of this Agreement;
                                                        </li>
                                                        <li>
                                                            Provide Macrohealthplus with a list of Customers (or at least the number of customers by specialty, as the case may be) who have visited the Customer by Macrohealthplus’s referral, at the end of each month. To this purpose, Macrohealthplus has already ensured Customer’s express consent through its registration procedures and policies. In case the Customer is still unable to deliver the data as requested for any reason, it will provide Macrohealthplus with the relevant statistical data;
                                                        </li>
                                                        <li>Be responsible for informing its employees/consultants that their profiles will be added to the Website.</li>
                                                    </ol>
                                                    <li>
                                                        <span style={{
                                                            textDecoration: "underline",
                                                        }}> Restriction </span> <br />
                                                        <span>Customer shall not use the Services to:</span>
                                                        <ol type="a" className="ms-3">
                                                            <li> Post, publish or transmit any Content which violates or infringes in any way upon the rights of others, which is unlawful, threatening, abusive, defamatory, libelous, invasive of privacy or publicity rights, violent, vulgar, obscene, profane, hateful, or racially or ethnically or otherwise objectionable, such as images or content that is obscene, sexually explicit or contains nudity, indecent, defamatory, hateful or intolerant in nature or in violation of the laws of any jurisdiction;</li>
                                                            <li>

                                                                Encourage, conduct activity that is criminal, would give rise to civil liability or would otherwise violate any law;
                                                            </li>
                                                            <li> Impersonate a person or entity or falsely state their affiliation with a person or entity;</li>
                                                            <li>
                                                                Upload, post, transmit or otherwise make available any Content that infringes the rights of another party including but not limited to trademark, copyright and other intellectual property rights;
                                                            </li>
                                                            <li>
                                                                Transmit or otherwise make available any material on or via the Website that contains software viruses or any other computer code, files designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                                                            </li>
                                                            <li>
                                                                Use any automated scripts or “robots” to access, copy, or manipulate any content provided on this Website;
                                                            </li>
                                                            <li>
                                                                Engage in denial of service attacks upon the servers that publish this Website;
                                                            </li>
                                                            <li>
                                                                Breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.
                                                            </li>
                                                        </ol>
                                                    </li>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            Duties and obligations of Macrohealthplus: <br />
                                            Macrohealthplus may:
                                            <ol type="A" className="ms-3">
                                                <li>
                                                    Promote the business, Macrohealthplus may take steps to disseminate information to the prospective Customers, examples include:
                                                    <ol type="a" className="ms-3">
                                                        <li>
                                                            Awareness through local Press (Interviews / Write-ups of Facilities / Doctors of First Party);
                                                        </li>
                                                        <li>
                                                            Blogs and Social Media;
                                                        </li>
                                                        <li>
                                                            Through all its offices / outlets and network of foreign travel partners;
                                                        </li>
                                                        <li>
                                                            Coordinate roadshow, seminars, conferences, medical health fairs, travel marts and press meets;
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        Refer Customers to the Customer for the Services, but at no time will Macrohealthplus recommend one Customer over another Customer;
                                                    </li>
                                                    <li>
                                                        Extend maximum co-operation to the Customer for the fulfillment of the objectives and purpose of this Agreement;
                                                    </li>
                                                    <li>
                                                        Not make derogatory remarks about the Customer;
                                                    </li>
                                                    <li>
                                                        Not do anything which has the effect of disparaging the image of the Customer in the eyes of the public in general. <br />
                                                        Macrohealthplus makes no representation that the usage of this Website, or the content provided herein, will not violate the laws of your local jurisdiction. You are responsible for the laws of your jurisdiction. Do not use this Website if such usage violates the law of the jurisdiction in which you reside. We reserve the right but are not obligated to limit access to our services to any entity, in any geographic region or jurisdiction. We may exercise this right on a case-by-case basis. All descriptions of services and investments are subject to change at any time without notice, at our sole discretion. We reserve the right to discontinue any service at any time.
                                                    </li>
                                                </li>
                                            </ol>
                                        </li>

                                    </ol>

                                </li>
                            </ol>
                        </li>
                        <li>
                            Ratings, Feedback and Reviews
                            <ol type="A" className="ms-3">
                                <li>
                                    <b style={{
                                        textDecoration: "underline",
                                    }}>Ratings:</b> Customer may receive a rating from Customers, from 1 to 5 stars. Ratings provided by the Customer will automatically be posted on the Website.
                                </li>
                                <li>
                                    <b style={{
                                        textDecoration: "underline",
                                    }}>Feedback:</b> Together with the rating, Macrohealthplus may allow submission of feedback about the Customer. The Customer hereby understands and agrees that Macrohealthplus has the unrestricted right to decide whether to post and display the feedback in any way or form as considered appropriate by Macrohealthplus. Macrohealthplus may also consider Customer’s input when deciding whether to publish or remove feedback from the Website. Also, the Customer hereby agrees and understands that Macrohealthplus may collect additional feedback directly from the Customers or from any third party. <br />
                                    Customers are not permitted to allow associates or staff to provide Ratings or Feedback.

                                </li>
                                <li><b style={{
                                    textDecoration: "underline",
                                }}>Reviews:</b> Macrohealthplus reserves the right to post third party reviews about Customers, such as Google Reviews, Facebook reviews, and reviews submitted by third party partners. <br />
                                    The Customer also indemnifies and agrees to hold harmless Macrohealthplus and its shareholders, officers, directors, employees and affiliates from and against all and any claims, damages, costs, suits, judgments, decrees, loss of profit, business and/or goodwill and expenses (including reasonable legal fees), based upon, relating to, or arising out of a posting and displaying of such ratings, feedbacks or reviews collected as per the specification of this clause.

                                </li>
                            </ol>
                        </li>
                        <li>
                            Payments <br />
                            Macrohealthplus shall, in no manner whatsoever, be responsible or liable for any payments to the Customer in relation to the Healthcare Services availed by the Customers.
                        </li>
                        <li>
                            Independent Contractor <br />
                            Nothing in this Agreement shall be construed to create a relationship of employer and employee, principal and agent, partnership, joint venture, or any relationship other than that of independent parties contracting with each other solely to carry out the provisions of this Agreement.
                        </li>
                        <li>
                            Representations and Warranties of the Parties
                            <ol type="i" className="ms-3">
                                <li>
                                    Each Party represents and warrants to the other Party that:
                                    <ol type="a" className="ms-3">
                                        <li>
                                            it has the right, power, and authority to enter into this Agreement and perform the acts required of it herein;
                                        </li>
                                        <li>
                                            the execution of this Agreement by such Party, and the performance by such Party of its obligations and duties hereunder, do not and will not violate any agreement to which such Party is a party or by which it is otherwise bound;
                                        </li>
                                        <li>
                                            it shall take utmost care in maintaining the confidentiality of the information received from each other and shall ensure that the same is not misused by its staff or representatives in any manner whatsoever. Further, the Parties shall be responsible for the breach of confidentiality obligations of its staff or representatives;
                                        </li>
                                        <li>

                                            this Agreement will constitute a legal, valid and binding obligation on each Party till its termination by the Parties herein.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    The Customer represents and warrants:
                                    <ol type="a" className="mx-3">
                                        <li>
                                            That it has and will maintain the necessary licenses/approvals to carry on the hospital/clinic or Healthcare Services as the case may be, and the carrying on of the same is not in violation of the provision of any law in the jurisdiction where it is present;
                                        </li>
                                        <li>
                                            That the doctors and other staff of the Customer are well-qualified and adequately trained to provide the Healthcare Services.
                                        </li>
                                        <li>
                                            That the Customer shall take all necessary precautions to ensure the well-being of the Customer while the Customer is in its care and custody.
                                        </li>
                                        <li>
                                            That the Customer has adequate infrastructure, facilities and staff to take care of the Customer while the Customer is rendered the Healthcare Services by the Customer.
                                        </li>
                                        <li>
                                            That the Customer will delegate Administrators to access and update information on the Website on behalf of the Customer and to receive inquiries submitted on the Website. The Customer claims responsibility and liability for any information provided by the Administrator or usage of the Website by the delegated Administrator. Customer is solely responsible for ensuring that the Administrator contact information is accurate and for responding to Customer inquiries forwarded by Macrohealthplus.

                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Personal Data
                            <ol type="i" className="ms-3">
                                <li>
                                    Customer hereby acknowledges that it may receive Customers’ personal data including without limitation health-related information. Customer represents and warrants that it will, at all times during the use of the Services and thereafter, comply with all applicable local and international laws that may now or hereafter govern the collection, use, transmission, processing, receipt, reporting, disclosure, maintenance, and storage of personal data.
                                </li>
                                <li>
                                    Macrohealthplus will treat personal data relating to Customers in accordance with its Privacy Policy, which is incorporated by reference herein.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Disclaimer and Warranties <br />
                            The Services, the Website, and information provided by Customers is provided "as is'' and "as available" basis without any warranty of any kind, expressed or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. You are solely responsible for any and all acts or omissions taken or made in reliance on the system or the information in the system, including inaccurate or incomplete information. Macrohealthplus disclaims any and all liability for erroneous transmissions and loss of service resulting from communication failures by telecommunication service providers or the system. Macrohealthplus will use best efforts to ensure that the Website and Services are available but does not guarantee or warrant that they will be continuously available or free from interruption.
                            You acknowledge that Customers have access to the system and are receiving our services. Such Customers have committed to comply with the applicable Terms & Conditions and with our policies and procedures concerning use of the system; however, the actions of such Customers may be beyond our control. Accordingly, we do not assume any liability for or relating to any impairment of the privacy, security, confidentiality, integrity, availability, or restricted use of any information on the system resulting from any Customer's actions or failures to act.
                        </li>
                        <li>
                            Limitation of Liability <br />
                            NOTWITHSTANDING THE OTHER TERMS OF THESE TERMS, Macrohealthplus SHALL NOT BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, PUNITIVE DAMAGES OR LIABILITIES OF ANY KIND, OR FOR LOSS OF REVENUE, LOSS OF BUSINESS OR OTHER FINANCIAL LOSS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF ANY REPRESENTATIVE OF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Macrohealthplus’S AGGREGATE LIABILITY UNDER THIS AGREEMENT SHALL UNDER NO CIRCUMSTANCES EXCEED THE VALUE OF ANY FEES RECEIVED BY Macrohealthplus FROM YOU IN THE PRECEDING TWELVE MONTHS. YOU AND Macrohealthplus AGREE THAT THE FOREGOING LIMITATION OF LIABILITY IS AN AGREED ALLOCATION OF RISK BETWEEN YOU AND Macrohealthplus.

                        </li>
                        <li>
                            Indemnification
                            <ol type="i" className="ms-3">
                                <li> The Parties shall indemnify, hold harmless and defend each other and its shareholders, officers, directors, employees and affiliates from and against all and any charges, claims, damages, costs, suits, judgments, decrees, losses, expenses (including reasonable legal fees), penalties, demands liabilities and causes of action, of any kind or stature (“Claims”) asserted by third parties, based upon, relating to, or arising out of a breach or claimed breach or failure of any of the covenants, representations or warranties under this Agreement.</li>
                                <li>
                                    It is expressly clarified that Macrohealthplus is not obligated to indemnify, hold harmless or defend Customer from Claims asserted by a Customer and/or his relatives or guardians concerning the Healthcare Services provided by the Customer.
                                </li>
                            </ol>

                        </li>
                        <li>
                            Confidential Information
                            <ol type="i" className="ms-3">
                                <li>The Parties agree to maintain and hold in strictest confidence, confidential information with respect to the affairs of each other. “Confidential Information” means any non-public information that relates to the actual or anticipated business or research and development of the Parties including, but not limited to, this Agreement and its terms thereof, the scheme of the Arrangement between the Parties, the business model of Macrohealthplus and its working methodology, Customer data, research, product plans or other information regarding either Party’s products or services and markets therefore, customer lists and customers, finances or other business information. The Parties shall however be permitted to disclose the Confidential Information of the other Party to the Customer and/or the Customer to the extent required for the purpose of this Arrangement.</li>
                                <li>
                                    Customer also agrees to maintain adequate administrative, technical, and physical safeguards to protect the confidentiality of protected health information of the Customers. Any breach of security or unlawful disclosure of health information of a Customer shall be reported to Macrohealthplus within two (2) days of the learning of such breach or disclosure and may be a ground for termination of this Agreement.
                                </li>
                                <li>
                                    It is clarified that Macrohealthplus shall not be responsible for any claim arising under this Clause due to breach of Confidentiality by the Customer and Customer shall be liable to indemnify Macrohealthplus for any losses arising as a result of the same.
                                </li>
                                <li>
                                    The provision on confidentiality under this Clause is binding and shall survive the termination of this Agreement.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Termination
                            <ol type='i' className="ms-3">
                                <li>Termination with cause <br />
                                    Either Party may terminate this Agreement if:
                                    <ol type="a" className="ms-3">
                                        <li>
                                            the other Party commits a breach of any material term or condition of this Agreement and fails to cure such breach within ten (15) days after receipt of written notice of the same;
                                        </li>
                                        <li>
                                            the other Party becomes the subject of a petition in bankruptcy or any voluntary/involuntary proceeding relating to insolvency, receivership, liquidation, or composition for the benefit of creditors.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Termination without cause <br />
                                    Either Party may terminate this Agreement at will, after giving 15 days’ prior written notice of the same to the other Party. Macrohealthplus may terminate this Agreement immediately if it determines in its sole discretion that the treatments provided by a Customer are inconsistent with generally accepted practices or standards, regardless of whether they are legal in the Customer’s jurisdiction.

                                </li>
                            </ol>
                        </li>
                        <li>
                            Dispute Resolution and Governing Law <br />
                            <ol type="i" className="ms-3">
                                <li>
                                    Any claim, controversy, difference or dispute between the Parties shall be attempted to be resolved through mutual negotiations within a period of 30 days from the date of such dispute arising.
                                </li>
                                <li>
                                    If the dispute is not resolved by negotiation within the above referred 30 days, the Parties shall be bound to submit all disputes and differences howsoever arising out of or in connection with this Agreement to arbitration by sole arbitrator appointed by mutual agreement of the Parties, or in the absence of such an agreement within 15 days after a Party first proposes an arbitrator, the dispute shall be resolved by an arbitral tribunal composed of three arbitrators, one of whom shall be appointed by each Party within 15 days after expiry of the 15 days period. The third arbitrator shall be selected by the mutual agreement of the first two arbitrators within 15 days after the last of the first two arbitrators has been appointed. The place of arbitration shall be Australia and Bangladesh. <br />
                                    The language to be used in the arbitration proceedings shall be English. The award of the arbitration proceedings will be final and binding on all Parties to the Agreement. <br />
                                    This Agreement is governed by and is to be construed in accordance with the laws of England and Wales. The courts of England and Wales shall have exclusive jurisdiction with respect to all actions arising out of or in relation to this Agreement, its performance or breach thereof.

                                </li>
                            </ol>
                        </li>
                        <li>
                            Copyright Notice <br />
                            Macrohealthplus respects the intellectual property rights of others and expects the users of its services to do the same. If you believe that any content appearing on the Website has been copied in a way that constitutes copyright infringement, please forward the following information to Macrohealthplus. <br />
                            To file a copyright infringement notice, you will need to send a notice that includes the following to info@macrohealthplus.org:
                            <ul className="ms-3">
                                <li>
                                    Your name, address, telephone number, and email address;
                                </li>
                                <li>
                                    A description of the copyrighted work that you claim has been infringed;
                                </li>
                                <li>The exact URL or a description of where the alleged infringing material is located;</li>
                                <li>
                                    A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
                                </li>
                                <li>
                                    An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; and
                                </li>
                                <li>
                                    A statement by you, under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
                                </li>
                            </ul>
                        </li>
                        <li>
                            General Provisions <br />
                            <ol type="i" className="ms-3">
                                <li>Survival: Termination of this Agreement will not terminate, affect, or impair any rights, obligations, or liabilities of either Party, which may accrue prior to such termination or those provisions which by their sense and context should survive any termination or expiration of this Agreement.</li>
                                <li>
                                    Non-Solicit: During the Term of this Agreement, Either Party will not, without the Other Party’s prior written consent, directly or indirectly, solicit or encourage any employee or contractor of the Other Party or its affiliates to terminate employment with, or cease providing services to, the Other Party or its affiliates.
                                </li>
                                <li>
                                    Assignment: This Agreement shall not be assigned, in whole or in part, by the Customer, without the prior written consent of Macrohealthplus. However, Macrohealthplus may assign this Agreement, at its discretion, to such affiliate/s as it may deem fit without prior notification to the Customer. Further, this Agreement shall continue and will remain unaffected by reason of change in control of Macrohealthplus. This Agreement will be binding upon and will inure [7][8] to the benefit of the Parties and their representatives and respective successors and permitted assigns. <br />
                                    For the purposes of this Agreement, “change in control” shall mean the direct or indirect change in the ownership, operation or control of Macrohealthplus whether resulting from merger, acquisition, share transfer / subscription, consolidation or otherwise.

                                </li>
                                <li>
                                    Waivers: No Party will be deemed to have waived any of its rights, powers or remedies hereunder unless that Party approves such waiver in writing.
                                </li>
                                <li>
                                    Entire Agreement: This Agreement, including any and all attachments/Exhibits hereto, constitutes the entire agreement between the Parties relating to the subject matter hereof, and all prior negotiations and understandings, whether oral or written, are superseded hereby.
                                </li>
                                <li>
                                    Severability: Each section of this Agreement is severable. If any provision is held unenforceable by a court of competent jurisdiction, such ruling shall not impair any other provision that remains intelligible and all other provisions shall continue in effect.
                                </li>
                                <li>
                                    Force Majeure: Macrohealthplus shall not be liable for any delay or failure in performance due to acts of God, earthquake, labor disputes, changes in law, regulation or government policy, riots, war, fire, flood, insurrection, sabotage, embargo, epidemics, pandemics, acts or omissions of vendors or suppliers, transportation difficulties, unavailability of interruption or delay in telecommunications or third party services (including DNS propagation), failure of third party software or hardware or inability to obtain raw materials, supplies, or power used in or equipment needed. Macrohealthplus is not responsible for server downtime under any circumstances.
                                </li>
                                <li>
                                    Compliance with Laws: Each Party shall comply with all applicable laws and regulations in performing its obligations under this Agreement and shall be individually liable for any and all non-compliance of any regulation/law/guideline/order etc. on its part.
                                </li>
                                <li>
                                    Notices: All notices required or desired to be given hereunder will be in writing and can either be delivered personally or be sent by electronic communication (with a copy by ordinary mail) or by registered or certified mail. If sent by electronic communication, notices will be deemed to have been given on the same day on which the notice is sent. If notice is sent by registered or certified mail or is delivered personally, it shall be deemed to have been given on the day on which the notice is received or when delivery is refused.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div >

            </div >
        </>
    )
}
